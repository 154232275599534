import {useState, useEffect, useRef,useLocation} from 'react';
import { Link } from 'react-router-dom';
import classes from './Navigation.module.css';
import '../../components/LandingPage.css';

const NavigationDesktop = (props) => {

    const [dropdown, setDropdown] = useState(false);
    const [navDropdown, setNavDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const [servicesDropdown, setServicesDropdown] = useState(false);
    const [moreDropdown, setMoreDropdown] = useState(false);

    const activePage = window.location.pathname;
    const path=window.location.pathname
    // console.log("path--->",path);
    let submissionText = "";
    let submissionType = "";


    useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdown(false);
            console.log(event.target);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef]);


    return(
            <div className={classes.masterContainer}>
                <div className={classes.left}>
                    <Link to="/">
                        <img src=
                        "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo-green-logo.png"
                        // {activePage === "/adoption"?
                        // :"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png"} 
                        
                        className="petmojo-logo" alt="" style={{objectFit:"contain",width:"220px",height:"80px"}} ></img>
                    </Link>
                </div>
                <div className={classes.right}>

                    <div className={classes.item1}>
                        <Link className={classes.link} to="/">
                            <div className={ (activePage === "/") ? "active" : "non-active" }>Home</div>
                        </Link>
                    </div>
                    <div>
                        {"|"}
                    </div>
                    <div className={classes.item1}>
                        <Link className= {classes.link } to="/dog-running" state={{from: "navigation"}}>
                            <div className={ (activePage === "/dog-running") ? "active" : "non-active" }>Dog Walking/Exercise</div>
                        </Link>
                    </div>
                    <div>
                        {"|"}
                    </div>
                    <div className={classes.item1}>
                        <Link className= {classes.link } to="/cat-training" state={{from: "navigation"}}>
                            <div className={ (activePage === "/cat-training") ? "active" : "non-active" }>Cat Training</div>
                        </Link>
                    </div>
                    <div>
                        {"|"}
                    </div>

                    <div className={classes.item1}>
                        <Link className= {classes.link } to="/dog-training" state={{from : "navigation"}}>
                            <div className={ (activePage === "/dog-training") ? "active" : "non-active" }>Dog Training</div>
                        </Link>
                    </div>
                    <div>
                        {"|"}
                    </div>
                    <div className={classes.item1}>
                        <Link className= {classes.link } to="/grooming" state={{from: "navigation"}}>
                            <div className={ (activePage === "/grooming") ? "active" : "non-active" }>Pet Grooming</div>
                        </Link>
                    </div>
                    <div>
                        {"|"}
                    </div>
                    <div onMouseOver={() => setMoreDropdown(true)} onMouseLeave={() => setMoreDropdown(false)} className={classes.item1}>
                        <div className= {classes.link }>
                            <div className={ (activePage === "/more") ? "active" : "non-active" }>More</div>
                            {
                                moreDropdown ? (
                                    <div className='navigation-desktop-services-drop-container' onMouseOver={() => setMoreDropdown(true)}>
                                        <Link className='navigation-desktop-service-drop-item' to="/pet-boarding"><div>Pet Hotel</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/grooming-subscription"><div>Grooming Subscription</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/socials"><div>Community</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/partner"><div>Become a Partner</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/blog"><div>Blogs</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/help"><div>Help</div></Link>
                                        <Link className='navigation-desktop-service-drop-item' to="/about"><div>About</div></Link>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>

                    {
                        (activePage !== "/adoption" && props.submissionType) ? (
                            <div>
                            {
                                (props.submissionType === "none") ? (
                                    <div className='desktop-navigation-booking-button'>
                                        <div className="">
                                            <button className="booksession-navigation-desktop">{props.submissionText}</button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='desktop-navigation-booking-button'>
                                        <div className="">
                                            <Link to="/popup" state={{ type: `${props.submissionType}` }}>
                                            <button className="booksession-navigation-desktop">{props.submissionText}</button>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                            </div>

                        ) : null
                    }
                </div>
            </div>
    );
};

export default NavigationDesktop;