export const data = [
    {
        title: "What days and times do you offer for Grooming?",
        content:
          "We offer Grooming 6 days a week. Meeting times will vary by day and season. We usually start at 9:00am and the last meeting of the day at 6:00pm. If you have special needs that do not fall within these times, please let our staff know and we will make every effort to accommodate you.",
      },
      {
        title: "How old should my puppy be before he gets his first groom?",
        content:
          "It is a good idea to introduce your puppy to grooming as soon as possible after the first round of puppy shots, usually between 12-16 weeks of age. This will help him learn at an early age that grooming is a pleasant experience.",
      },
      {
        title: "How long does it take to groom my pet?",
        content:
          "The approximate duration of each treatment can take up to 30 minutes. The time will depend on the condition and length of your dog's coat.",
      },
      {
        title: "Do you use drying cages?",
        content:
          "We have a strict no cage drying policy. All pooch's are finished by hand by our experienced stylists. We choose this for the safety and well-being of our canine clients, and while it increases treatment time, we refuse to compromise the handling of equipment and procedures used by other groomers.",
      },
      {
        title: "How often should my pet be groomed?",
        content:
          "The length and type of your pet's coat will determine how often your pet needs to be groomed.",
      },
      {
        title: "What if I am not satisfied with the service my dog ​​is receiving?",
        content:
          "If for any reason you are not completely satisfied with the treatment you received, please let us know within 2 working days so we can do everything we can to make it right. The satisfaction of pet parents is important to us. We take great pride in our services and want pooch parents to too.",
      }
  ];