import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import DeleteAccountPageForm from './DeleteAccountPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';
import DeleteAcc from "../../../images/DeleteBg.png"

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container">
            <div className='wrapper-class-intro-desktop-video' style={{position:"absolute"}}>
              <LazyLoad>
                {/* <video style={{width: "100vw", backgroundColor: "black"}} role="presentation" crossorigin="anonymous" playsInline preload="auto" muted loop tabindex="-1" autoPlay  className='home-page-desktop-background-video'>
                  <source
                    src="https://video.wixstatic.com/video/4e82bb_80fb0c4770334638a530f96539121a6c/720p/mp4/file.mp4"
                    type="video/mp4"
                    style={{width: "100%"}}
                  />
                </video> */}
                <img src={DeleteAcc} alt="" style={{width:"100vw",height:"100vh",objectFit:"cover"}}/>
              </LazyLoad>
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"dog-training"} submissionText={"Book a FREE Session"}  />

              <div className='intro-desktop-head-combiner'>
                
              
                <div className='intro-desktop-home-page-header'>
                  <h1 className='home-page-desktop-heading'>
                    <div className='intro-desktop-home-page-header1'>Enter your <span className='pink-bold'>Phone Number</span> or <span className='pink-bold'>Email</span></div>
                    <div className='intro-desktop-home-page-header1'>to Delete account</div>
                  </h1>
                </div>
                
                <DeleteAccountPageForm />


              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
