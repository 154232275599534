export const timelineData = [
  {
    title: "Book an appointment",
    text: "Free first session for Training and Walking Services. ",
    routeText: "Book Now",
    route: "/popup",
  },
  {
    title: "Select Your Service",
    text: "Choose the service perfect for your pet. ",
    routeText: "Check all services",
    route: "/services",
  },
  {
    title: "Start your Pet Service",
    text: "Begin your Journey with Us on the Petmojo App. ",
    link: "https://onelink.to/qrsgq4",
  },
];
//
export const data = [
  {
    title: "Why should we choose Petmojo?",
    content:
      "Our expert trainers have up to 20+ years of training experience. Moreover, we are super empathetic towards your furry friend using only positive reinforcement to re-model behavior. Our mission is to improve your lifelong relationship with your pet by making it more harmonious. We are not money-driven and care deeply about overall animal welfare. ",
  },
  {
    title: "Who will be my pet’s Trainer?",
    content:
      "PetMojo’s in-house trainer is experienced, reliable, and verified through a comprehensive background check. Our trainers are focused on delivering results with positive and reward-based training. ",
  },
  {
    title: "In what areas do you provide services?",
    content:
      "We provide services in Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Chandigarh, Mumbai, Bangalore, Pune, Kolkata, Chennai, and Bhopal at the moment. ",
  },
  {
    title: "What Breeds of Dogs do you Train?",
    content:
      "We have trained all breeds of dogs. You would be hard-pressed to find a breed that we have not trained – from Chihuahua to Great Dane, big or small, we train them all! ",
  },
  // {
  //   title: "What is the Cancellation and Refund Policy",
  //   content:
  //     "We will refund 100% of the remaining sessions – no questions asked. To claim the refund please contact our customer support team.",
  // },
];
