
export const data = [
    {
        title: "Where will you walk my dog?",
        content:
          "Our team will be happy to walk your dog where you would normally walk them. We can stick to your immediate neighborhood or take them to another area, depending on your preferences. Our aim is to keep the walk as close to yours as possible so that we are consistent with your dog's routine. Please note that we are unable to transport your dog.",
      },
      {
        title: "Do you conduct background checks on your Trainers/Walkers?",
        content:
          "Our certified caregivers at Petmojo undergo rigorous background checks as well as an extensive certification program before they can care for our clients. We only hire individuals we would trust to care for our own pets, so you can be sure your furry friends are always in the best hands, except yours.",
      },
      {
        title: "My dog ​​has health problems, will you still come?",
        content:
          "Of course! Our certified caregivers are trained to care for pets with special needs as well as older pets. During your free initial consultation, we'll take the time to understand the range of your pet's needs and copy down the detailed information you provide to ensure they get exactly what they need while you're away.",
      },
      {
        title: "What are you doing on your walk?",
        content:
          "We can tailor visits to your liking. Our goal is to keep your dog peeing and pooping and your pet super happy!",
      },
      {
        title: "Can I meet the person who will walk my dog?",
        content:
          "Yes. We strongly recommend that you have an initial meeting with your walker at your home to discuss your requirements prior to the walk. They can be arranged at short notice for a time that suits you.",
      },
      {
        title: "Will the same person walk my dog ​​every day?",
        content:
          "Most of the time, yes. You will have a dedicated walker. However, there may be a special occasion due to illness or annual leave when we need to replace the walker. The replacement walker will be fully informed of your dog's specific requirements. Our entire team is very experienced and we communicate constantly, so your dog will always be in great hands.",
      },
]