function HeartContainer({...props}) {
  const path=props.pathname;
  // console.log(path)
  // const path=props.pathname;
  // console.log(path);
  return (
    <div className="container pink-container-family" style={{marginTop:"0px",padding:"0px",position:"relative",bottom:path==='/grooming'?"0px":"0px"}}>
      <div className="textbox2">Your Pet - Our Family 💖</div>
      <hr className="vector1"></hr>
      <div className="heartimg">
        {/* <LazyLoad> */}
        <img
          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-heart-container-mobile.webp"
          alt="heart"
          className="hearticon"
        ></img>
        {/* </LazyLoad> */}
      </div>
    </div>
  );
}

export default HeartContainer;
