import React, {useState,useEffect, useRef} from 'react';
import './Partner.css';
import LazyLoad from "react-lazyload";
import NavigationBarPartner from "./Navigation/navigationBar/NavigationBarPartner";
import {Helmet} from 'react-helmet-async';
import GoToTop from "./GoToTop";
import {Link} from 'react-router-dom';
import NavigationBar from './Navigation/navigationBar/NavigationBar';
import NavigationDesktop from "../components-desktop/Navigation/NavigationDesktop";

function Partner() {
    const [navDropdown,setNavDropdown] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [activeText, setActiveText] = useState("Area of Interest");
    const [phNo, setPhNo] = useState(0);
    const [changeView, setChangeView] = useState(false);



    const [validPhone, setValidPhone] = useState(true);
    const [validAOI, setValidAOI] = useState(true);

    const [open, setOpen] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
      if(window.innerWidth <= 1000){
        setChangeView(false);
      }else{
        setChangeView(true);
      }
    }, []);

    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setNavDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    const submitFormHandler = (data) => {
      if( (activeText !== "Area of Interest") &&  (phNo.toString().length === 10)){
        setSubmitForm(true);
      }
      else{
        if(activeText === "Area of Interest"){
          setValidAOI(false);
        }
        if(phNo.toString().length !== 10){
          setValidPhone(false);
          console.log(phNo)
        }

        return;
      }
    }

    const handleInsideClickHandler = () => {
      setOpen(!open); // setting the open to true
    };

  return (
    <div >
      <Helmet>
        <title>Dog Trainer and Dog Walkers, Partner with us now - Petmojo</title>
        <meta name="description" content="Join Petmojo as a verified Dog Trainer or Dog Walker." />
        <link rel="canonical" href="/partner" />
      </Helmet>
      <div>
        {
          !changeView ? (
            <div className='partner'>
            <NavigationBar page={"white"} className="home-page-mobile-navigation-container" />
            <div className='partner-form-container'>
              <div className='partner-form'>
                  <div className='partner-form-box'>
                      <div className='partner-form-mobile-head'>
                        Become a Partner
                      </div>
                      <div className='form-message'>
                          Please submit the information and we will get back to you within 48 business hours.
                      </div>
                      <div className='partner-contact-container'>
                        <input 
                          type='number' 
                          onChange= {(e) => {
                            setValidPhone(true);
                            setPhNo(e.target.value)
                          }} 
                          className='partner-contact-input' 
                          placeholder='Phone*' 
                        />
                        {
                          validPhone ? null : (
                            <div className="invalidPhone">
                              Please enter a valid phone number!
                            </div>
                          )
                        }
                        <div 
                          className="area-interest-container" 
                          onClick={(e) => {
                            handleInsideClickHandler();
                            setValidAOI(true);
                          }}
                        >
                          <div className={ (activeText === "Area of Interest") ?  "partner-interest-text" : "partner-interest-text-dark" }>
                            {activeText}
                          </div>
                          <div className="partner-dropdown-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                               <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                          {
                            validAOI ? null : (
                              <div className="invalidAOI">
                                Required!
                              </div>
                            )
                          }
                          {
                          open ? (
                            <div className="dropdown-aoi">
                              <div 
                                onClick={() => {
                                  setActiveText("Dog Walking");
                                  setOpen(!open)
                                }} 
                                className="dropdown-aoi-item1"
                              >
                                Dog Walking
                                <hr></hr>
                              </div>
                              
                              <div 
                                onClick={() => {
                                  setActiveText("Dog Training");
                                  setOpen(!open)
                                }} 
                                className="dropdown-aoi-item2"
                              >
                                Dog Training
                                <hr></hr>
                              </div>
                              
                              <div 
                                onClick={() => {
                                  setActiveText("Pet Grooming");
                                  setOpen(!open)
                                }} 
                                className="dropdown-aoi-item3"
                              >
                                Pet Grooming
                              </div>
                            </div>
                          ) : null
                        }
                        </div>


                      </div>
        
                      <ul className='form-points-container'>
                          <li className='form-point'>If you are looking for our Service Partner Application then <a href='https://play.google.com/store/apps/details?id=in.tamely.user' target="_blank" rel="nofollow" style={{textDecoration: "none"}} className='pink'>Click here</a></li>
                          <li className='form-point'>If you are not able to get Access Code for Service Partner Application then <a target="_blank" href="tel: +91 900 900 44 73" style={{textDecoration: "none"}} className='pink'>Click here</a></li>
                          <li className='form-point'>If you have any other Questions Please contact <a href="mailto:comm-support@petmojo.care" target="_blank" style={{textDecoration: "none"}} className='pink'>support@petmojo.care</a></li>
                      </ul>
                      <div className='submit-box partner-submit-btn'>
                          <button className='submit-btn' onClick={()=>submitFormHandler(true)}>Submit</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          ) : (
            <div className='partner-page-desktop-view-master-container'>
              <img alt="backgroundImage" className='partner-page-desktop-view-background' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/partner website.webp" />
              <div className='partner-page-desktop-view-master'>
                <NavigationDesktop submissionType={"none"} submissionText={"Download Our App"} />

                <div className='partner-page-desktop-view-section-2'>
                  <div className='partner-page-desktop-view-section-2-head'>Become a Partner</div>
                  <div className='partner-page-desktop-view-section-2-subhead'> Please submit the information and we will get back to you within 24 business hours. </div>
                  <div className='partner-page-desktop-view-section-2-input-container'>
                    <div className='partner-contact-container-desktop'>
                          <input 
                            type='number' 
                            onChange= {(e) => {
                              setValidPhone(true);
                              setPhNo(e.target.value)
                            }} 
                            className='partner-contact-input' 
                            placeholder='Phone*' 
                          />
                          {
                            validPhone ? null : (
                              <div className="invalidPhone">
                                Please enter a valid phone number!
                              </div>
                            )
                          }
                          <div 
                            className="area-interest-container-desktop" 
                            onClick={(e) => {
                              handleInsideClickHandler();
                              setValidAOI(true);
                            }}
                          >
                            <div className={ (activeText === "Area of Interest") ?  "partner-interest-text" : "partner-interest-text-dark-desktop" }>
                              {activeText}
                            </div>
                            <div className="partner-dropdown-img">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                              </svg>
                            </div>
                            {
                              validAOI ? null : (
                                <div className="invalidAOI">
                                  Required!
                                </div>
                              )
                            }
                          {
                            open ? (
                              <div className="dropdown-aoi-desktop">
                                <div 
                                  onClick={() => {
                                    setActiveText("Pet Walking");
                                    setOpen(!open)
                                  }} 
                                  className="dropdown-aoi-item1"
                                >
                                  Pet Walking
                                  <hr></hr>
                                </div>
                                
                                <div 
                                  onClick={() => {
                                    setActiveText("Dog Training");
                                    setOpen(!open)
                                  }} 
                                  className="dropdown-aoi-item2"
                                >
                                  Dog Training
                                  <hr></hr>
                                </div>
                                
                                <div 
                                  onClick={() => {
                                    setActiveText("Pet Grooming");
                                    setOpen(!open)
                                  }} 
                                  className="dropdown-aoi-item3"
                                >
                                  Pet Grooming
                                </div>
                              </div>
                            ) : null
                          }
                          </div>

                        </div>
                  </div>

                  <div className='partner-page-desktop-view-section-2-submit' style={{color:"black",fontWeight:"bold",width:"314px",textAlign:"center",marginTop:"20px"}}>
                      Submit
                  </div>

                  <div className='partner-page-desktop-view-section-2-disclaimer'>
                    By clicking Submit above, You acknowledge our <span className='pink'><Link to="/privacy" className=' pink privacy-statement-partner' style={{textDecoration: "none"}}>Privacy Statement</Link></span>
                  </div>
                </div>

                <div className='partner-page-desktop-view-section-3'>
                  <div className='partner-page-desktop-view-section-3-1'>
                    <li>If you are looking for our Service Partner Application then <span className='pink'>Click here</span></li>
                  </div>

                  <div className='partner-page-desktop-view-section-3-2'>
                    <li>If you are not able to get Access Code for Service Partner Application then <span className='pink'>Click here</span></li>
                  </div>

                  <div className='partner-page-desktop-view-section-3-3'>
                    <li>If you have any other Questions Please contact <span className='pink'>support@petmojo.care</span></li>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <GoToTop/>
    </div>
  )
}

export default Partner;




