import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./OrderSummary.css";
import download from "../images/download.png";
import downloadinvoice from "../images/download-invoice.png";
import groomingvector from "../images/grooming-vector.jpg";

const OrderSummaryBoarding = (props) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [packageAmount, setPackageAmount] = useState(0);
  const [days, setDays] = useState(0);
  const [thirdMeal, setThirdMeal] = useState(0);
  const [nonVeg, setNonVeg] = useState(0);
  const [pickupCharges,setPickupCharges]=useState(0);
  const [dropOffCharges,setDropOffCharges]=useState(990);
  // console.log(location.state);
  // setData(location.state.data);
  const [changeView, setChangeView] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const [copyMessage, setCopyMessage] = useState("");
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  useEffect(() => {
    setData(location.state.data);
    console.log(data);
    setDays(data.Days);
    setPackageAmount(data.PackageAmount)
    if (data.ThirdMealAdded == "Yes") {
      setThirdMeal(59 * days);
    }
    if (data.NonVegMealAdded == "Yes") {
      setNonVeg(99 * days);
    }
    if( data.Days<=10){
      setPickupCharges(990);
      // setDropOffCharges(899);
    }
  });
  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }
  const navigate=useNavigate();

  const navigateHome=()=>{
    navigate('/');
  }
  return (
    <div>
      {changeView ? (
        <div className="order-summary-desktop-master-container">
          <div>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
              alt=""
              style={{ width: "270px", height: "70px", objectFit: "contain" }}
            />
          </div>

          <div className="order-summary-desktop-master-container-bottom">
            <div
              className="order-summary-desktop-grey-container"
              style={{ padding: "50px 100px", width: "100%",justifyContent:"space-between",gap:"10%" }}
            >
              <div className="order-summary-desktop-grey-left" style={{width:"50%"}}>
                <div className="order-summary-desktop-grey-left-order">
                  Booking Confirmed:
                </div>
                <div className="order-summary-desktop-grey-left-order-heading">
                  {data.Service}
                  ({data.PetSize}) <br/>
                  <span style={{fontSize:"18px"}}>
                  Amount per night ₹ 
                    {
                      data.PetSize=="Small Pet"?<>
                     499
                      </>:<>599</>
                    }
                  </span>
                </div>
                <div className="order-summary-desktop-grey-left-order-details-container">
                  {/* <div>Name of Pet: {"Simba"}</div> */}
                  <div>
                    Start Date:
                    {/* {location.state.DateOfReg} */}
                    {data.StartDate}
                  </div>
                  <div>Your Registered Number:{data.ContactNo}</div>
                </div>
              </div>
              <div
                className="order-summary-desktop-grey-right"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  flexDirection: "column",
                  width: "50%",
                  fontSize: "20px"
                }}
              >
                <div style={{ display: "flex",width:"100%",justifyContent:"end" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "right",
                    }}
                  >
                    <div style={{ fontWeight: "700" }}>x {days} Days:
                      <span style={{fontWeight:"400"}}>
                      ₹{packageAmount*days}
                      </span>
                    </div>

                    {
                      data.ThirdMealAdded=="Yes"?(
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                       Third Meal :
                       <span style={{fontWeight:"400"}}>
                       ₹{59 * days}
                      </span>
                    </div>
                      ):null
                    }
                    {
                      data.NonVegMealAdded=="Yes"?(
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                       Non Veg Meal :
                       <span style={{fontWeight:"400"}}>
                       ₹{99 * days}
                      </span>
                    </div>
                      ):null
                    }

                    {
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Pick-Up Charges :
                        <span style={{fontWeight:days>10?"700":"400"}} className={days>40?"pink":""}>
                       {days>40?"FREE":"₹990"}
                      </span>
                      </div>
                      }
                      {
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Drop-Off Charges :
                        <span style={{fontWeight:"400"}}>
                       ₹{990}
                      </span>
                      </div>
                      }
                    <div
                      style={{
                        fontWeight: "700",
                        borderTop: "2px solid grey",
                        marginTop: "5px",
                      }}
                      className="pink"
                    >
                      Total :
                      <span className="brand" style={{fontWeight:"700"}}>
                       ₹{(packageAmount*days)+pickupCharges+dropOffCharges+thirdMeal+nonVeg}
                      </span>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "right",
                    }}
                  >
                    {data.ThirdMealAdded == "Yes" ? (
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{59 * days}
                        </div>
                      ) : null}
                      {data.NonVegMealAdded == "Yes" ? (
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{99 * days}
                        </div>
                      ) : null}
                      {
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                        ₹ {days>10?0:899}
                      </div>
                      }
                      {
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                        ₹ {899}
                      </div>
                      }
                    <div
                      style={{
                        fontWeight: "400",
                        borderTop: "2px solid grey",
                        marginTop: "5px",
                      }}
                      className="pink"
                    >
                      {console.log(packageAmount,pickupCharges,dropOffCharges,thirdMeal,nonVeg)}
                      ₹{(packageAmount*days)+pickupCharges+dropOffCharges+thirdMeal+nonVeg}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div
              className="order-summary-desktop-grey-container"
              style={{
                padding: "60px 120px",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div
                className="order-summary-desktop-grey-left"
                style={{ width: "50%" }}
              >
                <div className="order-summary-desktop-grey-left-order">
                  Billing Address:
                </div>
                <div
                  className="order-summary-desktop-grey-left-order-details-container"
                  style={{ width: "30%" }}
                >
                  <div>{data.Address}</div>
                </div>
                <div className="" style={{ width: "100%" }}>
                  {
                    data.PayNow?<button
                    type=""
                    className="order-summary-desktop-white-container-left-download-button"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "50%",
                      fontSize: "20px",
                    }}
                  >
                    Download Invoice
                  </button>:<button
                    type=""
                    className="order-summary-desktop-white-container-left-download-button"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "50%",
                      fontSize: "20px",
                      color:"black"
                    }}
                    onClick={navigateHome}
                  >
                    Back to home
                  </button>
                  }
                </div>
              </div>

              <div
                className="order-summary-desktop-grey-right"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                  flexDirection: "column",
                  width: "50%",
                  fontSize: "20px",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/invoice-vector-walking.png"
                    alt=""
                    style={{ objectFit: "contain", width: "200px" }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="tickImage"
                    className="order-confirmation-mobile-summary-image"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
                    style={{ objectFit: "contain" }}
                  />
                  <div
                    className="order-confirmation-mobile-summary-text"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    Your information has been sent to our pet counselor, <br />
                    Download our Mobile app and start tracking <br />{" "}
                    immediately and connect with the walker.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="order-summary-mobile-master-container">
          <div className="order-summary-mobile-grey-container">
            <div>Booking Confirmed:</div>
            <div className="order-summary-mobile-grey-heading">
              {/* {location.state.SelectedService} */}
              {data.Service} 
              ({data.PetSize}) <br/>
              <span style={{fontSize:"14px"}}>
                  Amount per night ₹ 
                    {
                      data.PetSize=="Small Pet"?<>
                     499
                      </>:<>599</>
                    }
                  </span>
              {/* {data.Package !== "" ? (
                <>
                  ({data.Package}
                  <span className="pink"> {data.Days}Days</span>)
                </>
              ) : 
                <>({data.PetSize})
                <br/>
                  <span style={{fontSize:"14px"}}>
                  Amount per night ₹ 
                    {
                      data.PetSize=="Small Pet"?<>
                     499
                      </>:<>599</>
                    }
                  </span>
                </>
              } */}
              
            </div>
            <div className="order-summary-mobile-grey-container-details-container">
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {/* <div>Name of pet: {data.PetName}</div> */}
                <div>
                  Start Date: {data.StartDate}
                  {/* {location.state.DateOfReg} */}
                </div>
                <div>Your Registered Number: {data.ContactNo}</div>
              </div>

              {
                data.PayNow?<div>
                <img
                  src={downloadinvoice}
                  width={50}
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    top: "-20px",
                  }}
                />
              </div>:null
              }
            </div>
            <div
              className="order-confirmation-mobile-container"
              style={{ color: "rgba(63,65,78,1", marginTop: "20px" }}
            >
              <span>Billing Address:</span>
              {/* {location.state.orderId} */}
              <p style={{ margin: "0px", marginTop: "5px", color: "#727272" }}>
                {data.Address}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "15px",
            }}
          >
            <div>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/invoice-vector-walking.png"
                alt=""
                style={{ objectFit: "contain", width: "150px" }}
              />
            </div>
            <div
              className="order-summary-desktop-grey-right"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                flexDirection: "column",
                width: "50%",
                fontSize: "12px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  {/* {data.Package !== "" ? (
                    <>
                      <div style={{ fontWeight: "700" }}>Package Amount :</div>
                      {data.ThirdMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Third Meal :
                          </div>
                        </div>
                      ) : null}
                      {data.NonVegMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Non Veg Meal :
                          </div>
                        </div>
                      ) : null}
                      <div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Pick-Up Charges :
                      </div>
                      <div
                        style={{
                          fontWeight: "700",
                          borderTop: "2px solid grey",
                          marginTop: "5px",
                        }}
                        className="pink"
                      >
                        Total :
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ fontWeight: "700" }}>x {days} Days:</div>
                      {data.ThirdMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Third Meal :
                          </div>
                        </div>
                      ) : null}
                      {data.NonVegMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Non Veg Meal :
                          </div>
                        </div>
                      ) : null}
                
                      {
                        data.PickupSlot==""?null:<div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Pick-Up Charges :
                      </div>
                      }
                      {
                        data.DropoffSlot==""?null:<div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Drop-Off Charges :
                      </div>
                      }
                      <div
                        style={{
                          fontWeight: "700",
                          borderTop: "2px solid grey",
                          marginTop: "5px",
                        }}
                        className="pink"
                      >
                        Total :
                      </div>
                    </>
                  )} */}
                  <>
                      <div style={{ fontWeight: "700" }}>x {days} Days: 
                      <span style={{ fontWeight: "400" }}>₹{data.PetSize==="Small Pet"?499*days:599*days}</span>
                      </div>
                      {data.ThirdMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Third Meal : <span style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{59 * days}
                        </span>
                          </div>
                        </div>
                      ) : null}
                      {data.NonVegMealAdded == "Yes" ? (
                        <div>
                          <div style={{ fontWeight: "700", marginTop: "5px" }}>
                            Non Veg Meal : <div style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{99 * days}
                        </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div style={{ fontWeight: "700", marginTop: "5px" }}>
x6 Days :
</div>
<div style={{ fontWeight: "700", marginTop: "5px" }}>
x6 Third Meal :
</div> */}
                      {
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Pick-Up Charges : <span style={{ fontWeight:days>10?"700": "400", marginTop: "5px" }} className={days>10?"pink":""}>
                        {days>10?"FREE":"₹990"}
                      </span>
                      </div>
                      }
                      {
                        <div style={{ fontWeight: "700", marginTop: "5px" }}>
                        Drop-Off Charges : <span style={{ fontWeight: "400", marginTop: "5px" }}>
                        ₹{990}
                      </span>
                      </div>
                      }
                      <div
                        style={{
                          fontWeight: "700",
                          borderTop: "2px solid grey",
                          marginTop: "5px",
                        }}
                        className="pink"
                      >
                        Total : <span
                        style={{
                          fontWeight: "700",
                          borderTop: "2px solid grey",
                          marginTop: "5px",
                        }}
                        className="pink"
                      >
                        ₹{(packageAmount*days)+pickupCharges+dropOffCharges+thirdMeal+nonVeg}
                      </span>
                      </div>
                    </>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                 
                   <>
                      <div style={{ fontWeight: "400" }}>₹{data.PetSize==="Small Pet"?499*days:599*days}</div>
                      {data.ThirdMealAdded == "Yes" ? (
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{59 * days}
                        </div>
                      ) : null}
                      {data.NonVegMealAdded == "Yes" ? (
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                          ₹{99 * days}
                        </div>
                      ) : null}
                      {
                        <div style={{ fontWeight:days>10?"700": "400", marginTop: "5px" }} className={days>10?"pink":""}>
                        {days>10?"FREE":"₹840"}
                      </div>
                      }
                      {
                        <div style={{ fontWeight: "400", marginTop: "5px" }}>
                        ₹{840}
                      </div>
                      }

                      <div
                        style={{
                          fontWeight: "700",
                          borderTop: "2px solid grey",
                          marginTop: "5px",
                        }}
                        className="pink"
                      >
                        ₹{(packageAmount*days)+pickupCharges+dropOffCharges+thirdMeal+nonVeg}
                      </div>
                    </>
                </div> */}
              </div>
            </div>
          </div>

          <div className="order-confirmation-mobile-summary-container">
            <div>
              <img
                alt="tickImage"
                className="order-confirmation-mobile-summary-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
              />
            </div>
            <div className="order-confirmation-mobile-summary-text">
              Your information has been sent to our pet counselor, We will reach
              you soon.
            </div>
          </div>

          {
            data.PayNow?<button className="order-summary-mobile-download-button" style={{width:"100%",outline:"none",border:"none"}}>
            Download Invoice
          </button>:<button className="order-summary-mobile-download-button" style={{width:"100%",outline:"none",border:"none",color:"black"}} onClick={navigateHome}>
            Back to home
          </button>
          }

          <div className="order-summary-mobile-footer-container">
            <div>
              <img
                alt="petmojoLogo"
                className="order-summary-mobile-footer-logo"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
              />
            </div>
            <div className="order-summary-mobile-footer-text">
              Copyright 2022 Petmojo
            </div>
            <div className="order-summary-mobile-footer-text">
              All copyrights are reserved - any infringement will be legally
              pursued
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummaryBoarding;
