export const data = [
    {
        title: "What does a pet border do? ",
        content:
          "We pick up your pet on the required date and time from your home and take care of your pet while you are away. We make sure that the pet is well fed and their walking is taken care of as well. We will give you regular updates on your pet for your peace of mind.",
      },
      {
        title: "Which breeds can the pet border take care of? ",
        content:
          "Our borders are trained to take care of any breed of Dog. We understand that the kind of care, food, and water required by different breeds of Dogs is very different. We make sure that the needs specific to your pet are fulfilled.",
      },
      {
        title: "Is Dog boarding safe? ",
        content:
          "We maintain very professional, secure, and hygienic boarding accommodations. We make sure that the place is clean and follows the safety protocols by Petmojo. We provide a safe environment for your dog so that you can enjoy a stress-free vacation.",
      },
      {
        title: "What if my dog takes some medication?",
        content:
          "We will make sure that your pet is looked after properly. If they have some ongoing medication, do let us know and give the medicines. We will make sure that they are given the medicines on time. ",
      },
      {
        title: "Can I give my own food? ",
        content:
          "Absolutely. Our priority is the comfort of your pet. If you feel they will be more comfortable with the food you provide, you are most welcome to provide the food.",
      },
]