import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import styles from "./NavigationBar.module.css";

function NavigationBar() {
  const [navDropdown, setNavDropdown] = useState(false);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);



  return (
    <div className="navbar-blogDetail">

      <div>
        <Link to="/">
        <svg className={styles.arrowIcon} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        </Link>
      </div>
          

      <div className={styles.item3}>
        <button className="nav-menu">
          {/* <LazyLoad > */}
          <img
            src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/nav-menu-icon_m4iurc.png"}
            alt="nav-menu"
            onClick={() => setNavDropdown(!navDropdown)}
          ></img>
          {/* </LazyLoad> */}
        </button>
        {navDropdown ? (
          <div className="nav-dropdown" ref={dropdownRef}>
            <div className="nav-dropdown-row-first">
              <Link
                to="/dog-training"
                className="dropdown-link"
                state={{from : "navigation"}}
              >
                {/* <LazyLoad > */}
                
                {/* </LazyLoad> */}
                <div className="dropdown-text">Dog Training</div>
              </Link>
            </div>
            <div className="nav-dropdown-row">
              <Link to="/dog-running" state={{from: "navigation"}} className="dropdown-link">
                {/* <LazyLoad > */}
              
                {/* </LazyLoad> */}
                <div className="dropdown-text">Daily Dog Walking/Exercise</div>
              </Link>
            </div>
            <div className="nav-dropdown-row">
              <Link to="/cat-training" state={{from: "navigation"}} className="dropdown-link">
                {/* <LazyLoad > */}
              
                {/* </LazyLoad> */}
                <div className="dropdown-text">Cat Training</div>
              </Link>
            </div>
            <div className="nav-dropdown-row">
              <Link to="/grooming" state={{from: "navigation"}} className="dropdown-link">
                {/* <LazyLoad > */}
                
                {/* </LazyLoad> */}
                <div className="dropdown-text">Pet Grooming</div>
              </Link>
            </div>
            <div className="nav-dropdown-row-last">
              <Link to="/about" className="dropdown-link">
                {/* <LazyLoad > */}
                
                {/* </LazyLoad> */}
                <div className="dropdown-text">About</div>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default NavigationBar;
