import Accordion from "../../../components/Accordion";
import AccordionDesktop from "../../../components/AccordionDesktop";

import { data } from "../../../mockData/mockDataTraining";

import {Link} from 'react-router-dom';

function FAQContainerGrooming(props) {
  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };
  return (

    
    <>
    {
      (props.view === "mobile") ? (
        <div className="centerAlign">
          <div>
            <div className="textbox2 faq-title">FAQ'S</div>
            <hr className="vector2"></hr>

              <div className="grey-text">Most frequent Questions and Answers</div>
            

            <div className="accordion">
              {data.map(({ title, content }) => (
                <Accordion view={"mobile"} title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      ) : (
        <div className="faq-master-container-desktop">
          <div className="faqContainerDesktop">
            <div className="faqContainerDesktop-left">
              <div className="textbox2-desktop faq-title-desktop">FAQ'S</div>
              <div className="line-div">
                      <hr className="live-training-pink-line"></hr>
                    </div>
              <div className="grey-text-desktop" style={{position:"relative",bottom:"10px"}}>Most frequent Questions and Answers</div>
              <div className="bookingbutton" style={{marginTop:"10px"}}>
                {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                  <button className="booksession" onClick={()=>scrollToTop()} style={{}}>Book a FREE Session</button>
                {/* </Link> */}
                <div className="limitedslots">Hurry Up, Limited slots left!</div>
              </div>
            </div>

            <div className="accordion-desktop">
              {data.map(({ title, content }) => (
                <AccordionDesktop title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      )
    }
      
    </>
  );
}

export default FAQContainerGrooming;
