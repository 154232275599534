import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

function BlogCardGeneratorDesktop(props) {
    

    return (
        <Link to={`/blog/${props.id}`} className="blog-card-generator-card-link" style={{textDecoration: "none"}}>
            <div className={props.device === "laptop" ? "blog-card-generator-desktop-master-container" : "blog-card-generator-mobile-master-container"}>
                <div className='blog-card-generator-desktop-image-container'>
                    <img alt="blogImage" className='blog-card-generator-desktop-image' src={props.src} />
                    <div className='blog-card-generator-desktop-view-date-container'>
                        {props.views} Views | {props.date}
                    </div>
                </div>
                {/* <div className="blog-card-generator-desktop-heading"> */}
                    <h2 className="blog-card-generator-desktop-heading"> {props.heading}</h2>
                    
                {/* </div> */}
                {/* <div className='blog-card-generator-desktop-content'>
                    
                </div> */}
                <p className='blog-card-generator-desktop-content'>
                    {props.content}
                </p>
                <div className='blog-card-generator-desktop-bottom'>
                    <div>Latest</div>
                    <div><Link to={`/blog/${props.id}`} style={{textDecoration: "none"}}>Read More</Link></div>
                </div>
            </div>
        </Link>
    );
};

export default BlogCardGeneratorDesktop;