import Accordion from "../../../components/Accordion";
import AccordionDesktop from "../../../components/AccordionDesktop";

import { data } from "../../../mockData/mockData";

function FAQContainer(props) {
  return (

    
    <>
    {
      (props.view === "mobile") ? (
        <div className="centerAlign">
          <div>
            <div className="textbox2 faq-title">FAQ'S</div>
            <hr className="vector2"></hr>

            <div className="grey-text">Most frequent Questions and Answers</div>

            <div className="accordion">
              {data.map(({ title, content }) => (
                <Accordion view={"mobile"} title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      ) : (
        <div className="faq-master-container-desktop">
          <div className="faqContainerDesktop">
            <div className="faqContainerDesktop-left">
              <div className="textbox2-desktop faq-title-desktop">FAQ'S</div>
              <div className="line-div">
                      <hr className="live-training-pink-line" style={{position:"relative",top:"5px"}}></hr>
                    </div>
              <div className="grey-text-desktop" style={{position:"relative",bottom:"0px"}}>Most frequent Questions and Answers</div>
            </div>

            <div className="accordion-desktop">
              {data.map(({ title, content }) => (
                <AccordionDesktop title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      )
    }
      
    </>
  );
}

export default FAQContainer;
