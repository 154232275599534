import React, { useState, useEffect, useRef } from "react";
import "./More.css";
import LazyLoad from "react-lazyload";

import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import {Link} from 'react-router-dom';
import GoToTop from "./GoToTop";

function More() {
  const [navDropdown, setNavDropdown] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div>
    <NavigationBar />
    <div className="more">

      
      <div className="more-page-mobile-view-master">

      
      <div className="more-page-master-container">
        <div className="more-page-mobile-view-left">
          <div className="more-page-mobile-view-left-head">
            Download Our App
          </div>
          <div className="more-page-mobile-view-left-content">
            Download our application to get exciting offers on Pet Services.
          </div>
        </div>
        <div className="more-page-mobile-view-right">
          <div>
            <img alt="containerMoreIcon" className="more-mobile-view-icon-1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8866_yvefwk.png" />
          </div>
          <div>
            <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
              <img alt="goMoreIcon" className="more-mobile-view-arw" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
            </a>
          </div>
        </div>
      </div>

      <div className="more-page-master-container">
        <div className="more-page-mobile-view-left">
          <div className="more-page-mobile-view-left-head">
            Help Center
          </div>
          <div className="more-page-mobile-view-left-content">
            Pleae get in touch and our expert Support team will answer all your questions
          </div>
        </div>
        <div className="more-page-mobile-view-right">
          <div>
            <img alt="containerMoreIcon" className="more-mobile-view-icon-1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8869_ucdwqt.png" />
          </div>
          <div>
            <Link to="/help">
              <img alt="goMoreIcon" className="more-mobile-view-arw" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
            </Link>         
          </div>
        </div>
      </div>

      <div className="more-page-master-container">
        <div className="more-page-mobile-view-left-service">
          <div className="more-page-mobile-view-left-head">
            Become Petmojo Service Partner
          </div>
          <div className="more-page-mobile-view-left-content">
            By focusing on our Pet parents, we work closely with you to contribute best pet service.
          </div>
        </div>
        <div className="more-page-mobile-view-right">
          <div>
            <img alt="containerMoreIcon" className="more-mobile-view-icon-1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8872_f5l0cj.png" />
          </div>
          <div>
            <Link to="/partner">
              <img alt="goMoreIcon" className="more-mobile-view-arw" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
            </Link>          
          </div>
        </div>
      </div>

      <div className="more-page-master-container">
        <div className="more-page-mobile-view-left-service">
          <div className="more-page-mobile-view-left-head">
            Blogs
          </div>
          <div className="more-page-mobile-view-left-content">
          We love to share! On this section you will get to know about Pet Health, Food, and all Pet related contents.
          </div>
        </div>
        <div className="more-page-mobile-view-right">
          <div>
            <img alt="containerMoreIcon" className="more-mobile-view-icon-1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8875_zyz4au.png" />
          </div>
          <div>
            <Link to="/blog">
              <img alt="goMoreIcon" className="more-mobile-view-arw" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
            </Link>          
          </div>
        </div>
      </div>

      </div>
    </div>

    <br></br><br></br><br></br><br></br><br></br><br></br>

    <div>{" "}</div>
    <GoToTop/>
    </div>
  );
}

export default More;
