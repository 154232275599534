import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Training.css";
import LazyLoad from "react-lazyload";
import { BsArrowUp } from "react-icons/bs";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet-async";
import TimelineComponent from "./TimelineComponent";
import HeartContainer from "../components/container/heartContainer/HeartContainer";
import classes from "../pages/Landing/LandingPage.module.css";
import FAQContainerTraining from "../components/container/faqContainer/FAQContainerTraining";
import { useLocation } from "react-router-dom";
import IntroContainerDesktopTraining from "./container/introContainer/IntroContainerDesktopDeleteAccount";
import GoToTop from "./GoToTop";
import IntroContainerMobileTraining from "./container/introContainer/IntroContainerMobileDeleteAccount";
import Slider from "react-rangeslider";
import Loader from 'react-dots-loader'
import { BsChevronRight,BsChevronLeft } from "react-icons/bs";

function DeleteAccount () {
    const [isActive, setIsActive] = useState(false);
    const [changeView, setChangeView] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 1000) {
          setChangeView(false);
        } else {
          setChangeView(true);
        }
      }, []);

    return (
        <div className={!changeView ? "training" : "training-desktop"}>
      <Helmet>
        <title>Professional Dog Training at home - Dog Trainer near you - Petmojo</title>
        <meta
          name="description"
          content="Book Dog Training at the comfort of your home. Petmojo offers the best dog training services. Best Dog Trainers near you. "
        />
        <link rel="canonical" href="/dog-training" />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileTraining />
      ) : (
        <IntroContainerDesktopTraining />
      )}
      </div>
    )

}

export default DeleteAccount;