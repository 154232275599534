import {useEffect, useState} from 'react';
import NavigationBar from '../../Navigation/navigationBar/NavigationBar';
import WalkingPageForm from '../mobileForms/WalkingPageForm';
import {Link} from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";

const IntroContainerMobileTraining = (props) => {
  const [showad, setShowad] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  const removeAdd = () => {
    if(document.getElementById("grooming-popup-ad")){

      const ele = document.getElementById("grooming-popup-ad");
      ele.style.display = "none";
      setShowad(false);
    }
  };
  setTimeout(() => {
    if(document.getElementById("grooming-popup-ad")){

      const ele = document.getElementById("grooming-popup-ad");
      ele.style.display = "flex";
    }
  }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }  
    return (
        
      <div className='social-page-intro-mobile-view'>
         {showad && (
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "60px",
            display: "none",
            gap: "5px",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-evenly",
          }}
          id="grooming-popup-ad"
        >
          <div onClick={removeAdd}>
            <RxCross2 color="#5E5E5E" />
          </div>
          <div style={{ height: "100%" }}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-ad-logo.png"
              alt="logo"
              style={{ height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "DM Sans",
              height: "100%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "700",
              }}
            >
              Petmojo
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "400",
              }}
            >
              Download our app & get <br />{" "}
              <span style={{ color: "rgba(35, 175, 0, 1)" }}>₹1000</span> in
              your Wallet{" "}
            </div>
          </div>
          <a
            href={url}
            target="_blank" rel="nofollow"
            style={{
              textDecoration: "none",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="button-group" style={{ height: "80%" }}>
              <button
                className=""
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  outline: "none",
                  border: "2px solid #DEC269",
                  color: "#DEC269",
                  fontFamily: "DM Sans",
                  backgroundColor: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                DOWNLOAD
              </button>
            </div>
          </a>
        </div>
      )}
        <NavigationBar btn={"training"} homePage={false} page={"white"} className="home-page-mobile-navigation-container" />
        <div className='home-page-intro-mobile-view-container'>
          <div className='home-page-intro-mobile-view-heading-training'>
            <div>World's Best <span className='pink-bold'>Pets</span></div>
            <div><span className='pink-bold'>Social Media App!</span></div>
            <div><span className='pink-bold'>16000+</span> Pet Parents</div>
          </div>
          <div className='home-page-intro-mobile-view-points-training'>
            <div className='home-page-intro-mobile-view-point'>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
              <div>Go through our Educational content</div>
            </div>
            <div className='home-page-intro-mobile-view-point'>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
              <div>We have a supportive community</div>
            </div>
            <div className='home-page-intro-mobile-view-point'>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
              <div>Discover Pet services that can make your life easier.</div>
            </div>
          </div>
          <div className="home-page-mobile-form-container">
            <div className="bookingbutton">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                    <button className="booksession-mobile-view">Download Our App</button>
                </a>
            </div>
          </div>
        </div>
  
      </div>
        
    );
};

export default IntroContainerMobileTraining;