import React, { useState, useEffect, useRef } from "react";
import "./Services.css";
import LazyLoad from "react-lazyload";
import {Link} from 'react-router-dom';


import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import GoToTop from "./GoToTop";

function Services() {
  const [navDropdown, setNavDropdown] = useState(false);
  const [filter, setFilter] = useState("");

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div>
      <NavigationBar />
      <div className="all-services">

        <div className="services-page-mobile-view-heading">
          Our Services
        </div>
        <div className="services-page-mobile-view-subhead">
          Select a service of your choice & check our packages
        </div>

        <div className="services-page-mobile-view-service-1-1">
          <img alt="serviceIcon" className="services-page-mobile-view-icon1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/2_no1bqc.png" />
          <div className="services-page-mobile-view-service-1-contents">
            <div className="services-page-mobile-view-service-1-contents-head">
              Dog Training
            </div>
            <div className="services-page-mobile-view-service-1-contents-content">
              Get your pet a Loving, Trusted and Professional Trainer!
            </div>
            <div className="service-page-go-icon-container">
              <Link to="/dog-training" state={{from : "services"}}>
                <img alt="goIcon" className="service-page-go-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
              </Link>
            </div>
          </div>
        </div>

        <div className="services-page-mobile-view-service-1">
          <img alt="serviceIcon" className="services-page-mobile-view-icon1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/3_whylmd.png" />
          <div className="services-page-mobile-view-service-1-contents">
            <div className="services-page-mobile-view-service-1-contents-head">
              Pet Exercise/Walking
            </div>
            <div className="services-page-mobile-view-service-1-contents-content">
              India’s Most Trusted Daily Dog Exercise/Walking
            </div>
            <div className="service-page-go-icon-container">
              <Link to="/dog-running" state={{from: "services"}}>
                <img alt="goIcon" className="service-page-go-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
              </Link>
            </div>
          </div>
        </div>

        <div className="services-page-mobile-view-service-1">
          <img alt="serviceIcon" className="services-page-mobile-view-icon1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_947_jmyuyu.png" />
          <div className="services-page-mobile-view-service-1-contents">
            <div className="services-page-mobile-view-service-1-contents-head">
              Pet Grooming
            </div>
            <div className="services-page-mobile-view-service-1-contents-content">
              We Provide Best Pet Care Services At The Comfort Of Your Home!
            </div>
            <div className="service-page-go-icon-container" >
              <Link to="/grooming" state={{from: "services"}}>
                <img alt="goIcon" className="service-page-go-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
              </Link>
            </div>
          </div>
        </div>

        <div className="services-page-mobile-view-service-1-1">
          <img alt="serviceIcon" className="services-page-mobile-view-icon1" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_947_p6bi09.png" />
          <div className="services-page-mobile-view-service-1-contents">
            <div className="services-page-mobile-view-service-1-contents-head">
              Pet Hotel
            </div>
            <div className="services-page-mobile-view-service-1-contents-content">
              Trusted Pet Hotel in your Neighbourhood!
            </div>
            <div className="service-page-go-icon-container">
              <Link to="/pet-boarding" state={{from : "services"}}>
                <img alt="goIcon" className="service-page-go-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8479_kvkuzp.png" />
              </Link>
            </div>
          </div>
        </div>


        <GoToTop/>
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>

  );
}

export default Services;
