import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import WalkingPageForm from './WalkingPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';
function  IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container" style={{backgroundColor:"#F8F8F8"}}>
            <div className='wrapper-class-intro-desktop-video' style={{position:"absolute"}}>
              {/* <video style={{width: "100vw", background: "black"}} role="presentation" crossOrigin="anonymous" playsInline preload="auto" muted loop tabIndex="-1" autoPlay  className='home-page-desktop-background-video'>
                <source
                  src="https://video.wixstatic.com/video/a7477d_c08f4977d5c446bcb182402b6d807dd0/480p/mp4/file.mp4"
                  type="video/mp4"
                  style={{width: "100%",background:"rgba(0, 0, 0, 0.2)"}}
                />
              </video> */}
              <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walkingwebsite.webp" alt="" style={{width:"100vw",height:"100vh",objectFit:"cover"}}/>
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              
              <NavigationDesktop submissionType={"dog-running"} submissionText={"Book 1st FREE Walk"}  />
              <div className='intro-desktop-head-combiner' style={{marginTop:"0px"}}>  
                <div className='intro-desktop-home-page-header'>
                  <h1 className='home-page-desktop-heading'>
                    <div className='intro-desktop-home-page-header1'>India's Most <span className='pink-bold'>Trusted</span></div>
                    <div className='intro-desktop-home-page-header1'>Daily Dog <span className='pink-bold'>Exercise/Walking</span></div>
                  </h1>
                </div>
                <div className='intro-desktop-walking-page-points-container'>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Loving and Trusted dog Walkers in your Neighbourhood</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Improve your pet's Lifestyle and Health</div>
                  </div>
                </div>
              
                  <WalkingPageForm />
              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
