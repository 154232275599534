
// export default TrainingPageForm;
import "./MobileFormsCSS.css";
import { useState, useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import petmojologo from "../../../images/logo.png";
import DatePicker from "react-datepicker";
import downloadInvoice from "../../../images/download-invoice.png";
import groomingvector from "../../../images/grooming-vector.jpg";
import greenTick from "../../../images/green-tick.png";
import googlePlay from "../../../images/play-store.png";
import appleStore from "../../../images/app-store.png";
import { BiArrowBack, BiChevronDown } from "react-icons/bi";
import { useCallback } from "react";
import { useGeolocated } from "react-geolocated";
import ImageSlider from "../../ImageSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageSliderWalking from "../../ImageSliderWalking";
import {FaArrowAltCircleRight,FaCheck} from "react-icons/fa";
import {FaXmark} from "react-icons/fa6"
import iconsMobile from "../../../images/trainingFooterMobile.png";

function useGetLocation() {
  const [location, setLocation] = useState(null);

  const { latitude, longitude } = useGeolocated();
  setLocation({ latitude, longitude });
  // useEffect(() => {
  // }, []);

  return location;
}

//import dotenv from 'dotenv'
const images=[
  {
    id:1,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop.webp",
    alt:"Image 1"
  },
  {
    id:2,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop-2.webp",
    alt:"Image 2"
  },
  {
    id:3,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/service-image-desktop-3.webp",
    alt:"Image 3"
  },
 
  
]
const images1=[
  {
    id:1,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-1.webp",
    alt:"Image 1"
  },
  {
    id:2,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-2.webp",
    alt:"Image 2"
  },
  {
    id:3,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-3.webp",
    alt:"Image 3"
  },
  {
    id:4,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-4.webp",
    alt:"Image 4"
  },
  {
    id:5,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-5.webp",
    alt:"Image 5"
  },
  {
    id:6,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-mobile-1.webp",
    alt:"Image 2"
  },
  {
    id:7,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-mobile-2.webp",
    alt:"Image 3"
  },
  {
    id:8,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-mobile-3.webp",
    alt:"Image 4"
  },
  {
    id:9,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/walking-mobile-4.webp",
    alt:"Image 5"
  },
  {
    id:10,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-mobile-1.webp",
    alt:"Image 5"
  },
  {
    id:11,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-mobile-2.webp",
    alt:"Image 5"
  },
  {
    id:12,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-mobile-3.webp",
    alt:"Image 5"
  },
  {
    id:13,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-1.webp",
    alt:"Image 5"
  },
  {
    id:14,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-2.webp",
    alt:"Image 5"
  },
  {
    id:15,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-3.webp",
    alt:"Image 5"
  },
  {
    id:16,
    src:"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-mobile-4.webp",
    alt:"Image 5"
  },
  
]

const WalkingPageForm = (props) => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed:500,
    autoplay:true,
    // variablewidth:true

};
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  const [otp, setOtp] = useState("");
  const [bookNow, setBookNow] = useState(false);
  const otpRef = useRef(null);
  const navigate = useNavigate();
  const [sessionDate, setSessionDate] = useState();
  const location = useLocation().pathname;
  const [seconds, setSeconds] = useState(0);
  // console.log("location: ",location.pathname)
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [correctOtp, setCorrectOtp] = useState(true);
  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingAddressRef1 = useRef("");
  const groomingDateRef = useRef("");
  const groomingSlotRef = useRef("");
  const groomingOtpRef = useRef("");
  const petRef=useRef("");
  const [countdown, setCountdown] = useState(true);
  const [startValue, setStartValue] = useState(false);
  const [startDate, setStartDate] = useState(formattedDate);
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [petFill,setPetFill]=useState("");
  const [petValue,setPetValue]=useState(false);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [slotValue, setSlotValue] = useState(false);
  const [slotFill, setSlotFill] = useState("0");
  const freeSlot=["05AM-11AM","11AM-05PM","05PM-09PM"];
  const monthlySlot=["05AM-06AM"];
  const [isFreeSelected,setIsFreeSelected]=useState(true);
  const [isMonthlySelected,setIsMonthlySelected]=useState(false);

  const [walkerType, setWalkerType] = useState("pro")
  // const groomingEmailRef = useRef("");
  const groomingNameRef = useRef("");
  const [inputDate, setInputDate] = useState("");
  const [inputSlot, setInputSlot] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  const [userData, setUserData] = useState({
    Trial: "",
    Package: "",
    NumberPets: "",
    Date: startDate,
    Slot: "",
    Name: "",
    Phone: "",
    Address: "",
    Latitude: 0,
    Longitude: 0,
  });

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("BATH & BRUSH");
  const [selectedTrial, setSelectedTrial] = useState("Free Trial");
  const [monthlyPackage, setMonthlyPackage] = useState("Once a day");
  const [numberPets, setNumberPets] = useState("One");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingAddressValidation1, setGroomingAddressValidation1] =
    useState(true);

  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [groomingNameValidation, setGroomingNameValidation] = useState(true);
  const [petValidation,setPetValidation]=useState(true);  
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(1);
  const [petsnumber, setPetsnumber] = useState(1);
  const [selectedPetChoice, setSelectedPetChoice] = useState(1);
  const [navTab, setNavTab] = useState(1);
  const [formSection, setFormSection] = useState(1);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [showDownloadInvoice, setShowDownloadInvoice] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedTime, setSelectedTIme] = useState("05AM-11AM");
  const [price,setPrice]=useState(3990);
  const sectionRef = useRef(null);
  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  useEffect(()=>{

  },[selectedTrial])

  const applyCoupon = async() =>{
    
    await axios
      .post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/Verifycoupon",
      {
        name:couponCode,
        serviceType:2,
        package:"Pet Hotel"
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      })
      .then((response)=>{
        console.log(response)
        if(response.data.value){
          setCouponValue(response.data.value)
          setCouponErrorMsg("")
          setCouponNotValid(false)
          setCouponValid(true)   
        }else{
          setCouponValid(false)
          setCouponNotValid(true)
          setCouponErrorMsg(response.data.msg)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    
  }

  const firstFormWalkingHandler=()=>{
    if(phoneFill==null || phoneFill==""){
      setGroomingPhoneValidation(false);
    }
    else{
      setSelectedCarousel(3);
    }
  }
  const firstFormDataHandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (groomingDateRefCurrValue !== "") {
      setInputDate(groomingDateRefCurrValue);
      setInputSlot(groomingSlotRef.current.value);
      // let time = "";
      // if (timeSlot === 1) {
      //   time = "05AM-11AM";
      // } else if (timeSlot === 4) {
      //   time = "05AM-06AM";
      // }
      // setSelectedTIme(time);
      setUserData({
        ...userData,
        Trial: selectedTrial,
        Package: monthlyPackage,
        NumberPets: numberPets,

        Date: groomingDateRefCurrValue,
        Slot: inputSlot,
        Latitude: lat,
        Longitude: lon,
      });
      //   setSelectedCarousel(2);
      console.log(userData);
    } else {
      console.log("data coll err");
    }
  };
  const secondFormDataHandler = () => {
    if (
      groomingNameRef.current.value !== "" &&
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setUserData({
        ...userData,
        Name: groomingNameRef.current.value,
        Phone: phone,
        Address: groomingAddressRef.current.value,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const nextNavhandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;


    if (groomingDateRefCurrValue !== "" && groomingSlotRef.current.value!="" && (phoneFill!=null && phoneFill!="")) {
      console.log(userData);
      setSelectedCarousel(3);
      // setFormSection(3);
    } else if (groomingDateRefCurrValue === "") {
      console.log("error");
      setGroomingDateValidation(false);
    }

    if (groomingDateRefCurrValue === "" && groomingSlotRef.current.value!="") {
      console.log("error");
      setGroomingSlotValidation(false);
      setGroomingDateValidation(false);
    }
    if(phoneFill==null || phoneFill==""){
      setGroomingPhoneValidation(false);
    }
  };

  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }
  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp").innerHTML = "Resend OTP";
  };

  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setCorrectOtp(true);
        setToken(res.data.token);
        setCountdown(false);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setCorrectOtp(false);
        setIsWrongOtp(true);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOtp) {
      document.getElementById("send-otp").style.borderColor = "red";
    }
    // else{

    // }
  };
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        // if(document.getElementById("countdown-seconds")){

        //   document.getElementById("countdown-seconds").style.display = "none";
        // }
        } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  const handleOtpChange = (event) => {
    const { value } = event.target;
    setOtp(value);
  };

  // function to handle the form submission
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(`Submitting OTP: ${otp}`);
  };

  // function to handle the "Paste" event and autofill OTP
  const handlePaste = (event) => {
    // event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    setOtp(pastedText);
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    inputPhone,
    InputAddress,
    bookingId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Walking Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        console.log(result.data);
        //setRazorpayOrderId(result.data.orderId)

        const postGroomingPayment = async (bId, oId, amt) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
              headers:{
                Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY"
              } 
            },
          };
          try {
            console.log(bId, oId, amt);
            const res = await axios.patch(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
              {
                bookingId: bId,
                transactionId: oId,
                amount: amt,
                type: "User",
              },
              config
            );
            console.log(res.data);
          } catch (error) {
            console.log(bId, oId, amt);
            console.log(error);
          }
        };

        if (result.status === 200) {
          postGroomingPayment(bookingId, result.data.orderId, serviceAmount);
          setNavTab(2);
        }
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The boarding payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "support@petmojo.care",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }


  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      const ipAddress = response.data.ip;
console.log(ipAddress);
      // Fetch geolocation information using the obtained IP address
      const geoResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=ebe17d64fb1b22`);
      console.log(geoResponse.data)
      // setIpInfo(geoResponse.data);
      setAddress(geoResponse.data.region);
    } catch (error) {
      console.error('Error fetching IP information:', error);
    }
  };
console.log("city",address)
  useEffect(() => {
    // Fetch the user's dynamic IP address
    

    fetchIpAddress();
  }, []);



  const finalFormSubmitHandler = async (flag) => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    if(phoneFill==null || phoneFill==""){
      setGroomingPhoneValidation(false);
    }
    else if(selectedPetChoice===2 && nameFill==""){
      setGroomingNameValidation(false);
    }
    else if(selectedPetChoice===2 && address==""){
      setGroomingAddressValidation(false);
    }
    else{
      if (
        checkPhone(phoneFill).length === 10 
      ) {
        // console.log(userData);
        // console.log(userData.Phone)
        const config = {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        };
        setIsLoading(true);
        try {
          // console.log("hey1")
          const phone =
            userData.Phone.length > 10 &&
            (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
              ? userData.Phone.slice(-10)
              : userData.Phone;
          // console.log(phone)
          const res = await axios.post(
            `${baseUrl}/serviceBooking/websiteDogWalkingBookingService`,
            {
              type: "User",
              numberOfPets:petsnumber,
              isFree:selectedPetChoice===1,
              address: address,
              phone: phone,
              package: {
                description: selectedPetChoice===1?"Free Session": `Monthly ${monthlyPackage}`,
                amount:selectedPetChoice===1?0: (((price*118)/100)-couponValue),
                frequency:selectedPetChoice===1?1:26,
                dayfrequency:selectedPetChoice===1?1:monthlyPackage=="Once a day"?1:monthlyPackage=="Twice a day"?2:3
              },
              startDate: startDate,
              runTime1: selectedPetChoice===1?"":"05AM-06AM",
              amount:selectedPetChoice===1?0: price,
              isCouponCodeUsed:couponValue===0?false:true,
              couponCode:couponValue!==0?couponCode:"",
            },
            config
          );
          // console.log("hey2")
          console.log(res.data);
          console.log(res.status);
          if (flag) {
            const data = {
              ContactNo: userData.Phone,
              DateOfRegistration: dateOfReg,
              TimeOfRegistration: timeOfReg,
              FormType: "Direct Form",
              TrialDate: userData.Date,
              Address: address,
              Name:userData.Name,
              Slot:"05AM-06AM"
            };
  
            await axios
              .post(
                "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData",
                data
              )
              .then((response) => {
                console.log(response);
                console.log("The walking form is being submitted...");
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                console.log(
                  "There was some error in submitting the walking form..."
                );
                setIsLoading(false);
              });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: `Walking Registration Fired From Mobile Service Page Form`,
            });
            setShowDownloadInvoice(true);
            displayRazorpay(
              (((price*118)/100)-couponValue),
              userData.Package,
              dateOfReg,
              userData.Phone,
              userData.Address,
              res.data.bookingId
            );
          } 
          else {
            console.log("hello guys")
            console.log("Slot-->",slotFill);
            console.log("Name",nameFill);
            const data = {
              
              ContactNo: userData.Phone,
              DateOfRegistration: dateOfReg,
              TimeOfRegistration: timeOfReg,
              FormType: "Direct Form ",
              TrialDate: userData.Date,
              Address: "",
              Name:nameFill,
              Slot:selectedPetChoice===1?freeSlot[Number(slotFill)]:"05AM-06AM",
              location:address
            };
  
            await axios
              .post(
                "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData",
                data
              )
              .then((response) => {
                console.log(response);
                console.log("The walking form is being submitted...");
                navigate("/thankPage");
              })
              .catch((err) => {
                console.log(err);
                console.log(
                  "There was some error in submitting the walking form..."
                );
              });
            // setShowDownloadInvoice(false);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: `Walking Registration Fired From Mobile Service Page Form`,
            });
            
          }
        } catch (error) {
          console.log("error------->", error);
        }
      } else if (groomingNameRef.current.value === "") {
        console.log("err");
        setGroomingNameValidation(false);
      } else if (
        checkPhone(groomingPhoneRef.current.value.toString()).length !== 10
      ) {
        console.log("err123");
        setGroomingPhoneValidation(false);
      } else if (groomingAddressRef.current.value === "") {
        console.log("err");
        setGroomingAddressValidation(false);
      }
  
      if (
        groomingNameRef.current.value === "" &&
        checkPhone(groomingPhoneRef.current.value.toString()).length !== 10 &&
        groomingAddressRef.current.value === ""
      ) {
        console.log("err");
        setGroomingNameValidation(false);
        setGroomingPhoneValidation(false);
        setGroomingAddressValidation(false);
      }
    }
  };
  const [changeColor, setChangeColor] = useState(false);
  const formShiftHandler = () => {
    window.scrollTo(0, 300);
  };

  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };
  useEffect(() => {
    indicateAlert();
  }, [correctOtp]);

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);

  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude);
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[9].formatted_address);
              // console.log(address)
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const trainingSubmissionHandler = async () => {
    console.log(userData);
    const inputPhone = groomingPhoneRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setIsLoading(true);
      const phone =
        userData.Phone.length > 10 &&
        (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
          ? userData.Phone.slice(-10)
          : userData.Phone;
      const data = {
        ContactNo: phone,
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        FormType: "Direct Form",
        Address: userData.Address,
        TrialDate: userData.Date,
      };

      await axios
        .post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveWalkingData", data)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Walking Registration Fired From Mobile Service Page Form`,
      });
      navigate("/thankPage");
    } else {
      if (checkPhone(groomingPhoneRef.current.value.toString()).length !== 10) {
        setGroomingPhoneValidation(false);
        groomingPhoneRef.current.value = "";
      }

      return;
    }
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  // const [otp, setOtp] = useState('');
  const [otpsent, setOtpsent]=useState(false);
  
  const [isOtpVerified, setOtpVerification] = useState(false);

  const sendOtp = async () => {
    try {
      // Make a POST request to send OTP to the provided phone number
      const response = await axios.post(
        'https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/sendMobileOTP',
        {
          phoneNumber: phoneNumber,
        }
      );

      // Handle the response, maybe show a success message
      console.log('OTP Sent:', response.data);
    } catch (error) {
      // Handle error, show error message
      console.error('Error sending OTP:', error);
    }
  };

  const verifyOtp = async () => {
    try {
      // Make a POST request to verify the entered OTP
      console.log(otp,phoneNumber)
      const response = await axios.post(
        'https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP',
        {
          otp:otp,
          phoneNumber:phoneNumber,
          type: "User"
      }
      );
      console.log(response.data)

      // If OTP is verified successfully, update state
      if (response.data.user && response.data.user.confirmed) {
        setOtpVerification(true);
      } else {
        setOtpVerification(false)

        // Handle unsuccessful OTP verification, show error message
        console.error('OTP Verification failed:', response.data.message);
      }
    } catch (error) {
      if(error && error.response && error.response.data.success===false){
        setOtpVerification(false)
      }
      // Handle error, show error message
      console.error('Error verifying OTP:', error.response);
    }
  };
  useEffect(()=>{
if(otp.length===6){
  verifyOtp()
}
  },[otp])





  return (
    <>
      {navTab === 1 && (
        <div className="grooming-page-mobile-view-form-container" style={{marginTop:location=="/"?"0px":"",width:location=="/"?"100%":"",border:location=="/"?"0px":"",borderRadius:location=="/"?"0px":""}}>
          {
            (location=='/dog-running' || location=='/dog-walking-meta')?<div className="grooming-page-mobile-view-form-section-1">
            <div style={{ display: "flex", textAlign: "start",gap:"5px" }} >
              <div style={{ width: selectedCarousel === 1 ? "0%" : "5%" }}>
                <BiArrowBack
                  style={{ display: selectedCarousel === 1 ? "none" : "block" }}
                  onClick={backArrow}
                  size={20}
                />
              </div>
              {
                selectedPetChoice===1?<div
                style={{
                  width: selectedCarousel === 1 ? "100%" : "90%",
                  fontSize: "17px",
                  textAlign: "center",
                }}
                onClick={scrollToSection}
              >
                
                  Book a FREE Walking Session!
              </div>:<div
                style={{
                  width: selectedCarousel === 1 ? "100%" : "95%",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                {/* {selectedPetChoice === 1
                  ? "Book a FREE Walking Session!"
                  : "Monthly dog walking at just ₹2940/- only"
                  } */}
                  Monthly dog walking at just <span style={{fontSize:"12px",color:"grey"}}>₹{price}/-</span> only
              </div>
              }
            </div>
            
          </div>:null
          }

          {selectedCarousel == 1 ? (
            <div className="grooming-page-mobile-view-services-container" ref={sectionRef}>
              <div className="">
                {/* <span style={{ color: "#5E5E5E", fontWeight: "700" }}>Type of your pet</span> */}
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedTrial("Free Trial");
                          setUserData({ Trial: "Free Trial" });
                          setMonthlyPackage("");
                          setTimeSlot(1);
                          // console.log("dog");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 1 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          {/* <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/freeTrial.png"
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              objectFit: "contain",
                            }}
                          /> */}
                          <span style={{ fontWeight: "700", fontSize: "15px",color:"rgba(88, 88, 88, 1)" }}>
                            One FREE Walk <span style={{color:"rgba(192, 192, 192, 1)"}}>(meet the walker)</span>{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedTrial("Get Subscription");
                          setUserData({ Trial: "Get Subscription" });
                          setTimeSlot(4);
                          setSlotFill("05AM - 06AM")
                          // console.log("cat");
                        }}
                        style={{ alignItems: "flex-start" }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 2 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          {/* <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/subscription.png"
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              objectFit: "contain",
                            }}
                          /> */}
                          <span style={{ fontWeight: "700", fontSize: "15px",color:"rgba(88, 88, 88, 1)" }}>
                            Get monthly Subscription
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {selectedTrial === "Get Subscription" ? (
                <>
                
                <div className="select-service">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Select number of pets
                </span>
                <div
                  className="options-box"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="option"
                    id="one"
                    onClick={() => {
                      setPetsnumber(1);
                      setNumberPets("One");
                      // setPrice(2940);
                      setPrice(monthlyPackage=="Once a day"?(walkerType==="standard"?3440:3990):monthlyPackage==="Twice a day"?(walkerType==="standard"?6960:7990):(walkerType==="standard"?9690:11970))
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div className="left">
                        <div className="selection-circle">
                          {numberPets == "One" && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "500" }}>One Pet</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    onClick={() => {
                      setPetsnumber(2);
                      setNumberPets("Two");
                      // setPrice(3940);
                      setPrice(monthlyPackage=="Once a day"?(walkerType==="standard"?4940:5490):monthlyPackage==="Twice a day"?(walkerType==="standard"?8460:9490):(walkerType==="standard"?11190:13470))

                    }}
                  >
                    <div
                      className="heading"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="left">
                        <div className="selection-circle">
                          {numberPets == "Two" && <div className="dot"></div>}
                        </div>
                        <span style={{ fontWeight: "500" }}>
                          Two Pets <br />
                          
                        </span>
                      </div>
                      
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
                <div className="select-service" style={{ marginTop: "10px" }}>
                  <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Choose monthly package
                  </span>
                  <div
                    className="options-box"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="option"
                      id="three"
                      onClick={() => {
                        setMonthlyPackage("Once a day");
                        setPrice(petsnumber===1?(walkerType ==="standard"?3440:3990):(walkerType === "standard"?4940:5490));
                      }}
                    >
                      <div className="heading" style={{ marginBottom: "5px" }}>
                        <div className="left">
                          <div className="selection-circle">
                            {monthlyPackage == "Once a day" && (
                              <div className="dot"></div>
                            )}
                          </div>
                          <span style={{ fontWeight: "500",fontSize:"12px" }}>Once a day</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="option"
                      id="four"
                      onClick={() => {
                        setMonthlyPackage("Twice a day");
                        setPrice(petsnumber===1?(walkerType ==="standard"?6960:7990):(walkerType === "standard"?8460:9490));
                      }}
                    >
                      <div
                        className="heading"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <div className="left">
                          <div className="selection-circle">
                            {monthlyPackage == "Twice a day" && (
                              <div className="dot"></div>
                            )}
                          </div>
                          <span style={{ fontWeight: "500",fontSize:"12px" }}>
                            Twice a day <br />
                            {/* <small style={{ fontSize: "13px" }}>
                            (With Tick Treatment)
                          </small> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="option"
                      id="five"
                      onClick={() => {
                        setMonthlyPackage("Thrice a day");
                        setPrice(petsnumber===1?(walkerType ==="standard"?9690:11970):(walkerType === "standard"?11190:13470));
                      }}
                    >
                      <div
                        className="heading"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <div className="left">
                          <div className="selection-circle">
                            {monthlyPackage == "Thrice a day" && (
                              <div className="dot"></div>
                            )}
                          </div>
                          <span style={{ fontWeight: "500",fontSize:"12px" }}>
                            Thrice a day <br />
                            
                          </span>
                        </div>
                        
                      </div>
                    </div>

                    <div></div>
                  </div>
                  <div>
                    <div className="select-service" style={{ marginTop: "10px" }}>
                      <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                      Select type of walker
                      </span>
                    <div className="options-box"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "",
                    }}>
                      <div className="option"
                      // id="three"
                      style={{ width: "50%" }}
                      onClick={() => {
                        setWalkerType("standard");
                        setPrice(petsnumber===1?(monthlyPackage === "Once a day"?3440:(monthlyPackage === "Twice a day"?6960:9690)):(monthlyPackage === "Once a day"?4940:(monthlyPackage === "Twice a day"?8460:11190)));
                      }}>
                        <div className="heading" style={{ marginBottom: "5px" }}>
                        <div className="left">
                          <div className="selection-circle">
                            {walkerType == "standard" && (
                              <div className="dot"></div>
                            )}
                          </div>
                          <span style={{ fontWeight: "500",fontSize:"12px" }}>Standard Walker</span>
                        </div>
                      </div>
                      </div >

                      <div className="option"
                      // id="three"
                      onClick={() => {
                        setWalkerType("pro");
                        setPrice(petsnumber===1?(monthlyPackage === "Once a day"?3990:(monthlyPackage === "Twice a day"?7990:11970)):(monthlyPackage === "Once a day"?5490:(monthlyPackage === "Twice a day"?9490:13470)));
                      }}>
                        <div className="heading" style={{ marginBottom: "5px" }}>
                        <div className="left">
                          <div className="selection-circle">
                            {walkerType == "pro" && (
                              <div className="dot"></div>
                            )}
                          </div>
                          <span style={{ fontWeight: "500",fontSize:"12px" }}>Pro Walker</span>
                        </div>
                      </div>
                      </div >
                    </div >
                    </div>
                  </div>
                  {selectedPetChoice === 2 ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "end",
                        paddingRight: "5px",
                        color:"rgba(114, 114, 114, 1)"
                      }}
                    >
                      <span style={{fontWeight:"400",fontSize:"18px"}}>Total:</span> <span style={{fontWeight:"800",fontSize:"20px"}}>₹{selectedPetChoice===1?"0":<>
                      <span style={{fontWeight:"bold",fontSize:"20px"}}>
                        {price}
                      </span>
                      <br/>
                      <span style={{fontSize:"10px"}}>
                        +GST
                      </span>
                      </>}</span>
                    </div>
                  ) : null}
                </div>
                </>
              ) : null}
              
              <div className="desc" style={{ fontFamily: "DM Sans" }}>
                <p
                  style={{
                    color: "rgba(88, 88, 88, 1)",
                    fontSize: "16px",
                    margin: "10px",
                  }}
                >
                  Includes
                </p>
                <div style={{
                      color: "rgba(131, 145, 161, 1)",
                      paddingLeft:"10px",
                      display:'flex',
                      flexWrap:"wrap",
                      gap:"2px"
                    }}>
                   {/* Walk Everyday (Sunday optional), GPS LIVE tracking on app, Attendance on app, Trusted & Certified Dog Trainers, 30 mins walk, Track Distance,
                          */}
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>GPS LIVE tracking on app</div>
            </div>
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>30 mins walk</div>
            </div>
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>Track Distance</div>
            </div>
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>Attendance on app</div>
            </div>
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>Trusted & Certified Dog Trainers.</div>
            </div>
                          <div className='home-page-intro-mobile-view-point' style={{alignItems:"center"}}>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" style={{height:"20px",width:"20px"}}/></div>
              <div style={{fontSize:"10x"}}>Walk Everyday (Sunday optional)</div>
            </div>
              
                </div>
                {/* <ul>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    Walk Everyday (Sunday optional)
                  </li>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    GPS LIVE tracking on app
                  </li>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    Attendance on app
                  </li>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    Trusted & Certified Dog Trainers
                  </li>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    30 mins walk
                  </li>
                  <li
                    style={{
                      fontSize: "13px",
                      color: "rgba(131, 145, 161, 1)",
                    }}
                  >
                    Track Distance
                  </li>
                </ul> */}
              </div>
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                {
                   selectedPetChoice===2 && (
                  <div className="grooming-new-form-section1-carousel"></div>

                   )
                }
                </div>
              </center>
              <div className="button-group">
                {groomingLoading ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <button
                    onClick={() => {
                      // firstFormDataHandler();
                      // setFormSection(2);

                      setSelectedCarousel(2);
                      // displayLocation();
                      // firstFormWalkingHandler();
                    }}
                    className="next"
                    style={{fontSize:"18px",color:"black"}}
                  >
                    {selectedTrial==="Free Trial"?"Book FREE Walk":"Next"}
                  </button>
                )}
                {/* <span
                  style={{
                    textAlign: "center",
                    color: "rgba(255, 94, 149, 1)",
                    fontSize: "15px",
                    fontStyle: "italic",
                  }}
                >
                  We are mission driven & a Family of Pet Lovers
                </span> */}
              </div>
              <div>
                <img src={iconsMobile} style={{ width: "21rem" }} />
              </div>
              <div className='training-page-mobile-view-form-footer' style={{margin:"10px 10px 10px 14px",}}>
                    <i>We are mission driven & a Family of Pet Lovers</i>
                </div>
            </div>
          ) : null}

          {/* 2nd form section */}

          {selectedCarousel == 2 ? (
            <div className="grooming-page-mobile-view-services-container">
              <div className="phone" style={{marginTop:"10px"}}>
                <div className="label" style={{display:"flex",justifyContent:"space-between"}}>
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    disabled={isVerified}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px",width:"100%" }}
                    value={phoneValue ? phoneFill : ""}
                    // value={userData.Phone}
                  />
                </div>
               
              </div>
              {
                location=='/dog-walking-meta' && <div className="phone" style={{marginTop:"10px"}}>
                <div className="label" style={{display:"flex",justifyContent:"space-between"}}>
                  <span>Pet Name</span>
                  {! petValidation? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={petRef}
                    disabled={isVerified}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setPetValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPetValue(true);
                      setPetFill(e.target.value);
                      // setUserData({
                      //   ...userData,
                      //   Phone: e.target.value,
                      // });
                    }}
                    type="text"
                    placeholder="Enter pet name"
                    required
                    style={{ fontSize: "17px",width:"100%",padding:"10px",height:"56px",marginTop:"5px",backgroundColor:"#F7F8F9",outline:"none",border:"1px solid #E8ECF4", borderRadius:"8px" }}
                    value={petValue ? petFill : ""}
                    // value={userData.Phone}
                  />
                </div>
               
              </div>
              }
              {
                selectedPetChoice===2 && (
                  <>
                  <div className="dateandtime">
                <div className="select-date">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    {selectedPetChoice===1?"Session Date":"Start Date"}
                  </div>
                  {!groomingDateValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}

                  <div
                    className="walking-section-form-input-date-mobile"
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      border: "none",
                      height: "56px",
                    }}
                  >
                    <input
                      ref={groomingDateRef}
                      onChange={() => {
                        firstFormDataHandler();
                        // secondFormDataHandler();
                        setGroomingDateValidation(true);
                        setStartValue(true);
                        setStartDate(groomingDateRef.current.value);
                      }}
                      type="date"
                      className="walking-section-form-input-date-mobile"
                      placeholder="DD/MM/YYYY"
                      required
                      style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "17px",
                      }}
                      value={startValue ? startDate : formattedDate}
                    />
                  </div>

                  {/* <DatePicker ref={groomingDateRef} dateFormat="yyyy-MM-dd"  onChange={(date) => { firstFormDataHandler(); 
                                        setGroomingDateValidation(true); 
                                        
                                        setSessionDate(date) }} className='walking-section-form-input-date-mobile' placeholderText='DD/MM/YYYY'  id='inputId' selected={sessionDate}  /> */}
                </div>
                <div className="select-slot">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Time Slot - {selectedPetChoice===1?slotFill=="05AM - 11AM"?"Morning":slotFill=="11AM - 05PM"?"Afternoon":"Evening":"Morning"}
                  </div>
                  {!groomingSlotValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}
                  <select
                    name="slot"
                    id="slot"
                    ref={groomingSlotRef}
                    onChange={(e) => {
                      firstFormDataHandler();
                      setGroomingSlotValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Slot:groomingSlotRef.current.value
                      // })
                      setSlotValue(true)
                      setSlotFill(groomingSlotRef.current.value)
                      setUserData({
                        ...userData,
                        Slot:selectedPetChoice===1?freeSlot[Number(e.target.value)]:monthlySlot[Number(e.target.value)]
                      })
                    }}
                    // onSelect={()=>{
                    //   setSlotValue(true);
                    //   setSlotFill(groomingSlotRef.current.value);
                    // }}
                    style={{fontSize:"16px",border:"2px solid rgba(255, 94, 149, 1) ",color:"rgba(255, 94, 149, 1)",width:"45%",marginTop:"5px",padding:"0px 8px"}}
                    // value={userData.Slot}
                    value={slotValue?slotFill:""}
                  >
                    <option value="0">{selectedPetChoice===1?"05AM - 11AM":"05AM - 06AM"}</option>
                    {
                      selectedPetChoice===1?<>
                    <option value="1">11AM - 05PM</option>
                    <option value="2">05PM - 09PM</option>
                      </>:null
                    }
                    
                  </select>
                  {/* <div
                    className="grooming-new-form-section2-right-time-slots"
                    style={{ justifyContent: "flex-start" }}
                  >
                    {selectedTrial === "Free Trial" ? (
                      <>
                        <div
                          onClick={() => setTimeSlot(1)}
                          className="grooming-new-form-section2-right-time-slot-active"
                        >
                          
                          <span>05AM-11AM</span>
                          <span>
                            <BiChevronDown />
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={() => setTimeSlot(4)}
                          className="grooming-new-form-section2-right-time-slot-active"
                          style={{ padding: "15px" }}
                        >
                          <span>05AM-06AM</span>
                          <span>
                            <BiChevronDown />
                          </span>
                        </div>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
                  </>
                )
              }
              <div style={{padding:"10px",marginTop:"10px",marginBottom:"30px",width:"100%"}}>
                <ImageSlider images={images}/>
              </div>
              <center>
                <div className="grooming-new-form-section1-carousel-container" style={{marginTop:"15px"}}>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  {
                    selectedPetChoice===2 && (
                      <div className="grooming-new-form-section1-carousel"></div>
                    )
                  }
                </div>
              </center>
              <div className="button-group" style={{ gap: "0px",marginTop:"20px" }}>
                {
                  selectedPetChoice===1?
                  isLoading ? (
                    <center>
                      <LoadingSpinner />
                    </center>
                  ) : ( 
                  <button
                  className="pay-now"
                  onClick={() => {
                    // secondFormDataHandler();
                    // firstFormDataHandler();
                    // firstFormWalkingHandler()
                    finalFormSubmitHandler(false);

                    // setFormSection(3);
                    // setSelectedCarousel(3);
                  }}
                >
                  Book a FREE Session
                </button>
                  )
                :
                <button
                  className="next"
                  onClick={() => {
                    // secondFormDataHandler();
                    // firstFormDataHandler();
                    // firstFormWalkingHandler()
                    nextNavhandler();

                    // setFormSection(3);
                    // setSelectedCarousel(3);
                  }}
                >
                  Select Address & Book
                </button>
                }
                {/* <div style={{ padding: "10px" ,fontWeight:"400",lineHeight:"18.75px"}}>
              
              <p style={{ color: "rgba(255, 94, 149, 1)", fontSize: "15px",fontStyle:"italic" }}>
              We are mission driven & a Family of Pet Lovers
              </p>
            </div> */}
                <span
                  style={{
                    textAlign: "center",
                    color: "rgba(255, 94, 149, 1)",
                    fontSize: "15px",
                    fontStyle: "italic",
                  }}
                >
                  We are mission driven & a Family of Pet Lovers
                </span>
              </div>
            </div>
          ) : null}

          {/*3rd form section */}
          {selectedCarousel == 3 ? (
            <div className="second-form-section">
              <div className="name">
                <div className="label" style={{display:"flex",justifyContent:"space-between"}}>
                  <span>Pet Parent Name</span>
                  {!groomingNameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingNameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingNameValidation(true);
                      setnameValue(true);
                      setNameFill(e.target.value);
                      setUserData({
                        ...userData,
                        Name: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{ fontSize: "17px" }}
                    value={nameValue ? nameFill : ""}
                  />
                </div>
              </div>
              {/* <div className="phone">
                <div className="label">
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    disabled={isVerified}
                    onChange={(e) => {
                      secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      setUserData({
                        ...userData,
                        Phone:groomingPhoneRef.current.value
                      })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px",width:"100%" }}
                    value={phoneValue ? phoneFill : ""}
                    // value={userData.Phone}
                  />
                  <button
                    id="send-otp"
                    disabled={isVerified}
                    onClick={() => {
                      setChangeColor(true);
                      getmobileOtp();
                      setShowEnterOtp(true);
                      changeName();
                      {
                        seconds === 0 ? setSeconds(33) : setSeconds(0);
                      }
                      handleSubmit();
                    }}
                    style={{ borderColor: correctOtp ? "white" : "red",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"" }}
                  >
                    Send OTP
                  </button>
                </div>
                {countdown && seconds != 0 ? (
                  <div
                    id="countdown-seconds"
                    style={{
                      textAlign: "right",
                      fontSize: "15px",
                      position: "relative",
                      right: "30px",
                    }}
                  >
                    {seconds} sec
                  </div>
                ) : null}
                {showEnterOtp &&
                  (groomingPhoneRef.current.value.toString().length === 10 ||
                    showDialog) && (
                    <div className="enterotp">
                      <span>Enter OTP</span>
                      <br />
                      {isWrongOtp && !isVerified ? (
                        <>
                          <div className="home-page-walking-form-mobile-feedback">
                            Wrong OTP
                          </div>
                        </>
                      ) : null}
                      <input
                        ref={groomingOtpRef}
                        onClick={() => {
                          setIsWrongOtp(false);
                        }}
                        disabled={isVerified}
                        type="number"
                        placeholder="Enter Otp"
                        value={otp}
                        onChange={handleOtpChange}
                        onPaste={handlePaste}
                        autoComplete="one-time-code"
                        style={{ fontSize: "17px" }}
                      />

                      <button
                        disabled={isVerified}
                        onClick={() => {
                          // handleSubmit();
                          verifyMobileOTP();
                          indicateAlert();
                        }}
                        id="verifyButton"
                        style={{ border: "2px solid #DEC269",backgroundColor:phoneFill?.length>=10?"rgb(69, 163, 165)":"",color:phoneFill?.length>=10?"white":"" }}
                      >
                        {isVerified ? (
                          <>
                            <img src={greenTick} width={15} />{" "}
                            <span style={{ marginLeft: "5px",color:"white" }}>Verified</span>
                          </>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                  )}
              </div> */}
              <div className="address">
                <div className="label" style={{display:"flex",justifyContent:"space-between"}}>
                  <span>Address</span>
                  {!groomingAddressValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingAddressValidation(true);
                      // setAdressFill(true);
                      setAddress(e.target.value);
                      setUserData({
                        ...userData,
                        Address: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{ fontSize: "17px" }}
                    // onFocus={handleLocationAccess}
                    value={address}
                    onClick={useLocation123}
                  />
                </div>
              </div>
              <div className="address">
                <div className="label" style={{display:"inline-block",justifyContent:"space-between"}}>
                <span>Coupon Code<span style={{fontStyle : "italic"}}>(If Available)</span></span>
                </div>
                {couponValid && (
                    <div style={{color:"#618E41",fontSize:"10px",display:"inline-block"}}>
                      &nbsp;Code applied successfully
                    </div>
                  )}
                  {
                    couponNotValid && (
                      <div style={{color:"red",fontSize:"10px",display:"inline-block"}}>
                        &nbsp;Invalid Code
                      </div>
                    )
                  }
                <div className="grooming-new-form-section3-right-ph-input-contact"> 
                  <input
                  id="coupon"
                  type="text"
                  className="grooming-new-form-desktop-input-container-type1"
                  onChange={(e)=>{setCouponCode(e.target.value)}}
                  />
                  {!couponValid && !couponNotValid && (
                    <div className="coupon-apply-button" onClick={applyCoupon}>
                    <span style={{paddingRight:"0.5rem"}}>Apply</span> <FaArrowAltCircleRight className="coupon-apply-button-icon"/>
                    </div>
                  )}
                  
                  {couponValid && (
                    <div className="coupon-applied-text" style={{color:"#618E41;"}}>
                      <span style={{marginRight:"8px"}}>₹ {couponValue} /- OFF</span> <FaCheck className="check-mark"/>
                    </div>
                  )}
                  {
                    couponNotValid && (
                      <div className="coupon-not-valid">
                        <FaXmark className="x-mark"/>
                      </div>
                    )
                  } 
                </div>
              </div>
              <div style={{width:"100%",marginTop:"10px",marginBottom:"10px"}}>
                <ImageSliderWalking images={images1}/>
              </div>
              {/* <div className="address">
                <div className="label">
                  <span>Complete Address</span>
                  {!groomingAddressValidation1 ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef1}
                    onChange={() => {
                      secondFormDataHandler();
                      setGroomingAddressValidation1(true);
                      setAddressValue(true);
                      setAdressFill(groomingAddressRef1.current.value)
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{fontSize:"17px"}}
                    value={addressValue?adressFill:""}
                  />
                </div>
              </div> */}
              <div style={{fontSize:"10px",fontWeight:"500",color:"#8391A1",textAlign:"center"}}>Login into our Petmojo application using same number</div>
              <center>
                <div
                  className="grooming-new-form-section1-carousel-container"
                  style={{ paddingRight: "20px",marginTop:"0px" }}
                >
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                </div>
              </center>

              {isLoading ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <div className="button-group">
                  {selectedPetChoice === 1 ? (
                    <button
                      // disabled={isVerified === false || isVerified === null}
                      className="pay-now"
                      onClick={() => {
                        finalFormSubmitHandler(false);
                        // trainingSubmissionHandler();
                        // bookFreeSession();

                      }}
                      // style={{
                      //   backgroundColor: !isVerified
                      //     ? "rgba(131, 145, 161, 1)"
                      //     : "",
                      //   border: !isVerified
                      //     ? "2px solid rgba(131, 145, 161, 1)"
                      //     : "",
                      // }}
                    >
                      Avail your FREE Session
                    </button>
                  ) : (
                    <>
                      <button
                        // disabled={isVerified === false || isVerified === null}
                        className="pay-now"
                        onClick={() => {
                          // getSubscription();
                          finalFormSubmitHandler(true)
                        }}
                        // style={{
                        //   backgroundColor: !isVerified
                        //     ? "rgba(131, 145, 161, 1)"
                        //     : "",
                        //   border: !isVerified
                        //     ? "2px solid rgba(131, 145, 161, 1)"
                        //     : "",
                        // }}
                      >
                        Proceed to Pay
                      </button>
                    </>
                  )}
                  {/* <button
                    disabled={isVerified === false || isVerified === null}
                    className="pay-now"
                    onClick={() => {
                      finalFormSubmitHandler(true);
                    }}
                    style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                    
                  >
                    {selectedPetChoice===1?"Book Now":"Pay Now"}
                  </button>
                  {selectedTrial==="Get Subscription"?<button onClick={() => { finalFormSubmitHandler(false);setBookNow(true) }} className='pay-later'
                  style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >Book Now & Pay Later</button>:null} */}
                </div>
              )}
            </div>
          ) : null}
          <center>
            {selectedCarousel === 3 ? (
              <div
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  lineHeight: "18.75px",
                }}
              >
                <p
                  style={{ color: "rgba(131, 145, 161, 1)", fontSize: "15px" }}
                >
                  Paid sessions are given time & elite trainer preference!
                </p>
              </div>
            ) : null}
          </center>
        </div>
      )}
      {navTab === 2 && (
        <div className="order-placed-container">
          <div className="container">
            <div className="first-box">
              <span>
                {" "}
                <strong> Booking Confirmed:</strong>
              </span>
              <div className="middle">
                Monthly Walking <br/>
                {userData.Package}({numberPets} Pet)
              </div>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
                <span>Number of pets: {petsnumber}</span>
                <br />
                <span>
                  Date: {userData.Date.split("-").reverse().join("-")}
                </span>
                
                <br />
                <span>
                  Your Registered Number: {userData.Phone}
                </span>
                </div>
                {!bookNow?<div>
                <img
                  src={downloadInvoice}
                  width={50}
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    top: "-40px",
                  
                  }}
                  onClick={()=>setNavTab(3)}
                />
                </div>:null}
              </div>
              <div style={{width:"100%"}}>
                <div style={{fontWeight:"700"}}>
                  Billing Address:
                </div>
                <div style={{fontWeight:"400",marginTop:"10px"}}>
                  {address}
                </div>
              </div>
            </div>
            <div className="second-box" style={{padding:"10px 20px"}}>
            <div className="right" style={{backgroundColor:"white",width:"50%"}}>
                                <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/invoice-vector-walking.png" alt="" 
                               
                               style={{objectFit:"contain",width:"100%",height:"100%"}}
                                />
                            </div>
              <div className="left" style={{display:"flex",flexDirection:"column",fontSize:"12px",width:"50%"}}>
                <div style={{display:"flex",width:"100%",justifyContent:"space-between",gap:"2px"}}>
                  <div style={{fontWeight:"700"}}>Package Amount :</div>
                  <div style={{fontWeight:"400"}}>₹{price}</div>
                </div>
                <div style={{display:"flex",width:"100%",justifyContent:"space-between",gap:"2px"}}>
                  <div style={{fontWeight
                  :"700"}}>18% GST :</div>
                  <div style={{fontWeight:"400"}}>₹{(18*price)/100}</div>
                </div>
                <div style={{display:"flex",width:"100%",justifyContent:"space-between",gap:"2px",borderTop:"2px solid grey"}}>
                  <div style={{fontWeight
                  :"700"}}>Total :</div>
                  <div style={{fontWeight:"400"}}>₹{(price*118)/100}</div>
                </div>
              </div>
               
            </div>
            <div className="third-box" >
              <div
                onClick={() => {
                  console.log(userData);
                }}
                className="tick"
              >
                <img src={greenTick} alt="" style={{width:"40px",height:"40px"}}/>
              </div>
              <p style={{fontSize:"15px"}}>
              Your information has been sent to our pet counselor,
Download our Mobile app and start tracking immediately and connect with the walker.
              </p>

              <a href="https://play.google.com/store/apps/details?id=in.tamely.user"  style={{textDecoration:"none"}}>
              <div className="download" style={{marginBottom:"15px"}}>Download App to Contact Walker</div>
              </a>
              <div className="button-group" style={{marginBottom:"20px"}}>
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                  <img src={googlePlay} alt="" className="playstore"/>
                </a>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328">
                  <img src={appleStore} alt="" className="appstore"/>
                </a>
              </div>
              <center>
            <LazyLoad >
              <img src={petmojologo} width={170} height={50} />
            </LazyLoad>
            </center>
            <div style={{fontWeight:"500",fontSize:"10px",lineHeight:"20px"}}>
            <p style={{ color: "rgba(93, 93, 93, 1)" }}>
              Copyright 2022 Petmojo <br />
              All copyrights are reserved - any infringement will be legally
              pursued
            </p>
            </div>
            </div>
          </div>
        </div>
      )}
      {
        navTab===3 && (
          <div
          className="order-placed-container"
          style={{ borderRadius: "0px", border: "0px" }}
        >
          <div
            style={{
              width: "100%",
              height: "120px",
              backgroundColor: "rgba(41, 49, 60, 1)",
              borderRadius: "0px",
              padding: "10px",
            }}
          >
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/invoice-logo.png"
              alt=""
              style={{ height: "90px", width: "240px", objectFit: "contain" }}
            />
          </div>
          <div style={{ padding: "10px 20px",marginTop:"20px" }}>
            <div style={{fontSize:"15px",fontWeight:"800"}}>
            <div>Trade name: Petmojo</div>
            <div>Mangopaw technologies and solutions pvt. Ltd.</div>
            <div>Booking reference number: 0001003A</div>
            </div>
            <h3 style={{ textAlign: "center" }}>Invoice</h3>
            <div style={{fontSize:"13px",fontWeight:"400"}}>
              <div>Invoice to: {userData.Name}</div>
              <div>Phone Number: {userData.Phone}</div>
              <div>Booking reference number: 0001003A</div>
            </div>
            <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
              <div
                style={{
                  width: "70%",
                  backgroundColor: "#DCDCDC",
                  padding: "2px",
                  fontSize:"13px",
                  fontWeight:"800"
                }}
              >
                Package Details
              </div>
              <div
                style={{
                  width: "30%",
                  backgroundColor: "#FF5E95",
                  textAlign: "center",
                  color:"white",
                  fontSize:"13px",
                  fontWeight:"800",
                  alignContent:"center"
                }}
              >
                Amount
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", marginTop: "15px" }}>
              <div
                style={{
                  width: "70%",
                  padding: "2px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{fontSize:"15px",fontWeight:"700"}}>Walking {monthlyPackage}({numberPets} Pet)</span> <br />
                <span style={{fontSize:"15px",fontWeight:"400"}}>Address:{address}</span>
              </div>
              <div style={{ width: "30%", textAlign: "center" }}>₹{price}/-</div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "10px",
                backgroundColor: "rgba(164, 226, 193, 0.25)",
              }}
            >
              <div style={{ width: "70%", padding: "2px",fontSize:"13px" }}>+ 18% GST</div>
              <div style={{ width: "30%", textAlign: "center",fontSize:"15px",fontWeight:"700",color:"rgba(66, 167, 82, 1)" }}>+₹{(18*price)/100}/-</div>
            </div>
            <hr
              style={{
                marginTop: "10px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
              }}
            />
            <span style={{fontSize:"12px",fontWeight:"400"}}>Billing type: Full Amount</span>
            <div
              style={{
                marginTop: "100px",
                display: "flex",
                width: "100%",
                justifyContent: "end",
                padding: "20px",
              }}
            >
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "13px",fontWeight:"500" }}>Subtotal:</div>
                  <div style={{ fontSize: "15px",fontWeight:"600" }}>₹{price}/-</div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:"5px" }}
                >
                  <div style={{ fontSize: "13px",fontWeight:"500" }}>+18% GST</div>
                  <div style={{ fontSize: "15px",fontWeight:"600" }}>+₹{(18*price)/100}/-</div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:"5px" }}
                >
                  <div style={{ fontSize: "13px",fontWeight:"500" }}>TOTAL :</div>
                  <div
                    style={{
                      backgroundColor: "#FF5E95",
                      color: "white",
                      padding: "2px",
                      fontSize: "15px",fontWeight:"600"
                    }}
                  >
                    Rs.{(118*price)/100}/-
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              backgroundColor: "#29313C",
              display: "flex",
              padding: "10px",
              color: "white",
              fontFamily: "DM Sans",
              fontSize: "10px",
            }}
          >
            <div style={{ width: "50%" }}>Phone number: 9009004473</div>
            <div style={{ width: "50%" }}>
              E-mail Address: support@tamely.in
            </div>
          </div>
        </div>
        )
      }
    </>
  );
};

export default WalkingPageForm;
