import React, { useState, SyntheticEvent, useRef, useEffect } from "react";
import "./Blog.css";
import {Helmet} from 'react-helmet-async';
import NavigationBarBlogs from "./Navigation/navigationBar/NavigationBarBlogs";
import NavigationDesktopBlogs from "../components-desktop/Navigation/NavigationDesktopBlogs";
import {AllBlogs, SuggestionBlogs} from '../blogs/BlogsData';
import BlogListGenerator from "./BlogListGenerator";
import {useParams} from "react-router-dom";
import BlogListGeneratorMobile from "./BlogListGeneratorMobile";
import {RWebShare} from 'react-web-share';
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FacebookShareCount} from "react-share";


function Blog(props) {

  const {blogId} = useParams();


  const [value, setValue] = useState("1");
  const [navDropdown, setNavDropdown] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const [currentBlog, setCurrentBlog] = useState({});



  const [blogVisible, setBlogVisible] = useState(false);
  const [blogCollapsed, setBlogCollapsed] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if(window.innerWidth < 1000){
      setChangeView(false);
    }else{
      setChangeView(true);
    }
  }, [])


  //---------------------------handler functions-----------------------------


  return (
    <div className="blog">

      {
        AllBlogs.map((blog) => {
          if(blog.id === blogId){
            return(
              <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content={blog.description} />
                <link rel="canonical" href={`/blog/${blogId}`} />
              </Helmet>
            );

          }
        })
      }

      <Helmet>
        <title>Blogs by Petmojo - Pet Care, Training, Food, Well Being & More</title>
        <meta name="description" content="Read tips and tricks to deal with your pets and their moods." />
        <link rel="canonical" href="/blog" />
      </Helmet>

      {
        !changeView ? (
          <div className="Blogs-Mobile-Master">
            <NavigationBarBlogs />

            {
              AllBlogs.map((blog) => {
                if(blog.id === blogId){
                  return (
                    <div className="mobile-view-detail-blog-page-master-container">
                      <div className="mobile-view-blog-detail-master-container">
                        <div className="mobile-view-blog-detail-main-heading">{blog.heading}</div>
                        <div className="mobile-view-blog-detail-image-date-container">
                          <div className="mobile-view-blog-detail-image-container">
                            <img className="mobile-view-blog-detail-main-image" alt="blogImage" src={blog.src} />
                          </div>
                          <div className="mobile-view-blog-detail-date-container">
                            <div className="mobile-view-blog-detail-date-container-date"><div><img alt="dateImage" className="mobile-view-blog-detail-date-container-cal-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/calander-1.png" /></div><div>{blog.date}</div></div>
                            <div className="mobile-view-blog-detail-date-container-date"><div><img alt="dateImage" className="mobile-view-blog-detail-date-container-cal-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_zgwrql.png" /></div><div>{blog.views} Views</div></div>
                          </div>
                        </div>
                        <div className="mobile-view-blog-detail-main-content-container">
                          <div className="mobile-view-blog-detail-main-content-section">
                            {blog.full_content.map((section) => {
                              return <p className="section-gap-div-mobile">{section}</p>;
                            })}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })
            }

            {
              AllBlogs.map((blog) => {
                if(blog.id === blogId){
                  return (
                    <div className="blog-page-mobile-view-dont-forget-container">
                      <div className="blog-page-mobile-view-dont-forget-text">Don't forget to share this post!</div>
                      <div className="blog-page-mobile-view-dont-forget-image-container">
                        <FacebookShareButton
                          title = {blog.heading}
                          summary = "A post on pets by Petmojo"
                          source = {blogId}
                          url = {`https://petmojo.care/blog/${blogId}`}
                        >
                          <div><a href=""><img alt="socialIcon" className="blog-page-mobile-view-social-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8102_lenwel.png" /></a></div>
                        </FacebookShareButton>
                        <TwitterShareButton
                          title = {blog.heading}
                          summary = "A post on pets by Petmojo"
                          source = {blogId}
                          url = {`https://petmojo.care/blog/${blogId}`}
                        >
                          <div><a href=""><img alt="socialIcon" className="blog-page-mobile-view-social-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8103_rkphk7.png" /></a></div>
                        </TwitterShareButton>
                        <LinkedinShareButton
                          title = {blog.heading}
                          summary = "A post on pets by Petmojo"
                          source = {blogId}
                          url = {`https://petmojo.care/blog/${blogId}`}
                        >
                          <div><a href=""><img alt="socialIcon" className="blog-page-mobile-view-social-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_9010_tgifbf.png" /></a></div>
                        </LinkedinShareButton>
                        <InstapaperShareButton
                          title = {blog.heading}
                          summary = "A post on pets by Petmojo"
                          source = {blogId}
                          url = {`https://petmojo.care/blog/${blogId}`}
                        >
                          <div><a href=""><img alt="socialIcon" className="blog-page-mobile-view-social-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8105_wspfdy.png" /></a></div>
                        </InstapaperShareButton>
                        <WhatsappShareButton
                          title = {blog.heading}
                          summary = "A post on pets by Petmojo"
                          source = {blogId}
                          url = {`https://petmojo.care/blog/${blogId}`}
                        >
                          <div><a href=""><img alt="socialIcon" className="blog-page-mobile-view-social-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8106_vgx2w6.png" /></a></div>
                        </WhatsappShareButton>
                      </div>
                    </div>
                  )
                }
              })
            }



            <hr className="blog-page-mobile-view-related-articles-line"></hr>

            <div className="blogs-page-mobile-view-related-articles-text">Related Articles</div>
            <div className="blogs-page-mobile-view-related-articles-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="" height="20" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>
            </div>

            <div className="blogs-page-mobile-view-related-blogs-container">
              {
                AllBlogs.map((blog) => {
                  if(blog.id !== blogId){
                    return <BlogListGeneratorMobile dataObj={blog} />
                  }
                })
              }
            </div>
          </div>
        ) : (
        <div className="Blogs-Desktop-Master">
          <NavigationDesktopBlogs submissionType={"none"} submissionText={"Download Our App"} />

          {
            AllBlogs.map((blog) => {
              if(blog.id === blogId){
                return (
                  <div className="blogs-desktop-view-main-container">
                  <div className="blogs-desktop-view-main-container-left">
                    <div className="blogs-desktop-view-main-container-left-left">
                      <div className="blogs-desktop-view-main-container-main-image-container">
                        <img alt="blogImage" className="blogs-desktop-view-main-container-main-image" src={blog.src}  />
                      </div>
                      <div className="blogs-desktop-view-main-container-main-time-container">
                        <div className="blogs-desktop-view-main-container-main-views">
                          <div><img alt="eyeImage" className="desktop-eye-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_grm73x.png" /></div>
                          <div className="blogs-desktop-view-main-container-main-views-text">{blog.views} Views</div>
                        </div>
                        <div className="blogs-desktop-view-main-container-main-date">
                          <div><img alt="dataImage" className="desktop-calander-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/calander-1.png" /></div>
                          <div className="blogs-desktop-view-main-container-main-views-text">{blog.date}</div>
                        </div>
                      </div>
                    </div>
      
                    <div className="blogs-page-desktop-view-blog-content-container">
                      <div className="blogs-page-desktop-view-blog-content-heading">
                        <div><h2>{blog.heading}</h2></div>
                        
                        <div className="line-div">
                          <hr className="live-training-pink-line"></hr>
                        </div>
                      </div>
                      <div className="blogs-page-desktop-view-blog-content-text">
                        {blog.full_content.map((section) => {
                              return <div className="section-gap-div-mobile"><p>{section}</p></div>;
                            })}
                      </div>
                    </div>
                  </div>
      
                  <div className="blogs-desktop-view-main-container-right">
                    <div className="blogs-desktop-view-main-container-right-heading">
                      Read Our Other Blogs
                    </div>
                    <div className="blogs-desktop-view-main-container-right-blogs">
                      {
                        AllBlogs.map((blog) => {
                          return <BlogListGenerator dataObj = {blog} />
                        })
                      }
                    </div>
                  </div>
                </div>
                )
              }
            })
          }

        </div>
      )
      }




    </div>
  );
}

export default Blog;
