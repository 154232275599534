import {Link} from 'react-router-dom';
import './Blog.css';

const BlogListGeneratorMobile  = (props) => {
    return (
        <div onClick={()=>
            props.setBlogSchema(!props.blogSchema)
          }>
        <Link to={{pathname: `/blog/${props.dataObj.id}`, state: props.dataObj}} style={{textDecoration: "none"}}>
            <div className="blog-item-mobile-view-master-container">
                <div className="blog-item-mobile-view-image-container">
                    <img className="blog-item-mobile-view-image" alt="blogImage" src={props.dataObj.src} />
                </div>
                <div className="blog-item-mobile-view-text-container">
                    <div className="blog-item-mobile-view-text-heading">{props.dataObj.heading}</div>
                    <div className="blog-item-mobile-view-text-data-views">{props.dataObj.date} | {props.dataObj.views} Views   </div>
                </div>
            </div>
        </Link>
        </div>
    );
};

export default BlogListGeneratorMobile;