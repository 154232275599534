import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from '../../../images/logo.png'

import styles from "./NavigationBar.module.css";

function NavigationBar(props) {
  const [navDropdown, setNavDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timerId;

    function handleScroll() {
      setIsScrolling(true);
      clearTimeout(timerId);
      timerId = setTimeout(() => setIsScrolling(false), 200);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timerId);
    };
  }, []);

  const [changeView, setChangeView] = useState(false);
  const [hasUserScrolled,setHasUserScrolled]=useState(false);
  // const [changeView, setChangeView] = useState(false);
  window.onscroll=function(e){
    setHasUserScrolled(true);
  }
  
  function dropDownHandlerFunction(){
    console.log(document.querySelector('#navbar-id'));
    document.querySelector('#navbar-id').classList.remove('nav-bar-remove');
    document.querySelector('#navbar-id').classList.add('nav-dropdown');
  }

  function dropdownCloseHandlerFunction(){
    console.log(document.querySelector('#navbar-id'));
    document.querySelector('#navbar-id').classList.remove('nav-dropdown');
    document.querySelector('#navbar-id').classList.add('nav-bar-remove');
  }
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
        console.log(event.target);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);



  return (
     <div className="navbar-bottomSpace" style={{marginBottom:"0px",marginTop:"10px"}}>

    <div className={styles.item1}>
      <Link to="/">
        {/* <LazyLoad> */}
        <img src=
        // {(props.page === "white")
        //  ? 
         "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo-green-logo.png"
        //  : `${logo}`} 
         className="petmojo-logo" alt="" style={{objectFit:"contain"}}></img>
        {/* </LazyLoad> */}
      </Link>
    </div>


    <div className={styles.item3}>
      {props.homePage && <button onClick={() => dropDownHandlerFunction()} className="nav-menu">
        {/* <LazyLoad > */}
        <img
          src={(props.page !== "white") ? "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/nav-menu-icon_m4iurc.png" : "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8326_wgmnxf.png"}
          className="mobile-view-nav-dropdown"
          alt="nav-menu"
          // onClick={() => setNavDropdown(!navDropdown)}
        ></img>
        {/* </LazyLoad> */}
      </button>}
        {!isScrolling && <div className="nav-bar-remove" id="navbar-id" ref={dropdownRef} style={{padding:"0px",position:"fixed",top:"0px",right:"0px"}}>
          <div className="navbar-notch" style={{backgroundColor:"rgb(41,49,60)",marginTop:"0px",padding:"5px",alignItems:"center"}} >
            <div className="navbar-notch-icon">
              <img alt="petmojo" className="petmojo-mobile-nav-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/petmojo-green-logo.png" />
            </div>
            <div>
              <svg className="navigation-mobile-cross" onClick={() => dropdownCloseHandlerFunction()} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" color="white">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
              </svg>
            </div>
          </div>

          <Link to="/" style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Home</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>

          <Link to="/dog-running" state={{from: "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Dog Walking/Exercise</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/cat-training" state={{from: "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Cat Training</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/dog-training" state={{from : "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Dog Training</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/grooming" state={{from : "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Pet Grooming</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/grooming-subscription" state={{from : "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Grooming Subscription</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/pet-boarding" state={{from : "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Pet Hotel</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          {/* <Link to="/styling" state={{from : "navigation"}} style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link">
              <div className="nav-dropdown-main-link-text">Pet Styling</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link> */}
          <Link to="/socials" style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Petmojo Social</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/blog" style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Blogs</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/help" style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">Help Center</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <Link to="/about" style={{textDecoration: "none"}}>
            <div className="nav-dropdown-main-link" style={{padding:"10px"}}>
              <div className="nav-dropdown-main-link-text">About Us</div>
              <div className="nav-dropdown-main-link-icon"><img alt="goArrow" className="goArrow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8708_haf7as.png" /></div>
            </div>
          </Link>
          <div className="nav-mobile-view-downloads-container" style={{padding:"10px"}}>
            <div className="nav-mobile-view-google-container">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=in.tamely.user" rel="nofollow"><img alt="playStore" className="nav-mobile-view-google-icon playstore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png" /></a>
            </div>
            <div className="nav-mobile-view-apple-container">
              <a target="_blank" href="https://apps.apple.com/in/app/petmojo/id1609861328" rel="nofollow"><img alt="appStore" className="nav-mobile-view-google-icon appstore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"  /></a>
            </div>
          </div>
        </div>}

    </div>
  </div>
  );
}

export default NavigationBar;
