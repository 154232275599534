import Accordion from "../../../components/Accordion";
import AccordionDesktop from "../../../components/AccordionDesktop";

import { data } from "../../../mockData/mockDataBoarding";

import {Link} from 'react-router-dom';

function FAQContainerGrooming(props) {
  return (

    
    <>
    {
      (props.view === "mobile") ? (
        <div className="centerAlign grey-background">
          <div>
            <div className="textbox2 faq-title">FAQ'S</div>
            <hr className="vector2"></hr>

              <div className="grey-text">Most frequent Questions and Answers</div>
            

            <div className="accordion">
              {data.map(({ title, content }) => (
                <Accordion view={"mobile"} title={title} content={content} />
              ))}
            </div>
          </div>

          <div className="bookingbutton margin-top-boarding-faq-btn" style={{paddingLeft:"30px",paddingRight:"30px"}}>
                            <button className="booksession-mobile-form" style={{width:"100%"}}>Book Now!</button>
                        </div>
          
        </div>
      ) : (
        <div className="faq-master-container-desktop">
          <div className="faqContainerDesktop">
            <div className="faqContainerDesktop-left">
              <div className="textbox2-desktop faq-title-desktop">FAQ'S</div>
              <div className="line-div">
                      <hr className="live-training-pink-line"></hr>
                    </div>
              <div className="grey-text-desktop">Most frequent Questions and Answers</div>
            </div>

            <div className="accordion-desktop">
              {data.map(({ title, content }) => (
                <AccordionDesktop title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      )
    }
      
    </>
  );
}

export default FAQContainerGrooming;
