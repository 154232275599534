import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import GroomingPageFormNew from './GroomingPageFormNew';
import GroomingPageForm from './GroomingPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container">
            <div className='wrapper-class-intro-desktop-video' style={{width:"100%",position:"absolute"}}>
              {/* <video  role="presentation" autoPlay loop muted style={{width:"100vw",objectFit:"contain"}}>
                <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-vid-compress.mp4" type="video/mp4" style={{width:"100%"}}>
                </source>
              </video> */}
              <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/groomingwebsite.webp" alt="" style={{width:"100vw",height:"160vh",objectFit:"cover"}}/>
            </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"/grooming"} submissionText={"Book Now"} />

              <div className='intro-desktop-head-combiner-grooming'>
                <div className='intro-desktop-home-page-header'>
                  <h1 className='home-page-desktop-heading'>
                    <div className='intro-desktop-home-page-header1'><span className='pink-bold'>Professional </span>Pet <span className='pink-bold'>Grooming </span>Services at home </div>
                    {/* <div className='intro-desktop-home-page-header1'>Our <span className='pink-bold'> Expert </span> Groomers</div> */}
                  </h1>
                </div>
                <div className='intro-desktop-walking-page-points-container'>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Highly Trained & Expert Pet Groomers</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Breed Specific Haircuts</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Carefully Selected, non Allergic products for your Pet’s skin coat</div>
                  </div>
                </div>
                  <GroomingPageFormNew />
              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
