import { Link } from "react-router-dom";
//
import "./TimelineComponent.css";

const linkArrow ="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/link-arrow.svg";

function TimelineComponent({ timelineData, version }) {

  return (
    <div>
      {
        (version === "mobile") ? (
          <div className="timeline-container-boarding">
            {timelineData.map((data, idx) => (
              <div className="timeline-item">
                <div className="timeline-item-content">
                  {data.route ? (
                    <Link to={data.route} state={{type: "dog-training"}}>
                      <div>
                        {data.title} 
                      </div>
                      <p className="timeline-text">
                        {data.text}
                        <span>{data.routeText}</span>
                      </p>
                    </Link>
                  ) : (
                    <a href={data.link}>
                      <div>
                        {data.title} 
                      </div>
                      <p className="timeline-text">
                        {data.text}
                      </p>
                    </a>

                  )}
                  <span className="circle-desktop">
                    <span className="timeline-point">{idx + 1}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="timeline-container-boarding">
            {timelineData.map((data, idx) => (
              <div className="timeline-item">
                <div className="timeline-item-content">
                  {data.route ? (
                    <Link to={data.route} state={{type: "dog-training"}}>
                      <div>
                        {data.title} 
                      </div>
                      <p className="timeline-text">
                        {data.text}
                        <span>{data.routeText}</span>
                      </p>
                    </Link>
                  ) : (
                    <a href={data.link}>
                      <div>
                        {data.title} 
                      </div>
                      <p className="timeline-text">
                        {data.text}
                      </p>
                    </a>
                  )}
                  <span className="circle-desktop-boarding">
                    <span className="timeline-point">{idx + 1}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )
      }
      
    </div>
    
  );
}

export default TimelineComponent;
