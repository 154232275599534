import "./MobileFormsCSS.css";
import { useState, useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import petmojologo from "../../../images/BlackLogo.png";
import DatePicker from "react-datepicker";
import downloadInvoice from "../../../images/download-invoice.png";
import groomingvector from "../../../images/grooming-vector.jpg";
import greenTick from "../../../images/green-tick.png";
import googlePlay from "../../../images/play-store.png";
import appleStore from "../../../images/app-store.png";
import { BiArrowBack } from "react-icons/bi";
import { useCallback } from "react";
import { useGeolocated } from "react-geolocated";
import { FaArrowAltCircleRight, FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import DogImage from "../../../images/dog.png";
import dogBath from "../../../images/dogBath.png";
import dog3 from "../../../images/dog3.png";
import dog4 from "../../../images/dog4.png";
import dog5 from "../../../images/dog5.png";
import dog6 from "../../../images/dog6.png";
import dog7 from "../../../images/dog7.png";
import dog8 from "../../../images/dog8.png";
import dog9 from "../../../images/dog9.png";
import dog10 from "../../../images/dog10.png";

import ImageSliderWalking from "../../ImageSliderWalking";

const images = [
  {
    id: 1,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-1.webp",
    alt: "Image 1",
  },
  {
    id: 2,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-2.webp",
    alt: "Image 2",
  },
  {
    id: 3,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-3.webp",
    alt: "Image 3",
  },
  {
    id: 4,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-4.webp",
    alt: "Image 4",
  },
  {
    id: 5,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-5.webp",
    alt: "Image 5",
  },
  {
    id: 6,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-6.webp",
    alt: "Image 6",
  },
  {
    id: 7,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-7.webp",
    alt: "Image 7",
  },
  {
    id: 8,
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-slider-mobile-8.webp",
    alt: "Image 8",
  },
];

function useGetLocation() {
  const [location, setLocation] = useState(null);

  const { latitude, longitude } = useGeolocated();
  setLocation({ latitude, longitude });
  // useEffect(() => {
  // }, []);

  return location;
}

const GroomingPageForm = (props) => {
  const [otp, setOtp] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [bookNow, setBookNow] = useState(false);
  const otpRef = useRef(null);
  const navigate = useNavigate();
  const [sessionDate, setSessionDate] = useState();
  const location = useLocation().pathname;
  const [seconds, setSeconds] = useState(0);
  // console.log("location: ",location.pathname)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [correctOtp, setCorrectOtp] = useState(true);
  const groomingPhoneRef = useRef(0);
  const groomingAddressRef = useRef("");
  const groomingAddressRef1 = useRef("");
  const groomingDateRef = useRef("");
  const groomingSlotRef = useRef("");
  const groomingOtpRef = useRef("");
  const [countdown, setCountdown] = useState(true);
  const [startValue, setStartValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [nameValue, setnameValue] = useState(false);
  const [nameFill, setNameFill] = useState("");
  const [phoneValue, setPhoneValue] = useState(false);
  const [phoneFill, setPhoneFill] = useState(null);
  const [addressValue, setAddressValue] = useState(false);
  const [adressFill, setAdressFill] = useState("");
  const [slotValue, setSlotValue] = useState(false);
  const [slotFill, setSlotFill] = useState("");
  const [price1, setPrice] = useState(799);
  // const groomingEmailRef = useRef("");
  const groomingNameRef = useRef("");
  const [serviceChoice, setServiceChoice] = useState("Bath & Brush Subscription(3 sessions)");
  const [inputDate, setInputDate] = useState("");
  const [inputSlot, setInputSlot] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [copyMessage, setCopyMessage] = useState("");
  const [userData, setUserData] = useState({
    Pet: "",
    Service: "",
    amount: 0,
    Date: "",
    Slot: "",
    Name: "",
    Phone: "",
    Address: "",
    Latitude: 0,
    Longitude: 0,
  });

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedService, setSelectedService] = useState("BATH & BRUSH");
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [groomingPhoneValidation, setGroomingPhoneValidation] = useState(true);
  const [groomingAddressValidation, setGroomingAddressValidation] =
    useState(true);
  const [groomingAddressValidation1, setGroomingAddressValidation1] =
    useState(true);

  const [groomingDateValidation, setGroomingDateValidation] = useState(true);
  const [groomingSlotValidation, setGroomingSlotValidation] = useState(true);
  const [groomingNameValidation, setGroomingNameValidation] = useState(true);
  const [groomingLoading, setGroomingLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(1);
  const [selectedPetChoice, setSelectedPetChoice] = useState(1);
  const [navTab, setNavTab] = useState(1);
  const [formSection, setFormSection] = useState(1);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [showDownloadInvoice, setShowDownloadInvoice] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedOption, setSelectedOption] = useState("option1");
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(true);
  const [isClicked3, setIsClicked3] = useState(false);
  const [isClicked4, setIsClicked4] = useState(false);
  const [isClicked5, setIsClicked5] = useState(false);
  const [isClicked6, setIsClicked6] = useState(false);
  const [isClicked7, setIsClicked7] = useState(true);
  const [isClicked8, setIsClicked8] = useState(false);
  const [isClicked9, setIsClicked9] = useState(false);
  const [isClicked10, setIsClicked10] = useState(false);
  const [isClicked11, setIsClicked11] = useState(true);
  const [isClicked12, setIsClicked12] = useState(false);
  const [isClicked13, setIsClicked13] = useState(false);
  const [sessionNumber,setSessionNumber] = useState(3);
  const [packageAmount,setPackageAmount] = useState(699);
  const [saveAmount, setSaveAmount] = useState(300);
  const [amount, setAmount] = useState(1);

  const sectionRef = useRef(null);
  function scrollToSection() {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  var day = ("0" + currentDate.getDate()).slice(-2);
  var formattedDate = year + "-" + month + "-" + day;
  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }
  const groomingFirstFormHandler = () => {
    

    if (selectedChoice !== 0) setSelectedCarousel(2);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case 'option1':
        {
          handleDivClick2();
          setIsClicked2(true);
          setPackageAmount(699);
        }
        
        break;
      case 'option2':
        {
          handleDivClick7();
          setIsClicked7(true);
          setPackageAmount(1149);
        }
        
        break;
      case 'option3':
        {
          handleDivClick11();
          setIsClicked11(true);
        setPackageAmount(1549);
        }
        break;
      default:
    }
  };
  const firstFormDataHandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== ""
    ) {
      setInputDate(groomingDateRefCurrValue);
      setInputSlot(groomingSlotRef.current.value);

      setUserData({
        ...userData,
        Pet: selectedPetChoice === 1 ? "Dog" : "Cat",
        Service:
          selectedChoice === 1
            ? "BATH & BRUSH"
            : selectedChoice === 2
            ? "BATH & BRUSH (With Tick Treatment)"
            : selectedChoice === 3
            ? "HAIRCUT & STYLING"
            : selectedChoice === 4
            ? "BATH & FULL HAIRCUT"
            : "MASSAGE & BATH & FULL HAIRCUT & STYLING",
        amount:
          selectedChoice === 1
            ? location === "/grooming"
              ? 799
              : 899
            : selectedChoice === 2
            ? 1199
            : selectedChoice === 3
            ? 1249
            : selectedChoice === 4
            ? 1649
            : 1949,
        Date: groomingDateRefCurrValue,
        Slot: groomingSlotRef.current.value,
        Latitude: lat,
        Longitude: lon,
      });
      //   setSelectedCarousel(2);
    } else {
      console.log("data coll err");
    }
  };
  const secondFormDataHandler = () => {
    if (
      groomingNameRef.current.value !== "" &&
      checkPhone(groomingPhoneRef.current.value.toString()).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      setUserData({
        ...userData,
        Name: groomingNameRef.current.value,
        Phone: phone,
        Address: groomingAddressRef.current.value,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const [showDialog, setShowDialog] = useState(false);
  const checkPhone = (phoneString) => {
    if (
      phoneString.length > 10 &&
      (phoneString.startsWith("+91") || phoneString.startsWith("91"))
    ) {
      phoneString = phoneString.slice(-10);
      setPhone(phoneString);
      setShowDialog(true);
    }
    return phoneString;
  };
  const nextNavhandler = () => {
    let groomingDateRefCurrValue = groomingDateRef.current.value;
    if (
      groomingDateRefCurrValue !== "" &&
      groomingSlotRef.current.value !== "" &&
      nameFill != "" &&
      (phoneFill != null || phoneFill != "")
    ) {
      console.log(userData);
      setSelectedCarousel(3);
      // setFormSection(3);
    } else if (nameFill == "") {
      setGroomingNameValidation(false);
    } else if (phoneFill == null || phoneFill == "") {
      setGroomingPhoneValidation(false);
    } else if (groomingDateRefCurrValue === "") {
      console.log("error");
      setGroomingDateValidation(false);
    } else if (groomingSlotRef.current.value === "") {
      console.log("error");
      setGroomingSlotValidation(false);
    }

    if (
      groomingDateRefCurrValue === "" &&
      groomingSlotRef.current.value === ""
    ) {
      console.log("error");
      setGroomingSlotValidation(false);
      setGroomingDateValidation(false);
    }
  };

  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }
  const getmobileOtp = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: phoneString,
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const changeName = () => {
    document.getElementById("send-otp").innerHTML = "Resend OTP";
  };

  const verifyMobileOTP = useCallback(async () => {
    try {
      let phoneString = checkPhone(groomingPhoneRef.current.value.toString());
      const res = await axios.post(
        "https://6xdmb7fadb.execute-api.ap-south-1.amazonaws.com/production/api/auth/sendMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: phoneString,
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setCorrectOtp(true);
        setToken(res.data.token);
        setCountdown(false);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setCorrectOtp(false);
        setIsWrongOtp(true);
      }
    }
  }, []);
  const indicateAlert = () => {
    if (!correctOtp) {
      document.getElementById("send-otp").style.borderColor = "red";
    }
   
  };

  
  const decrementSeconds = () => {
    setTimeout(() => {
      if (seconds == 0) {
        document.getElementById("countdown-seconds").style.display = "none";
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  const handleOtpChange = (event) => {
    const { value } = event.target;
    setOtp(value);
  };

  // function to handle the form submission
  const handleSubmit = (event) => {
    // event.preventDefault();
    console.log(`Submitting OTP: ${otp}`);
  };

  // function to handle the "Paste" event and autofill OTP
  const handlePaste = (event) => {
    // event.preventDefault();
    const pastedText = event.clipboardData.getData("Text");
    setOtp(pastedText);
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    SelectedService,
    DateOfReg,
    inputPhone,
    InputAddress,
    bookingId
  ) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        console.log(result.data);
        //setRazorpayOrderId(result.data.orderId)

        const postGroomingPayment = async (bId, oId, amt) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY",
            },
          };
          try {
            console.log(bId, oId, amt);
            const res = await axios.patch(
              "https://6xdmb7fadb.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
              {
                bookingId: bId,
                transactionId: oId,
                amount: amt,
                type: "User",
              },
              config
            );
            console.log(res.data);
          } catch (error) {
            console.log(bId, oId, amt);
            console.log(error);
          }
        };

        if (result.status === 200) {
          postGroomingPayment(bookingId, result.data.orderId, serviceAmount);
          setNavTab(2);
        }
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "support@petmojo.care",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  let UTM_Source,
    UTM_Medium,
    UTM_Campaign = "";

  const urlParams = new URLSearchParams(window.location.search);
  UTM_Source = urlParams.get("utm_source");
  UTM_Medium = urlParams.get("utm_medium");
  UTM_Campaign = urlParams.get("utm_campaign");

  const finalFormSubmitHandler = async (flag) => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      nameFill !== "" &&
      checkPhone(phoneFill).length === 10 &&
      groomingAddressRef.current.value !== ""
    ) {
      // console.log(userData);
      // console.log(userData.Phone)
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      };
      setIsLoading(true);
      try {
        // console.log("hey1")
        const phone =
          userData.Phone.length > 10 &&
          (userData.Phone.startsWith("+91") || userData.Phone.startsWith("91"))
            ? userData.Phone.slice(-10)
            : userData.Phone;
        // console.log(phone)
        const res = await axios.post(
          `${baseUrl}/serviceBooking/websiteDogGroomingBookingService`,
          {
            fullName: userData.Name,
            address: address,
            phone: phone,
            startDate: userData.Date,
            sessionTime: userData.Slot,
            pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            package: {
              description: serviceChoice,
              amount: packageAmount*sessionNumber,
              frequency: 3,
            },
            isCouponCodeUsed: couponValue === 0 ? false : true,
            couponCode: couponValue !== 0 ? couponCode : "",
            type: "User",
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          },
          config
        );
        // console.log("hey2")
        console.log(res.data);
        console.log(res.status);
        if (flag) {
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("utm_medium");
          UTM_Campaign = urlParams.get("utm_campaign");

          const data = {
            ContactNo: userData.Phone,
            Services: serviceChoice,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: userData.Address,
            Name: userData.Name,
            Pet: userData.Pet,
            latitude: lat,
            longitude: lon,
            Amount:packageAmount*sessionNumber,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 1;

          
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setShowDownloadInvoice(true);
          displayRazorpay(
            userData.amount - couponValue,
            userData.Service,
            dateOfReg,
            userData.Phone,
            userData.Address,
            res.data.bookingId
          );
        } else {
          // console.log("hello guys")
          let UTM_Source,
            UTM_Medium,
            UTM_Campaign = "";

          const urlParams = new URLSearchParams(window.location.search);
          UTM_Source = urlParams.get("utm_source");
          UTM_Medium = urlParams.get("utm_medium");
          UTM_Campaign = urlParams.get("utm_campaign");

          const data = {
            ContactNo: userData.Phone,
            Services: serviceChoice,
            Date: userData.Date,
            Time: userData.Slot,
            Email: "",
            FormType: "Direct Form",
            DateOfRegistration: dateOfReg,
            TimeOfRegistration: timeOfReg,
            Address: address,
            Name: userData.Name,
            Pet: userData.Pet,
            Amount: packageAmount*sessionNumber,
            Latitude: lat,
            Longitude: lon,
            UTM_Source: UTM_Source || "",
            UTM_Medium: UTM_Medium || "",
            UTM_Campaign: UTM_Campaign || "",
          };
          data["paymentStatus"] = 2;

          await axios
            .post(
              "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The grooming form is being submitted...");
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the grooming form..."
              );
            });
          setShowDownloadInvoice(false);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: `Grooming Registration Fired From Mobile Service Page Form`,
          });
          setNavTab(2);
        }
      } catch (error) {
        console.log("error------->", error);
      }
    } else if (nameFill === "") {
      console.log("err");
      setGroomingNameValidation(false);
    } else if (checkPhone(phoneFill).length !== 10) {
      console.log("err123");
      setGroomingPhoneValidation(false);
    } else if (groomingAddressRef.current.value === "") {
      console.log("err");
      setGroomingAddressValidation(false);
    }

    if (
      nameFill === "" &&
      checkPhone(phoneFill).length !== 10 &&
      groomingAddressRef.current.value === ""
    ) {
      console.log("err");
      setGroomingNameValidation(false);
      setGroomingPhoneValidation(false);
      setGroomingAddressValidation(false);
    }
  };
  const [changeColor, setChangeColor] = useState(false);
  const formShiftHandler = () => {
    window.scrollTo(0, 300);
  };

  const backArrow = () => {
    setSelectedCarousel(selectedCarousel - 1);
  };

  const changeButtonColor = (e) => {
    console.log(e);
    // const ele=document.getElementById("send-otp");
    // if(e.target.value.length()>=10){
    //   ele.style.backgroundColor="red";
    // }
  };

  useEffect(() => {
    indicateAlert();
  }, [correctOtp]);

  const applyCoupon = async () => {
    await axios
      .post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/Verifycoupon",
        {
          name: couponCode,
          serviceType: 2,
          package: "Pet Hotel",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.value) {
          setCouponValue(response.data.value);
          setCouponErrorMsg("");
          setCouponNotValid(false);
          setCouponValid(true);
        } else {
          setCouponValid(false);
          setCouponNotValid(true);
          setCouponErrorMsg(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    decrementSeconds();
  }, [seconds]);
  
  const useLocation123 = () => {
    // const { coords } = useGeolocated();
    // console.log(coords);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setLocation({ latitude, longitude });
        console.log("asdf", latitude, longitude);
        setLat(latitude);
        setLon(longitude);
        // const result=getAddress(latitude,longitude);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCeoWpdd6YOVu3wEqqA0qpY6P3B5rUumm8`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const { results } = data;
            if (results && results.length > 0) {
              setAddress(results[9].formatted_address);
              setGroomingAddressValidation(true);
              // console.log(address)
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
      (error) => {
        console.log(error);
      }
    );
    
  };
  


  const handleDivClick1 = () => {
   
    setIsClicked1(!isClicked1);
    setIsClicked2(false);
    setIsClicked3(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(1);
    setPackageAmount(799);
    setSaveAmount(0);
    setAmount(799);
    setServiceChoice("Bath & Brush Subscription(1 session)");
  };

  const handleDivClick2 = () => {
   
    setIsClicked2(!isClicked2);
    setIsClicked1(false);
    setIsClicked3(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(3);
    setPackageAmount(699);
    setSaveAmount(300);
    setServiceChoice("Bath & Brush Subscription(3 sessions)");
  };

  const handleDivClick3 = () => {
   
    setIsClicked3(!isClicked3);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked4(false);
    setIsClicked5(false);
    setSessionNumber(6);
    setPackageAmount(649);
    setSaveAmount(900);
    setServiceChoice("Bath & Brush Subscription(6 sessions)");
  };

  const handleDivClick4 = () => {
   
    setIsClicked4(!isClicked4);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked3(false);
    setIsClicked5(false);
    setSessionNumber(12);
    setPackageAmount(599);
    setSaveAmount(2400);
    setServiceChoice("Bath & Brush Subscription(12 sessions)");
  };

  const handleDivClick5 = () => {
   
    setIsClicked5(!isClicked5);
    setIsClicked1(false);
    setIsClicked2(false);
    setIsClicked4(false);
    setIsClicked3(false);
    setSessionNumber(24);
    setPackageAmount(475);
    setSaveAmount(7776);
    setServiceChoice("Bath & Brush Subscription(24 sessions)");
  };

  const handleDivClick6 = () => {
   
    setIsClicked6(!isClicked6);
    setIsClicked7(false);
    setIsClicked8(false);
    setIsClicked9(false);
    setSessionNumber(1);
    setPackageAmount(1249);
    setSaveAmount(0);
    setServiceChoice("Haircut & Styling(1 session)");
  };
  
  const handleDivClick7 = () => {
   
    setIsClicked7(!isClicked7);
    setIsClicked6(false);
    setIsClicked8(false);
    setIsClicked9(false);
    setSessionNumber(3);
    setPackageAmount(1149);
    setSaveAmount(300);
    setServiceChoice("Haircut & Styling(3 sessions)");
  };

  const handleDivClick8 = () => {
   
    setIsClicked8(!isClicked8);
    setIsClicked6(false);
    setIsClicked7(false);
    setIsClicked9(false);
    setSessionNumber(6);
    setPackageAmount(1049);
    setSaveAmount(1200);
    setServiceChoice("Haircut & Styling(6 sessions)");
  };

  const handleDivClick9 = () => {
   
    setIsClicked9(!isClicked9);
    setIsClicked7(false);
    setIsClicked8(false);
    setIsClicked6(false);
    setSessionNumber(12);
    setPackageAmount(949);
    setSaveAmount(3600);
    setServiceChoice("Haircut & Styling(12 sessions)");
  };
 
  const handleDivClick10 = () => {
   
    setIsClicked10(!isClicked10);
    setIsClicked11(false);
    setIsClicked12(false);
    setIsClicked13(false);
    setSessionNumber(1);
    setPackageAmount(1649);
    setSaveAmount(0);
    setServiceChoice("Bath, Full haircut & Styling(1 session)");
  };

  const handleDivClick11 = () => {
   
    setIsClicked11(!isClicked11);
    setIsClicked10(false);
    setIsClicked13(false);
    setIsClicked12(false);
    setSessionNumber(3);
    setPackageAmount(1549);
    setSaveAmount(300);
    setServiceChoice("Bath, Full haircut & Styling(3 sessions)");
  };

  const handleDivClick12 = () => {
   
    setIsClicked12(!isClicked12);
    setIsClicked10(false);
    setIsClicked11(false);
    setIsClicked13(false);
    setSessionNumber(6);
    setPackageAmount(1449);
    setSaveAmount(1200);
    setServiceChoice("Bath, Full haircut & Styling(6 sessions)");
  };

  const handleDivClick13 = () => {
   
    setIsClicked13(!isClicked13);
    setIsClicked10(false);
    setIsClicked11(false);
    setIsClicked12(false);
    setSessionNumber(12);
    setPackageAmount(1349);
    setSaveAmount(3600);
    setServiceChoice("Bath, Full haircut & Styling(12 sessions)");
  };

  

  return (
    <>
      {navTab === 1 && (
        <div
          className="grooming-page-mobile-view-form-container"
          style={{
            marginTop: location == "/" ? "0px" : "",
            width: location == "/" ? "100%" : "",
            border: location == "/" ? "0px" : "",
            borderRadius: location == "/" ? "0px" : "",
          }}
        >
          {location == "/grooming-subscription" && (
            <div className="grooming-page-mobile-view-form-section-1">
              <div style={{ display: "flex", textAlign: "start" }}>
                <div style={{ width: selectedCarousel === 1 ? "0%" : "10%" }}>
                  <BiArrowBack
                    style={{
                      display: selectedCarousel === 1 ? "none" : "block",
                    }}
                    onClick={backArrow}
                  />
                </div>
                <div
                  style={{
                    width: selectedCarousel === 1 ? "100%" : "90%",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                  onClick={scrollToSection}
                >
                  Book your Grooming Subscription Here! <br />
                  
                </div>
              </div>
            </div>
          )}

          {selectedCarousel == 1 ? (
            <div
              className="grooming-page-mobile-view-services-container"
              ref={sectionRef}
            >
              <div className="">
                <span style={{ color: "#5E5E5E", fontWeight: "700" }}>
                  Type of Pet
                </span>
                <div
                  style={{ display: "flex", padding: "10px", width: "100%" }}
                  className=""
                >
                  <div
                    className="option"
                    id="one"
                    style={{ width: "50%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(1);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Dog");
                          setUserData({ Pet: "Dog" });
                          // console.log("dog");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 1 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Dog.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Dog{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="option"
                    id="two"
                    style={{ width: "50%", display: "flex" }}
                    onClick={() => {
                      setSelectedPetChoice(2);
                    }}
                  >
                    <div className="heading" style={{ marginBottom: "5px" }}>
                      <div
                        className="left"
                        onClick={() => {
                          setSelectedPet("Cat");
                          setUserData({ Pet: "Cat" });
                          // console.log("cat");
                        }}
                      >
                        <div className="selection-circle">
                          {selectedPetChoice == 2 && (
                            <div className="dot"></div>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat.png"
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <span style={{ fontWeight: "500" }}>
                            Cat{" "}
                            <small style={{ fontSize: "13px" }}>
                              {" "}
                              <br />
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="select-service">
                <div>
                  <select
                    id="dropdown"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    style={{
                      width: "20rem",
                      height: "2.5rem",
                      borderRadius: "0.5rem",
                      border: "1px solid #CCC",
                      padding: "0 1rem 0 1rem",
                      fontSize: "1rem",
                      color: "var(--Brand, #FF5E95)",
                      marginBottom: "1rem 0",
                    }}
                  >
                    <option value="option1" onClick={handleDivClick2}>Bath & Brush Subscription</option>
                    <option value="option2" onClick={handleDivClick7}>Haircut & Styling</option>
                    <option value="option3" onClick={handleDivClick11}>
                      Bath, Full haircut & Styling
                    </option>
                    
                  </select>

                  {selectedOption === "option1" && (
                    <div>
                      {/* Content for Option 1 */}
                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick1}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked1
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                              borderRadius: "1rem",
                              marginRight: "1rem",
                              alignItems: "center",
                              // justifyContent:"center",
                              display: "flex",
                              flexDirection: "column",
                          }}
                        >
                          {isClicked1 ? (
                            <div
                              className="subscription_box_heading"
                              style={{
                                width: "6rem",
                                height: "1.3rem",
                                borderRadius: "11px 12px 0px 0px",
                                background: "#FF5E94",
                                flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#fff",
                                  fontFamily: "Lato",
                                  fontSize: "0.8rem",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  // padding: "4px 8px",
                                }}
                              >
                                You Save ₹799
                              </div>
                            </div>
                          ) : (
                            <div
                              className="subscription_box_heading"
                              style={{
                                width: "6rem",
                                height: "1.3rem",
                                borderRadius: "11px 12px 0px 0px",
                                background: "#ccc",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#5C5C5C",
                                  fontFamily: "Lato",
                                  fontSize: "0.8rem",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  // padding: "4px 8px",
                                }}
                              >
                                You Save ₹799
                              </div>
                            </div>
                          )}
                          <img
                            src={DogImage}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 2rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            1 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 799/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick2}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked2
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                              flexDirection:"column",
                              alignItems:"center",
                          }}
                        >

                          {isClicked2 ? (
                            <div
                              className="subscription_box_heading"
                              style={{
                                width: "6rem",
                                height: "1.3rem",
                                borderRadius: "11px 12px 0px 0px",
                                background: "#FF5E94",
                                flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                                
                              }}
                            >
                              <div
                                style={{
                                  color: "#fff",
                                  fontFamily: "Lato",
                                  fontSize: "0.8rem",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  // padding: "4px 8px",
                                }}
                              >
                                You Save ₹899
                              </div>
                            </div>
                          ) : (
                            <div
                              className="subscription_box_heading"
                              style={{
                                width: "6rem",
                                height: "1.3rem",
                                borderRadius: "11px 12px 0px 0px",
                                background: "#ccc",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#5C5C5C",
                                  fontFamily: "Lato",
                                  fontSize: "0.8rem",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  // padding: "4px 8px",
                                }}
                              >
                                You Save ₹899
                              </div>
                            </div>
                          )}
                          <img
                            src={dogBath}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            3 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 699/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick3}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked3
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked3 ? (<div
                              className="subscription_box_heading"
                              style={{
                                width: "6rem",
                                height: "1.3rem",
                                borderRadius: "11px 12px 0px 0px",
                                background: "#FF5E94",
                                flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                              }}
                            >
                              <div
                                style={{
                                  color: "#fff",
                                  fontFamily: "Lato",
                                  fontSize: "0.8rem",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  // padding: "4px 8px",
                                }}
                              >
                                You Save ₹949
                              </div>
                            </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>)}
                          
                          <img
                            src={dog3}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            6 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 649/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                      </div>
                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick4}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked4
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                              flexDirection:"column",
                              alignItems:"center",
                          }}
                        >
                          {isClicked4 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                               style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹999
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹999
                            </div>
                          </div>)}
                          
                          <img
                            src={dog4}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            12 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 599/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                        
                        <div
                          className="subscription_box"
                          onClick={handleDivClick5}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked5
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                              flexDirection:"column",
                              alignItems:"center",
                            // marginLeft:"1rem"
                          }}
                        >
                          {isClicked5?(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,199
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,199
                            </div>
                          </div>)}
                          
                          <img
                            src={dog5}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            24 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 475/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: "1px solid #CCC",
                            borderRadius: "1rem",
                            // marginRight: "1rem",
                            // marginLeft:"1rem"
                            textAlign:"center"
                          }}
                        >
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.5rem",
                              textDecorationLine:"line-through"
                            }}
                          >
                            1 Session ₹799 x {sessionNumber}
                          </div>

                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.2rem",
                              
                            }}
                          >
                            <span
                            style={{color:"#11BC18", lineHeight:"2px",fontSize: "0.9rem",
                            fontStyle: "normal",
                            fontWeight: "800",}}
                            >1 Sessions ₹{packageAmount}</span> x {sessionNumber}
                          </div>
                          <hr style={{width:"4rem"}}/>
                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            You Save extra
                          </div>


                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "1.1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            ₹ {saveAmount}
                          </div>

                        </div>

                       
                      </div>
                    </div>
                  )}

                  {selectedOption === "option2" && (
                    <div>
                      {/* Content for Option 2 */}
                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick6}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked6
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked6 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>)}
                          
                          <img
                            src={dog4}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 2rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            1 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹1,249
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick7}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked7
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked7 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,249
                            </div>
                          </div>)}
                          <img
                            src={dog6}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            3 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 0.7rem",
                            }}
                          >
                            ₹ 1,149/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick8}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked8
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked8 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,349
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,349
                            </div>
                          </div>)}
                          <img
                            src={dog7}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            6 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 0.7rem",
                            }}
                          >
                            ₹ 1,049/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                      </div>

                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick9}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked9
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked9 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,449
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,449
                            </div>
                          </div>)}
                          <img
                            src={dog8}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            12 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹ 949/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                        <div
                          className="subscription_box"
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: "1px solid #CCC",
                            borderRadius: "1rem",
                            // marginRight: "1rem",
                            // marginLeft:"1rem"
                            textAlign:"center"
                          }}
                        >
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.5rem",
                              textDecorationLine:"line-through"
                            }}
                          >
                            1 Session ₹1249 x {sessionNumber}
                          </div>

                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.2rem",
                              
                            }}
                          >
                            <span
                            style={{color:"#11BC18", lineHeight:"2px",fontSize: "0.9rem",
                            fontStyle: "normal",
                            fontWeight: "800",}}
                            >1 Sessions ₹{packageAmount}</span> x {sessionNumber}
                          </div>
                          <hr style={{width:"4rem"}}/>
                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            You Save extra
                          </div>


                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "1.1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            ₹{saveAmount}
                          </div>

                        </div>
                      </div>
                    </div>
                  )}

                  {selectedOption === "option3" && (
                    <div>
                      {/* Content for Option 3 */}
                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick10}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked10
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked10 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,649
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,649
                            </div>
                          </div>)}
                          <img
                            src={dog4}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 2rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            1 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            ₹1,649
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick11}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked11
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked11 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 8px",
                              }}
                            >
                              You Save ₹949
                            </div>
                          </div>)}
                          <img
                            src={dog9}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            3 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 0.7rem",
                            }}
                          >
                            ₹ 1,549/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>

                        <div
                          className="subscription_box"
                          onClick={handleDivClick12}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked12
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",

                          }}
                        >
                          {isClicked12 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,049
                            </div>
                          </div>): (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#CCC",
                              display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,049
                            </div>
                          </div>)}
                          <img
                            src={dog10}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            6 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 0.7rem",
                            }}
                          >
                            ₹ 1,449/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                      </div>

                      <div
                        className="subscription_packages_grooming"
                        style={{
                          padding: "1rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="subscription_box"
                          onClick={handleDivClick13}
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: isClicked13
                              ? "1px solid #FF5E94"
                              : "1px solid #CCC",
                            borderRadius: "1rem",
                            marginRight: "1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                          }}
                        >
                          {isClicked13 ? (<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#FF5E94",
                              flexDirection: "row",
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#fff",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,149
                            </div>
                          </div>):(<div
                            className="subscription_box_heading"
                            style={{
                              width: "6rem",
                              height: "1.3rem",
                              borderRadius: "11px 12px 0px 0px",
                              background: "#ccc",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                          >
                            <div
                              style={{
                                color: "#5C5C5C",
                                fontFamily: "Lato",
                                fontSize: "0.8rem",
                                lineHeight: "normal",
                                fontStyle: "normal",
                                fontWeight: "700",
                                // padding: "4px 2px",
                              }}
                            >
                              You Save ₹1,149
                            </div>
                          </div>)}
                          <img
                            src={DogImage}
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                              margin:"0.5rem 0 0.5rem 0"
                              // margin: "0.4rem 2.2rem 0.5rem 1.7rem",
                            }}
                          />
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1.2rem",
                            }}
                          >
                            12 Session
                          </div>
                          <div
                            className="subscription_box_amount"
                            style={{
                              color: "#3f414e",
                              fontFamily: "Lato",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              // margin: "0 0.7rem",
                            }}
                          >
                            ₹ 1,349/-
                          </div>
                          <div
                            className="subscription_box_session"
                            style={{
                              color: "#fd7a98",
                              fontFamily: "Lato",
                              fontSize: "0.8rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              // margin: "0 1rem",
                            }}
                          >
                            Per Session
                          </div>
                        </div>
                        <div
                          className="subscription_box"
                          style={{
                            width: "6rem",
                            height: "8.9rem",
                            border: "1px solid #CCC",
                            borderRadius: "1rem",
                            // marginRight: "1rem",
                            // marginLeft:"1rem"
                            textAlign:"center"
                          }}
                        >
                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.5rem",
                              textDecorationLine:"line-through"
                            }}
                          >
                            1 Session ₹1649 x {sessionNumber}
                          </div>

                          <div
                            className="subscription_box_number"
                            style={{
                              color: "#727272",
                              fontFamily: "Lato",
                              fontSize: "0.9rem",
                              fontStyle: "normal",
                              fontWeight: "800",
                              lineHeight: "normal",
                              margin: "0 0.5rem",
                              paddingTop:"0.2rem",
                              
                            }}
                          >
                            <span
                            style={{color:"#11BC18", lineHeight:"2px",fontSize: "0.9rem",
                            fontStyle: "normal",
                            fontWeight: "800",}}
                            >1 Sessions ₹{packageAmount}</span> x {sessionNumber}
                          </div>
                          <hr style={{width:"4rem"}}/>
                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "0.8rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            You Save extra
                          </div>


                          <div 
                          style={{
                            color: "#fd7a98",
                            fontFamily: "Lato",
                            fontSize: "1.1rem",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "normal",
                            // margin: "0 0.5rem",
                          }}
                          >
                            ₹{saveAmount}
                          </div>

                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                </div>
              </center>
              <div className="button-group">
                {groomingLoading ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <button
                    onClick={() => {
                      // firstFormDataHandler();
                      // setFormSection(2);
                      groomingFirstFormHandler();

                      // setSelectedCarousel(2);
                      // displayLocation();
                    }}
                    className="next"
                    style={{ fontSize: "18px", color: "black" }}
                  >
                    Next
                  </button>
                )}
              </div>
              <div
                style={{
                  padding: "10px",
                  marginTop: "10px",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <ImageSliderWalking images={images} />
              </div>
            </div>
          ) : null}

          {/* 2nd form section */}

          {selectedCarousel == 2 ? (
            <div className="grooming-page-mobile-view-services-container">
              <div className="name" style={{ marginTop: "10px" }}>
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Enter Name</span>
                  {!groomingNameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingNameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingNameValidation(true);
                      setnameValue(true);
                      setNameFill(e.target.value);
                      setUserData({
                        ...userData,
                        Name: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{ fontSize: "17px" }}
                    value={nameValue ? nameFill : ""}
                  />
                </div>
              </div>
              <div className="phone" style={{ marginTop: "10px" }}>
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Phone number</span>
                  {!groomingPhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={groomingPhoneRef}
                    // disabled={isVerified}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true);
                      setPhoneFill(e.target.value);
                      setUserData({
                        ...userData,
                        Phone: e.target.value,
                      });
                      // changeButtonColor();
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{ fontSize: "17px", width: "100%" }}
                    value={phoneValue ? phoneFill : ""}

                  />
                  
                </div>
                
              </div>
              <div className="dateandtime">
                <div className="select-date">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Session Date
                  </div>
                  {!groomingDateValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}

                  <div
                    className="walking-section-form-input-date-mobile"
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      border: "none",
                      height: "56px",
                    }}
                  >
                    <input
                      ref={groomingDateRef}
                      onChange={() => {
                        firstFormDataHandler();
                        // secondFormDataHandler();
                        setGroomingDateValidation(true);
                        setStartValue(true);
                        setStartDate(groomingDateRef.current.value);
                      }}
                      type="date"
                      className="walking-section-form-input-date-mobile"
                      placeholder="DD/MM/YYYY"
                      required
                      style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "17px",
                      }}
                      value={startValue ? startDate : formattedDate}
                    />
                  </div>

                  
                </div>
                <div className="select-slot">
                  <div style={{ color: "#5E5E5E", fontWeight: "700" }}>
                    Time Slot
                  </div>
                  {!groomingSlotValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Required
                    </div>
                  ) : null}
                  <select
                    name="slot"
                    id="slot"
                    ref={groomingSlotRef}
                    onChange={() => {
                      firstFormDataHandler();
                      setGroomingSlotValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Slot:groomingSlotRef.current.value
                      // })
                      setSlotValue(true);
                      setSlotFill(groomingSlotRef.current.value);
                    }}
                    // onSelect={()=>{
                    //   setSlotValue(true);
                    //   setSlotFill(groomingSlotRef.current.value);
                    // }}
                    style={{ fontSize: "16px" }}
                    // value={userData.Slot}
                    value={slotValue ? slotFill : ""}
                  >
                    <option value="">Select Slot</option>
                    <option value="10AM - 02PM">10AM - 02PM</option>
                    <option value="02PM - 06PM">02PM - 06PM</option>
                    <option value="06PM - 10PM">06PM - 10PM</option>
                  </select>
                </div>
              </div>
              <center>
                <div className="grooming-new-form-section1-carousel-container">
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel"></div>
                </div>
              </center>
              <div className="button-group">
                <button
                  className="next"
                  onClick={() => {
                 
                    nextNavhandler();

                    
                  }}
                  style={{ color: "black" }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}

          {/*3rd form section */}
          {selectedCarousel == 3 ? (
            <div className="second-form-section">
              <div className="address">
                <div
                  className="label"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Your Location</span>
                  {!groomingAddressValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setGroomingAddressValidation(true);
                      // setAdressFill(true);
                      setAddress(e.target.value);
                      // setUserData({
                      //   ...userData,
                      //   Address:e.target.value
                      // })
                      // console.log(e.target.value)
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{ fontSize: "17px" }}
                    // onFocus={handleLocationAccess}
                    value={address}
                    onClick={useLocation123}
                  />
                </div>
              </div>
              <div className="address">
              </div>
              {/* <div className="address">
                <div className="label">
                  <span>Complete Address</span>
                  {!groomingAddressValidation1 ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      Enter your home address
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={groomingAddressRef1}
                    onChange={() => {
                      secondFormDataHandler();
                      setGroomingAddressValidation1(true);
                      setAddressValue(true);
                      setAdressFill(groomingAddressRef1.current.value)
                    }}
                    type="text"
                    placeholder="Enter your Home address"
                    required
                    style={{fontSize:"17px"}}
                    value={addressValue?adressFill:""}
                  />
                </div>
              </div> */}
              {/* <div className="grooming-form-tagline-text">
                <center style={{ fontSize: "1.5rem" }}>
                  Total Payable :
                  {!couponValid && !couponNotValid && <span> ₹{price1}</span>}
                  {couponValid && (
                    <span>
                      <span style={{ textDecoration: "line-through" }}>
                      ₹{price1}
                      </span>
                      <span style={{color:"#618E41", paddingLeft:"0.5rem", fontWeight:"800"}}> ₹{price1 - couponValue}</span>
                    </span>
                  )}
                  {couponNotValid && <span>₹{price1}</span>}
                </center>
              </div> */}
              <center>
                <div
                  className="grooming-new-form-section1-carousel-container"
                  style={{ paddingRight: "20px" }}
                >
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                  <div className="grooming-new-form-section1-carousel-active"></div>
                </div>
              </center>
              <div className="grooming-form-tagline-text-mobile-view">
                <center>
                  Paid Sessions are given time & elite <br /> groomers
                  preference!
                </center>
              </div>

              {isLoading ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <div className="button-group">
                  <button
                    // disabled={isVerified === false || isVerified === null}
                    className="pay-now"
                    onClick={() => {
                      finalFormSubmitHandler(true);
                    }}
                    // style={{backgroundColor:!isVerified?"rgba(131, 145, 161, 1)":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":""}}
                  >
                    Pay Now
                  </button>
                  {/* {location==="/grooming"? */}
                  <button
                    onClick={() => {
                      finalFormSubmitHandler(false);
                      setBookNow(true);
                    }}
                    className="pay-later"
                    // style={{backgroundColor:!isVerified?"white":"",border:!isVerified?"2px solid rgba(131, 145, 161, 1)":"",color:!isVerified?"rgba(131, 145, 161, 1)":""}}
                  >
                    Book Now & Pay Later
                  </button>
                  {/* // :null} */}
                </div>
              )}
            </div>
          ) : null}
          <center>
            {selectedCarousel === 3 ? (
              <div
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  lineHeight: "18.75px",
                }}
              >
                <h3
                  style={{ color: "rgba(114, 114, 114, 1)", fontSize: "15px" }}
                >
                  Your Data Privacy matters.
                </h3>
                <p
                  style={{ color: "rgba(131, 145, 161, 1)", fontSize: "15px" }}
                >
                  We only use your contact number to reach you regarding your
                  service request
                </p>
              </div>
            ) : null}
            <center>
              <LazyLoad>
                <img src={petmojologo} width={170} height={70} />
              </LazyLoad>
            </center>
            <div
              style={{
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "20px",
              }}
            >
              <p style={{ color: "rgba(93, 93, 93, 1)" }}>
                Copyright 2022 Petmojo <br />
                All copyrights are reserved - any infringement will be legally
                pursued
              </p>
            </div>
          </center>
        </div>
      )}
      {navTab === 2 && (
        <div className="order-placed-container">
          <div className="container">
            <div className="first-box">
              <span>
                {" "}
                <strong> Order Successfully Placed:</strong>
              </span>
              <div className="middle">{userData.Service}</div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>Number of pets: 1</span>
                  <br />
                  <span>
                    Date: {userData.Date.split("-").reverse().join("-")}
                  </span>

                  <br />
                </div>
                {!bookNow ? (
                  <div>
                    <img
                      src={downloadInvoice}
                      width={50}
                      style={{
                        objectFit: "contain",
                        position: "relative",
                        top: "-40px",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="second-box">
              <div className="left">
                <span>
                  <strong> Billing Address: </strong>
                </span>
                <div>
                  <p>{address}</p>
                </div>
              </div>
              <div className="right" style={{ backgroundColor: "white" }}>
                <img
                  src={groomingvector}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <div className="third-box">
              <div
                onClick={() => {
                  console.log(userData);
                }}
                className="tick"
              >
                <img src={greenTick} alt="" />
              </div>
              <p>
                Your information has been sent to our pet counselor, We will
                reach you soon.
              </p>

              <a
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
                style={{ textDecoration: "none" }}
              >
                <div className="download" style={{ marginBottom: "15px" }}>
                  Download App to Contact Groomer
                </div>
              </a>
              <div className="button-group">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                  <img src={googlePlay} alt="" className="playstore" />
                </a>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328">
                  <img src={appleStore} alt="" className="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
        
      )}
    </>
  );
};

export default GroomingPageForm;
