import './Blog.css';
import {Link} from 'react-router-dom';

const BlogListGenerator = (props) => {
    return (
        <div onClick={()=>
            props.setBlogSchema(!props.blogSchema)
          }>
        <Link to= {{pathname: `/blog/${props.dataObj.id}`, state: props.dataObj}} style={{textDecoration: "none"}}  >
            <div className="blogs-desktop-view-main-container-left-right-item-container-right">
                <div className="blogs-desktop-view-main-container-left-right-item-image-container">
                    <img alt="pupImage" className="blogs-desktop-view-main-container-left-right-item-image" src={props.dataObj.src} />
                </div>
                <div className="blogs-desktop-view-main-container-left-right-item-content">
                    <div>{props.dataObj.heading}</div>
                    <div className="blogs-desktop-submai1-date">{props.dataObj.date}</div>
                </div>
            </div>
        </Link> 
        </div>
    );
};

export default BlogListGenerator;