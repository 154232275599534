import {useEffect, useState} from 'react';
import NavigationBar from '../../Navigation/navigationBar/NavigationBar';
import WalkingPageForm from '../mobileForms/WalkingPageForm';
import { RxCross2 } from "react-icons/rx";
import IntroContainer from "../introContainer/IntroContainer";

const IntroContainerMobileTraining = (props) => {
  const [showad, setShowad] = useState(true);

  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  const removeAdd = () => {
    if(document.getElementById("grooming-popup-ad")){
    const ele = document.getElementById("grooming-popup-ad");

    ele.style.display = "none";
    setShowad(false);
    }
  };
  setTimeout(() => {
    if(document.getElementById("grooming-popup-ad")){
      const ele = document.getElementById("grooming-popup-ad");
    ele.style.display = "flex";
    }
  }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }
    return (
        
      <div className='walking-page-intro-mobile-view'>
        {/* {showad && (
          <div
          style={{ width: "100%", backgroundColor: "white", display: "none",flexDirection:"column" }}
          id="grooming-popup-ad"
          >
          <div
            style={{
              width: "100%",
              padding: "5px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div onClick={removeAdd}>
              <RxCross2 color="#5E5E5E" />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: "80px",
              gap: "8px",
              display:"flex",
              alignItems: "center",
              padding: "5px",
              justifyContent: "center",
            }}
          >
            <div style={{ height: "100%" }}>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/install_now.png"
                alt="logo"
                style={{ height: "100%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "DM Sans",
                height: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "rgba(94, 94, 94, 1)",
                  fontWeight: "700",
                }}
              >
                ₹1000 in your Wallet instantly
              </div>
              <div
                style={{
                  fontSize: "15px",
                  color: "rgba(94, 94, 94, 1)",
                  fontWeight: "400",
                }}
              >
                Book any services now to avail <br /> wallet discount
              </div>
            </div>
          </div>
          <div style={{ padding: "5px", width: "100%",marginBottom:"10px" }}>
            <a
              href={url}
              target="_blank"
              style={{
                textDecoration: "none",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  width: "80%",
                  height: "30px",
                  backgroundColor: "rgba(225,199,110)",
                  outline: "none",
                  border: "none",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  fontFamily: "DM Sans",
                }}
              >
                INSTALL NOW
              </button>
            </a>
          </div>
        </div>
        )} */}
        <NavigationBar btn={"walking"} homePage={true} page={"white"} className="home-page-mobile-navigation-container" />
        
        <div className='home-page-intro-mobile-view-container' style={{ marginTop: "0px" }}>
          <h1 className='intro-heading-mobile-view-header' style={{paddingLeft:"2px",paddingRight:"2px"}}>
            <div className='home-page-intro-mobile-view-heading-training'>
              <div>India's Most <span className='pink-bold'>Trusted </span></div>
              <div>Daily Dog <span className='pink-bold'>Exercise/Walking</span></div>
            </div>
          </h1>
          <div className='home-page-intro-mobile-view-points-training'>
            <div className='home-page-intro-mobile-view-point'>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
              <div>Loving and Trusted dog walkers in your Neighbourhood</div>
            </div>
            <div className='home-page-intro-mobile-view-point'>
              <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
              <div>Improve your pet's Lifestyle and Health</div>
            </div>
          </div>
          <div className="home-page-mobile-form-container">
            <WalkingPageForm />
          </div>
        </div>
  
      </div>
        
    );
};

export default IntroContainerMobileTraining;