import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../loadingSpinner/LoadingSpinner";
import { useCallback } from "react";
import greenTick from "../../../images/green-tick.png";
import {BiArrowBack} from "react-icons/bi";
//import dotenv from 'dotenv'

const HomePageForm = (props) => {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState(1);
  const [timeSlot, setTimeSlot] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [serviceChoice, setServiceChoice] = useState("BATH & BRUSH");
  const [overallChoice, setOverallChoice] = useState({
    choice: "",
    date: "",
    time: "10AM-02PM",
    name: "",
    address: "",
    phone: "",
  });
  const [newNameValidation, setNewNameValidation] = useState(true);
  const [newPhoneValidation, setNewPhoneValidation] = useState(true);
  const [newAddressValidation, setNewAddressValidation] = useState(true);
  const [newDateValidation, setNewDateValidation] = useState(true);
  const [representationDate, setRepresentationDate] = useState("");
  const [representationDay, setRepresentationDay] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";
  const [token, setToken] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const newDateRef = useRef("");
  const newServiceRef = useRef("");
  const newTimeServiceRef = useRef("");
  const newNameRef = useRef("");
  const newAddressRef = useRef("");
  const newPhoneRef = useRef("");
  const groomingOtpRef = useRef("");

  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);

  function setSelectedServiceHandler(service) {
    setSelectedService(service);
    setServiceDropdown(false);
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(
    serviceAmount,
    inputPhone,
    SelectedService,
    DateOfReg,
    InputAddress
  ) {
    setIsLoading(false);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPayOrderId",
      {
        amount: serviceAmount,
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Petmojo",
      description: "Pet Grooming Payment",
      //image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        //console.log("Grooming Page Function: ", data);

        const result = await axios.post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/generateGroomingRazorPaySuccess",
          data
        );
        //alert(result.data.msg);
        const Data = {
          ...result.data,
          SelectedService,
          DateOfReg,
          InputAddress,
        };

        const actualAmount = serviceAmount / 100;

        const dataToStore = {
          Date: DateOfReg,
          Address: InputAddress,
          Contact_No: inputPhone.toString(),
          Amount_Paid: actualAmount.toString(),
          Selected_Service: SelectedService,
          Order_id: order_id,
        };

        await axios
          .post(
            "https://api.apispreadsheets.com/data/hq3CiEAYcPfNXIJE/",
            dataToStore
          )
          .then((response) => {
            console.log(response);
            console.log("The grooming payment has been completed!");
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in submitting the grooming payment to backend!"
            );
          });

        const data2 = {
          bookingId: bookingId,
          transactionId: order_id,
          amount: actualAmount,
          type: "User",
        };

        data2["isFromWebsite"]=true;
        // {
        //     "bookingId" : "63d554eb156e83edfd5d1144",
        //     "transactionId" : "order_JCB9CaLSarOaTj",
        //     "amount":799,
        //     "type": "User"
        // }

        await axios
          .patch(
            "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/service/postGroomingPayment",
            data2,
            {
              "Content-Type": "application/json",
              headers:{
                Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYzZWY5ZWZkNTc1MGY5Y2Q4MmVmYmZmNyJ9.QOz1wBekeflAqD2ktWjDQLubM4snuMMDFV0Lk97z5UY"
              }
              
            }
          )
          .then((response) => {
            console.log(response);
            console.log(
              "The grooming payment has been completed and info has been saved in the database!"
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(
              "There was some error in saving the grooming payment to database!"
            );
          });

        setPaymentData(Data);
        setFlag(true);
      },
      // prefill: {
      //     name: "Petmojo",
      //     email: "support@petmojo.care",
      //     contact: "+91 9009004473",
      // },
      notes: {
        address: "Petmojo",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    //console.log(paymentObject);
    paymentObject.open();
  }
  const backArrow=()=>{
    setSelectedCarousel(selectedCarousel-1);
}
  const groomingFormDesktopHandler1 = () => {
    if (serviceChoice === "") {
      console.log("Cant move to next card!");
    } else {
      setOverallChoice({
        choice: serviceChoice,
        name: "",
        address: "",
        phone: "",
        time: "",
        date: "",
      });
      // setSelectedCarousel(2);
      console.log(overallChoice);
    }
  };

  const groomingFormDesktopHandler2 = () => {
    overallChoice.date = newDateRef.current.value;
    if (overallChoice.date == "") {
      console.log("Cant move to next card!");
      console.log(overallChoice);
    } else {
      let time = "";
      if (timeSlot === 1) {
        time = "10AM-02PM";
      } else if (timeSlot === 2) {
        time = "02PM-06PM";
      } else if (timeSlot === 3) {
        time = "06PM-10PM";
      }

      setSelectedDate(newDateRef.current.value);
      setSelectedTime(time);
      //overallChoice.time

      setOverallChoice({
        choice: serviceChoice,
        date: newDateRef.current.value,
        time: time,
        name: "",
        address: "",
        phone: "",
      });
      // setSelectedCarousel(3);
      console.log(overallChoice);
    }
  };

  const getmobileOtp = useCallback(async () => {
    try {
      const res = await axios.post(`${baseUrl}/auth/sendMobileOTP`, {
        phoneNumber: newPhoneRef.current.value.toString(),
      });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const verifyMobileOTP = useCallback(async () => {
    try {
      const res = await axios.post(
        "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/auth/verifyMobileOTP",
        {
          otp: groomingOtpRef.current.value.toString(),
          phoneNumber: newPhoneRef.current.value.toString(),
          type: "User",
        }
      );
      console.log(res.status);
      console.log(res.data);

      if (res.status === 200) {
        setIsVerified(true);
        setToken(res.data.token);
      }
    } catch (error) {
      console.log(error.response.status);
      console.log(error);
      if (error.response.status === 400) {
        setIsVerified(false);
        setIsWrongOtp(true);
      }
    }
  }, []);

  const paymentGatewayHit = async (data) => {
    const path = "grooming";
    const inputPhone = data.ContactNo;
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;
    const inputAddress = newAddressRef.current.value;

    var amount = 0;

    if (data.Services === "BATH & BRUSH") {
      //kartik
      amount = 899;
    } else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
      amount = 1199;
    } else if (data.Services === "HAIRCUT & STYLING") {
      amount = 1249;
    } else if (data.Services === "BATH & FULL HAIRCUT") {
      amount = 1799;
    }

    data["Amount"]=amount;
    axios
      .post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData", data)
      .then((response) => {
        console.log(response);
        console.log("The grooming form is being submitted...");
      })
      .catch((err) => {
        console.log(err);
        console.log("There was some error in submitting the grooming form...");
      });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${path} Registration Fired From Desktop Service Page Form`,
    });

    displayRazorpay(
      amount,
      inputPhone,
      selectedService,
      dateOfReg,
      inputAddress
    );

    console.log(data);
  };

  const paymentInitiationHandler = async () => {
    const phone = newPhoneRef.current.value;
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const phoneString = phone.toString();

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
      });

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
        
      };

      // ContactNo: inputPhone,
      // Services: selectedService,
      // Date: inputDate,
      // Time: inputTime,
      // Address: inputAddress,
      // DateOfRegistration: dateOfReg,
      // TimeOfRegistration: timeOfReg,
      // Email: inputEmail,
      // FormType: "Direct Form"

      var amount = 0;

      if (data.Services === "BATH & BRUSH") {
        //kartik
        amount = 899;
      } else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      } else if (data.Services === "HAIRCUT & STYLING") {
        amount = 1249;
      } else if (data.Services === "BATH & FULL HAIRCUT") {
        amount = 1799;
      }

      let UTM_Source, UTM_Medium, UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get('utm_source');
      UTM_Medium = urlParams.get('utm_medium');
      UTM_Campaign = urlParams.get('utm_campaign')
      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        startDate: selectedDate,
        sessionTime: selectedTime,
        package: {
          description: serviceChoice,
          price: amount,
          frequency:1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      data2["Amount"]=amount;
      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setBookingId(response.bookingId);
          console.log("The grooming data is submitted to the database.");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      paymentGatewayHit(data);
      return;
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  const bookNowPayLaterHandler = async () => {
    const phone = newPhoneRef.current.value;
    const name = newNameRef.current.value;
    const address = newAddressRef.current.value;

    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    const phoneString = phone.toString();

    const enteredYear = parseInt(selectedDate.substring(0, 4));
    const enteredMonth = parseInt(selectedDate.substring(5, 7));
    const enteredDay = parseInt(selectedDate.substring(8, 10));

    if (phoneString.length !== 10 || name === "" || address === "") {
      console.log("cannot proceed further!");
    } else {
      // if(enteredMonth === date.getMonth()+1){
      //     if(enteredDay <= date.getDate()){
      //       setNewDateValidation(false);
      //       return;
      //     }
      // }

      setIsLoading(true);

      setOverallChoice({
        choice: serviceChoice,
        name: name,
        address: address,
        phone: phone,
        date: selectedDate,
        time: selectedTime,
      });

      const data = {
        ContactNo: phone,
        Services: serviceChoice,
        Date: selectedDate,
        Time: selectedTime,
        Email: "",
        FormType: "Direct Form",
        DateOfRegistration: dateOfReg,
        TimeOfRegistration: timeOfReg,
        Address: address,
        Name: name,
      };

      var amount = 0;

      if (data.Services === "BATH & BRUSH") {
        //kartik
        amount = 899;
      } else if (data.Services === "BATH & BRUSH (With Tick Treatment)") {
        amount = 1199;
      } else if (data.Services === "HAIRCUT & STYLING") {
        amount = 1249;
      } else if (data.Services === "BATH & FULL HAIRCUT") {
        amount = 1799;
        
      }

      data["Amount"]=amount;
      axios
        .post("https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/saveGroomingData", data)
        .then((response) => {
          console.log(response);
          console.log("The grooming form is being submitted...");
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "There was some error in submitting the grooming form..."
          );
        });
        let UTM_Source, UTM_Medium, UTM_Campaign = "";

      const urlParams = new URLSearchParams(window.location.search);
      UTM_Source = urlParams.get('utm_source');
      UTM_Medium = urlParams.get('utm_medium');
      UTM_Campaign = urlParams.get('utm_campaign')

      const data2 = {
        fullName: name,
        address: address,
        phone: phone,
        startDate: selectedDate,
        sessionTime: selectedTime,
        package: {
          description: serviceChoice,
          amount: amount,
          frequency:1,
        },
        type: "User",
        UTM_Source: UTM_Source || "",
        UTM_Medium: UTM_Medium || "",
        UTM_Campaign: UTM_Campaign || "",
      };

      console.log(data2);
      data2["Amount"]=amount;

      await axios
        .post(
          "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api/serviceBooking/websiteDogGroomingBookingService",
          data2,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          console.log("The grooming data is submitted to the database.");
        })
        .catch((err) => {
          console.log(err);
          console.log("There was some error in saving the data in database.");
        });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: `Grooming Registration Fired From Desktop Service Page Form`,
      });

      navigate("/thankPageGroom");
    }

    if (phoneString.length !== 10) {
      setNewPhoneValidation(false);
    }
    if (name === "") {
      setNewNameValidation(false);
    }
    if (address === "") {
      setNewAddressValidation(false);
    }
  };

  useEffect(() => {
    if (flag) navigate("/ordersummary", { state: paymentData });
  }, [paymentData, flag]);

  const date = new Date();

  // declaration of refs
  const phoneRef = useRef("");
  const emailRef = useRef("");
  const dateRef = useRef("");
  const addressRef = useRef("");
  const timeRef = useRef("");

  useEffect(() => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    var MonthName;
    if (date.getMonth() + 1 == 1) {
      MonthName = "January";
    } else if (date.getMonth() + 1 == 2) {
      MonthName = "February";
    } else if (date.getMonth() + 1 == 3) {
      MonthName = "March";
    } else if (date.getMonth() + 1 == 4) {
      MonthName = "April";
    } else if (date.getMonth() + 1 == 5) {
      MonthName = "May";
    } else if (date.getMonth() + 1 == 6) {
      MonthName = "June";
    } else if (date.getMonth() + 1 == 7) {
      MonthName = "July";
    } else if (date.getMonth() + 1 == 8) {
      MonthName = "August";
    } else if (date.getMonth() + 1 == 9) {
      MonthName = "September";
    } else if (date.getMonth() + 1 == 10) {
      MonthName = "October";
    } else if (date.getMonth() + 1 == 11) {
      MonthName = "November";
    } else if (date.getMonth() + 1 == 12) {
      MonthName = "December";
    }

    var DayName;
    if (date.getDay() + 1 == 1) DayName = "Sunday";
    else if (date.getDay() + 1 == 2) DayName = "Monday";
    else if (date.getDay() + 1 == 3) DayName = "Tuesday";
    else if (date.getDay() + 1 == 4) DayName = "Wednesday";
    else if (date.getDay() + 1 == 5) DayName = "Thursday";
    else if (date.getDay() + 1 == 6) DayName = "Friday";
    else if (date.getDay() + 1 == 7) DayName = "Saturday";

    const repDate =
      MonthName + " " + date.getDate() + ", " + date.getFullYear() + " | ";
    const repDay = DayName;
    setRepresentationDate(repDate);
    setRepresentationDay(repDay);
  }, []);

  return (
    <div className="home-page-desktop-intro-form-container2">
      <div className="walking-page-desktop-intro-form-header" >
              <div style={{display:"flex",alignItems:"start",}}>
                <div style={{width:selectedCarousel===1?"0%":"8%",display:"flex",justifyContent:"center"}}>
                    <BiArrowBack style={{display:selectedCarousel===1?"none":"block"}} onClick={backArrow}/>
                </div>
                <div style={{width:selectedCarousel===1?"100%":"90%"}}>
                Book your Grooming Session Here
                </div>
              </div>  
            </div>

      {selectedCarousel == 1 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section1-left">
              <div className="grooming-new-form-section1-left-head">
                Select service of your choice
              </div>
              <div className="grooming-new-form-section1-left-container">
                <div
                  className={
                    serviceChoice === "BATH & BRUSH"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div className="grooming-new-form-section1-left-choice-name-container">
                    <div className="selection-circle">
                      {serviceChoice == "BATH & BRUSH" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    {/* <input onClick={() => setServiceChoice("BATH & BRUSH")} type="radio" id="bath&brush" className='radio-grooming-form-desktop'></input> */}
                    <div
                      onClick={() => (
                        setServiceChoice("BATH & BRUSH"),
                        setOverallChoice({ choice: "BATH & BRUSH" })
                      )}
                      htmlFor="bath&brush"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        BATH & BRUSH
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹899
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "BATH & BRUSH (With tick treatment)"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div className="grooming-new-form-section1-left-choice-name-container">
                    <div className="selection-circle">
                      {serviceChoice ==
                        "BATH & BRUSH (With tick treatment)" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    {/* <input onClick={() => setServiceChoice("BATH & BRUSH (With tick treatment)")} type="radio" id="bath&brushTicks" className='radio-grooming-form-desktop'></input> */}
                    <div
                      onClick={() => (
                        setServiceChoice("BATH & BRUSH (With tick treatment)"),
                        setOverallChoice({
                          choice: "BATH & BRUSH (With tick treatment)",
                        })
                      )}
                      htmlFor="bath&brushTicks"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        BATH & BRUSH (With tick treatment)
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹1199
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "HAIRCUT & STYLING"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div className="grooming-new-form-section1-left-choice-name-container">
                    <div className="selection-circle">
                      {serviceChoice == "HAIRCUT & STYLING" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    {/* <input onClick={() => setServiceChoice("HAIRCUT & STYLING")} type="radio" id="haircutstyling" className='radio-grooming-form-desktop'></input> */}
                    <div
                      onClick={() => (
                        setServiceChoice("HAIRCUT & STYLING"),
                        setOverallChoice({ choice: "HAIRCUT & STYLING" })
                      )}
                      htmlFor="haircutstyling"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        HAIRCUT & STYLING
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹1249
                  </div>
                </div>
                <div
                  className={
                    serviceChoice === "BATH & FULL HAIRCUT"
                      ? "grooming-new-form-section1-left-choice-container-active"
                      : "grooming-new-form-section1-left-choice-container"
                  }
                >
                  <div className="grooming-new-form-section1-left-choice-name-container">
                    <div className="selection-circle">
                      {serviceChoice == "BATH & FULL HAIRCUT" && (
                        <div className="dot"></div>
                      )}
                    </div>
                    {/* <input onClick={() => setServiceChoice("BATH & FULL HAIRCUT")} type="radio" id="bathhaircut" className='radio-grooming-form-desktop'></input> */}
                    <div
                      onClick={() => (
                        setServiceChoice("BATH & FULL HAIRCUT"),
                        setOverallChoice({ choice: "BATH & FULL HAIRCUT" })
                      )}
                      htmlFor="bathhaircut"
                    >
                      <span className="grooming-new-form-section1-left-choice-name">
                        BATH & FULL HAIRCUT
                      </span>
                    </div>
                  </div>
                  <div className="grooming-new-form-section1-left-choice-price">
                    ₹1799
                  </div>
                </div>
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div className="grooming-new-form-section1-right-include-container" style={{width:"80%"}}>
                <div className="grooming-new-form-section1-right-include-text" style={{width:"100%",textAlign:"start"}}>
                  Includes
                </div>
                {serviceChoice === "BATH & BRUSH" ? (
 <div className="grooming-new-form-section1-right-include-list-container">
                 <div style={{display:"flex",width:"100%",gap:"10%",justifyContent:"end"}}>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Includes Bath</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Shampoo</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Blow Dry</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Brushing</div>
                      </div>
                    </div>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Nail Trim</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Ear Cleaning or Flushing</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Scissoring/Trimming feet hair</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Teeth Brushing</div>
                      </div>
                    </div>
                  </div>
                 </div>
                ) : null}

                {serviceChoice === "BATH & BRUSH (With tick treatment)" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div style={{display:'flex',width:"100%",gap:"10%",justifyContent:"center"}}>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Includes Bath</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Shampoo</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Blow Dry</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Brushing</div>
                      </div>
                      
                    </div>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Plus Tick Treatment</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Nail Trim</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Scissoring/Trimming feet hair</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Teeth Brushing</div>
                      </div>
                    </div>
                  </div>
                  </div>
                ) : null}

                {serviceChoice === "HAIRCUT & STYLING" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div style={{display:'flex',width:"100%",gap:"10%",justifyContent:"center"}}>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Full Haircut</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Shaving</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Hairstyling</div>
                      </div>
                    </div>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Comes with Face Trim</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Comes with Feet Trim</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Comes with Fanny Trim</div>
                      </div>
                    </div>
                  </div>
                  </div>
                ) : null}

                {serviceChoice === "BATH & FULL HAIRCUT" ? (
                  <div className="grooming-new-form-section1-right-include-list-container">
                    <div style={{display:'flex',width:"100%",gap:"10%",justifyContent:"center"}}>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Includes Bath</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Shampoo</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Blow Dry</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Brushing</div>
                      </div>
                    </div>
                    <div >
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Nail Trim</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Ear Cleaning or Flushing</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <li></li>
                        </div>
                        <div style={{fontSize:"15px"}}>Shaving and Hairstyling</div>
                      </div>
                    </div>
                  </div>
                  </div>
                ) : null}
              </div>
              <div className="grooming-new-form-section1-right-next-button-container">
                <button
                  onClick={() => {
                    groomingFormDesktopHandler1();
                    setSelectedCarousel(2);
                  }}
                  className="grooming-new-form-section1-right-next-button"
                  style={{width:"85%",color:"black"}}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 2 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section2-left">
              <div className="grooming-new-form-section2-left-date-container">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Session Date
                  </div>
                  {newDateValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-desktop-input-space1" style={{position:"relative"}}>
                  <input
                    ref={newDateRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="DD/MM/YY"
                    type="date"
                    style={{padding:"10px"}}
                  ></input>
                </div>
              </div>

              <div className="grooming-new-form-section2-left-date-text-container">
                {representationDate}{" "}
                <span className="pink-text">{representationDay}</span>
              </div>
            </div>
            <div className="grooming-new-form-section1-right">
              <div className="grooming-new-form-section2-right-time-slot-container">
                <div>Time Slot</div>
                <div className="grooming-new-form-section2-right-time-slots">
                  <div
                    onClick={() => setTimeSlot(1)}
                    className={
                      timeSlot == 1
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    10AM-02PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(2)}
                    className={
                      timeSlot == 2
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    02PM-06PM
                  </div>
                  <div
                    onClick={() => setTimeSlot(3)}
                    className={
                      timeSlot == 3
                        ? "grooming-new-form-section2-right-time-slot-active"
                        : "grooming-new-form-section2-right-time-slot"
                    }
                  >
                    06PM-10PM
                  </div>
                </div>
              </div>
              <div className="grooming-new-form-section2-right-next-button-container">
                <button
                  onClick={() => {
                    groomingFormDesktopHandler2();
                    setSelectedCarousel(3);
                  }}
                  className="grooming-new-form-section1-right-next-button" style={{width:"90%",color:"black"}}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel"></div>
          </div>
        </div>
      ) : null}

      {selectedCarousel == 3 ? (
        <div className="grooming-new-form-section1-main-container">
          <div className="grooming-new-form-section1-main">
            <div className="grooming-new-form-section3-left">
              <div className="grooming-new-form-section3-right-ph-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Enter Name
                  </div>
                  {newNameValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>

                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => setNewNameValidation(true)}
                    ref={newNameRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter your name"
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="grooming-new-form-section3-right-address-section">
                <div className="grooming-new-form-validation-container-desktop">
                  <div className="grooming-new-form-section3-right-ph-text">
                    Address
                  </div>
                  {newAddressValidation ? null : (
                    <div className="home-page-desktop-phone-validation-text">
                      Required
                    </div>
                  )}
                </div>
                <div className="grooming-new-form-section3-right-ph-input-contact">
                  <input
                    onChange={() => setNewAddressValidation(true)}
                    ref={newAddressRef}
                    className="grooming-new-form-desktop-input-container-type1"
                    placeholder="Enter Your Home Address"
                    type="text"
                  ></input>
                </div>
              </div>
            </div>
            <div className="grooming-new-form-section3-right">
              <div className="grooming-new-form-section3-right-ph-section-2">
                <div className="grooming-new-form-section3-right-ph-section-Phone-container">
                  <div>
                    <div className="grooming-new-form-validation-container-desktop">
                      <div className="grooming-new-form-section3-right-ph-text">
                        Phone Number
                      </div>
                      {newPhoneValidation ? null : (
                        <div className="home-page-desktop-phone-validation-text">
                          Required
                        </div>
                      )}
                    </div>
                    <div className="grooming-new-form-section3-right-ph-input-contact">
                      <div>
                        <input
                          disabled={isVerified}
                          onChange={() => {
                            setNewPhoneValidation(true);
                          }}
                          ref={newPhoneRef}
                          className={
                            isVerified
                              ? "grooming-new-form-desktop-input-container-type1-verified"
                              : "grooming-new-form-desktop-input-container-type1"
                          }
                          placeholder="Contact number"
                          type="number"
                          required
                        ></input>
                      </div>
                      <div>
                        {isVerified === true ? null : (
                          <div
                            disabled={isVerified}
                            onClick={() => {
                              getmobileOtp();
                              setShowEnterOtp(true);
                            }}
                            className="send-otp-button-desktop-container"
                          >
                            Send OTP
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {isVerified !== true &&
                    showEnterOtp &&
                    newPhoneRef.current.value.toString().length === 10 && (
                      <div className="enterotp">
                        <div className="enterotp-heading">
                          <div>Enter OTP</div>
                          {isWrongOtp && !isVerified ? (
                            <div className="home-page-walking-form-mobile-feedback">
                              Wrong OTP
                            </div>
                          ) : null}
                        </div>
                        <input
                          className={
                            isVerified
                              ? "otp-input-container-desktop-verified"
                              : "otp-input-container-desktop"
                          }
                          ref={groomingOtpRef}
                          onClick={() => {
                            setIsWrongOtp(false);
                          }}
                          disabled={isVerified}
                          type="number"
                          placeholder="Enter Otp"
                        />
                        {isVerified ? null : (
                          <button
                            className={
                              isVerified
                                ? "verified-button"
                                : "non-verified-button"
                            }
                            disabled={isVerified}
                            onClick={() => {
                              verifyMobileOTP();
                            }}
                          >
                            {isVerified ? (
                              <>
                                <img src={greenTick} width={15} />{" "}
                                <span style={{ marginLeft: "5px" }}>
                                  Verified
                                </span>
                              </>
                            ) : (
                              "Verify"
                            )}
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="grooming-form-tagline-text">
                Paid Sessions are given time & elite groomers preference!
              </div>
              {isLoading ? (
                <div className="grooming-page-loading-spinner-container-desktop">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="grooming-new-form-section1-right-pay-button-container">
                  <button
                    disabled={isVerified === false || isVerified === null}
                    onClick={() => paymentInitiationHandler()}
                    className="grooming-new-form-section1-right-pay-button"
                  >
                    Pay Now
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="grooming-new-form-section1-carousel-container">
            <div className="grooming-new-form-section1-carousel-active"></div>
            <div className="grooming-new-form-section1-carousel-active "></div>
            <div className="grooming-new-form-section1-carousel-active"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomePageForm;

//displayRazorpay()

//onClick={() => groomingDataSubmitHandler("grooming")}

// /payment/success:

//     request body:
// {
//     orderCreationId: order_id,
//     razorpayPaymentId: response.razorpay_payment_id,
//     razorpayOrderId: response.razorpay_order_id,
//     razorpaySignature: response.razorpay_signature,
// }
//     response body:
//         {
//             msg: "success",
//             orderId: razorpayOrderId,
//             paymentId: razorpayPaymentId,
//         }

// /payment/orders:
//         request body:
//             {
//                 'amount': serviceAmount
//             }

//         response body:

//                 {
//                     id: 'order_Kjsc3E9EEaiS13',
//                     entity: 'order',
//                     amount: 100,
//                     amount_paid: 0,
//                     amount_due: 100,
//                     currency: 'INR',
//                     receipt: 'receipt_order_74394',
//                     offer_id: null,
//                     status: 'created',
//                     attempts: 0,
//                     notes: [],
//                     created_at: 1669293764
//                 }
