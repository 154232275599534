import { Link } from "react-router-dom";
import { scrollToTop } from "react-scroll/modules/mixins/animate-scroll";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { event } from "@fullstory/browser";

function FooterCTAButton(props) {
  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };
  // height

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showDataA, setShowDataA] = useState(true);
  const [showDataB, setShowDataB] = useState(false);
 
  useEffect(() => {
    const handleScroll = () => {
       const currentScrollPosition = window.scrollY;
      setScrollPosition(currentScrollPosition);

      if (currentScrollPosition >= 800) {
        setShowDataA(false);
        setShowDataB(true);
      } else {
        setShowDataA(true);
        setShowDataB(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation().pathname;
  const [ctaName, setCtaName] = useState("");

  // useEffect(() => {
  //   if (location==="/grooming"){
  //     const handleScroll = () => {
  //     const scrollY = window.scrollY;
  //     console.log(scrollY)
  //     if(scrollY>400){
  //       setCtaName("Book a Professional Groomer")
  //       // console.log("Book")
  //       return;
  //     }
  //     if(scrollY<400){
  //       setCtaName("Next")
  //     }
      
  //     setCtaName("Next")
  //     // console.log("Next")
    
  //     //setScrollingUp(scrollY > 900 ? false : true);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   }
  // }, []);
  useEffect(() => {
    if (location === "/dog-training") {
      setCtaName("Book FREE Session");
    } else if (location === "/grooming") {
        setCtaName("Book a Professional Groomer")
    }
     else if (location === "/dog-running") {
      setCtaName("Book FREE Walk");
    }
  }, [location]);
  return (
    // (props.path === "/grooming") ? (

    // <Link to="/popup" state={{ type: "grooming" }}>
    <button
      className="glowBtn"
      style={{
        width: "100%",
        textAlign: "center",
        padding: "1rem 0rem",
        position: "fixed",
        bottom: "0%",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "20px",
        // color: "white",
        border: "none",
        zIndex: "100",
      }}
      onClick={() => scrollToTop()}
    >
      {ctaName}
    </button>
  );
}

//     ) : (
//       //the code for training/walking CTA button
//         // <Link to="/popup" state={{ type: "dog-training" }}>
//         <>
//         <button className="glowBtn"
//           style={{
//             width: "100%",
//             textAlign: "center",
//             padding: "1rem 0rem",
//             position: "fixed",
//             bottom: "0%",
//             fontFamily: "DM Sans",
//             fontStyle: "normal",
//             fontWeight: "700",
//             fontSize: "20px",
//             color: "white",
//             border: "none",
//             zIndex: "100",
//           }}
//           onClick={()=>scrollToTop()}
//         >
//           Book a FREE Session
//         </button>
//         </>

//     )

// );

export default FooterCTAButton;
