import React,{useRef,useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from './loadingSpinner/LoadingSpinner';

function PopupAdoption() {
  const PhoneRef = useRef(0);
  const NameRef = useRef("");
  const navigate=useNavigate();
  const [nameValue,setnameValue]=useState(false);
  const [nameFill,setNameFill]=useState("");
  const [phoneValue,setPhoneValue]=useState(false);
  const [phoneFill,setPhoneFill]=useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    Name: "",
    Phone: ""
  });
  const [paymentData, setPaymentData] = useState({});
  const [flag, setFlag] = useState(false);
  const baseUrl =
    "https://r6v7nfd4a8.execute-api.ap-south-1.amazonaws.com/production/api";

  const [PhoneValidation, setPhoneValidation] = useState(true);
  const [NameValidation, setNameValidation] = useState(true);
  const [bookingId, setBookingId] = useState("");
  const [token, setToken] = useState("");
  const [phone,setPhone]=useState("");
  const formHandler=()=>{
    if (
      NameRef.current.value !== "" &&
      PhoneRef.current.value.toString().length === 10 
    ) {
      setUserData({
        ...userData,
        Name: NameRef.current.value,
        Phone: phone,
      });

      //   setSelectedCarousel(3);
    } else {
      console.log("data col err");
    }
  };
  const finalFormSubmitHandler = async (flag) => {
    const elapsed = Date.now();
    const date = new Date(elapsed);
    const dateOfReg = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const timeOfReg = `${date.toLocaleTimeString("en-US")}`;

    if (
      NameRef.current.value !== "" &&
      PhoneRef.current.value.toString().length === 10
    ) {
      // console.log(userData);
      // console.log(userData.Phone)
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setIsLoading(true);
      try {
        // console.log("hey1")
        
        // console.log(phone)
        // const res = await axios.post(
        //   `${baseUrl}/serviceBooking/websiteDogGroomingBookingService`,
        //   {
        //     fullName: userData.Name,
        //     address: address,
        //     phone: phone,
        //     startDate: userData.Date,
        //     sessionTime: userData.Slot,
        //     pet:userData.Pet,
        //     latitude:lat,
        //     longitude:lon,
        //     package: {
        //       description: userData.Service,
        //       amount: userData.amount,
        //     },
        //     type: "User",
        //   },
        //   config
        // );
        //   // console.log("hey2")
        // console.log(res.data);
        // console.log(res.status);
        if (flag) {
          const data = {
            Name: userData.Name,
            ContactNo: userData.Phone
          };

          await axios
            .post(
              "https://api.apispreadsheets.com/data/gPADPdiRBvBgVWoV/",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The adoption form is being submitted...");
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the grooming form..."
              );
              setIsLoading(false);
            });
            window.dataLayer=window.dataLayer || [];
            window.dataLayer.push({
              "event":`Adoption Registration Fired From Mobile Service Page Form`
            })
          //   setShowDownloadInvoice(true);
          // displayRazorpay(
          //   userData.amount,
          //   userData.Service,
          //   dateOfReg,
          //   userData.Phone,
          //   userData.Address,
          //   res.data.bookingId
          // );
        } else {
          // console.log("hello guys")
          const data = {
            Name: userData.Name,
            ContactNo: userData.Phone,
          };

          await axios
            .post(
              "https://api.apispreadsheets.com/data/gPADPdiRBvBgVWoV/",
              data
            )
            .then((response) => {
              console.log(response);
              console.log("The adoption form is being submitted...");
              navigate('/thankPage')
            })
            .catch((err) => {
              console.log(err);
              console.log(
                "There was some error in submitting the grooming form..."
              );
            });
          // setShowDownloadInvoice(false);
          
          window.dataLayer=window.dataLayer || [];
            window.dataLayer.push({
              "event":`Adoption Registration Fired From Mobile Service Page Form`
            })
          // setNavTab(2);
        }
      } catch (error) {
        console.log("error------->",error);
      }
    } else if (NameRef.current.value === "") {
      console.log("err");
      setNameValidation(false);
    } else if (PhoneRef.current.value.toString().length !== 10) {
      console.log("err123");
      setPhoneValidation(false);
    }

    if (
      NameRef.current.value === "" &&
      PhoneRef.current.value.toString().length !== 10
    ) {
      console.log("err");
      setNameValidation(false);
      setPhoneValidation(false);
    }
  };
  return (
    <>
    <div className='second-form-section' style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",padding:"0px",width:"100%"}}>
    <div className="name" style={{width:"100%"}}>
                <div className="label">
                  <span>Enter Name</span>
                  {!NameValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div>
                  <input
                    ref={NameRef}
                    onChange={(e) => {
                      // secondFormDataHandler();
                      setNameValidation(true);
                      setnameValue(true);
                      setNameFill(e.target.value);
                      setUserData({
                        ...userData,
                        Name:e.target.value
                      })
                      console.log(e.target.value)
                    }}
                    type="text"
                    placeholder="Enter your name"
                    required
                    style={{fontSize:"17px",width:"100%"}}
                    value={nameValue?nameFill:""}
                  />
                </div>
              </div>
              <div className="phone" style={{width:"100%"}}>
                <div className="label">
                  <span>Phone number</span>
                  {!PhoneValidation ? (
                    <div className="home-page-walking-form-mobile-feedback">
                      required
                    </div>
                  ) : null}
                </div>
                <div className="number-box">
                  <input
                    ref={PhoneRef}
                    // disabled={isVerified}
                    onChange={(e) => {
                      formHandler();
                      setPhoneValidation(true);
                      // setUserData({
                      //   ...userData,
                      //   Phone:groomingPhoneRef.current.value
                      // })
                      setPhoneValue(true)
                      setPhoneFill(e.target.value)
                      setUserData({
                        ...userData,
                        Phone:e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Enter phone number"
                    required
                    style={{fontSize:"17px",width:"100%"}}
                    value={phoneValue?phoneFill:""}
                    // value={userData.Phone}
                  />
                  
                </div>
                  
              </div>
              {isLoading?(<center>
                <LoadingSpinner/>
              </center>):<div className="button-group">
              <button onClick={() => { finalFormSubmitHandler(false) }} className='pay-later'>Adopt Now!</button>
                </div>}
    </div>
    </>
  )
}

export default PopupAdoption