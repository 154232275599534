import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Input } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import background from "../images/background.png";
import iconsDesktop from "../images/trainingFooterDesktop.png"
import mobImage from "../images/mobImage.png"
import iconsMobile from "../images/trainingFooterMobile.png"


const ThankComponent = (props) => {
  const [changeView, setChangeView] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 300,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 24,
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 2,
  };

  return (
    <div className="success-animation-container">
      {!changeView ? (
        <div
          class="success-animation"
          style={{
            justifyContent: "start",
            marginTop: "-20px",
            marginBottom: "20px",
          }}
        >
          <div>
            <div
              style={{
                width: "26.8rem",
                height: "19.48rem",
                borderRadius: "1rem",
                backgroundImage: `url(${background})`,
              }}
            >
              <p className="textOverBg" style={{ color: "white" }}>
                Download our Application
              </p>
              <p className="textOverBg2" style={{ color: "white" }}>
                to get instant{" "}
                <span
                  style={{
                    backgroundColor: "rgb(106,255,112)",
                    color: "black",
                    fontWeight: "bolder",
                    fontStyle: "italic",
                  }}
                >
                  ₹1000/-
                </span>
              </p>
              <p
                className="textOverBg2"
                style={{ color: "white", paddingTop: "12px" }}
              >
                in your wallet and
              </p>
              <p className="textOverBg2" style={{ paddingTop: "12px" }}>
                <span
                  style={{
                    backgroundColor: "rgb(106,255,112)",
                    color: "black",
                    fontWeight: "bolder",
                    fontStyle: "italic",
                  }}
                >
                  FREE Pet Training E-BOOK
                </span>
              </p>
              <div
                className="bookingbutton popup-cta"
                style={{ width: "100%", paddingTop: "1rem" }}
              >
                <a
                  target="_blank" rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  style={{ width: "100%" }}
                >
                  <button className="booksession" style={{ width: "70%" }}>
                    Download Petmojo App
                  </button>
                </a>
              </div>
              <div
                className="download-btn-container"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <div className="playstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                      alt="playstore"
                      id="section2"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </div>
                <div className="appstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store.png"
                      alt="appstore"
                      id="section1"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <div className="success-info">Thank You!</div>
          <div className="success-msg">
            Your information has been sent to our pet counselor. We will reach
            you soon.{" "}
          </div>
          {/* <div className="bookingbutton popup-cta" style={{ width: "100%" }}>
            <Link to="/" style={{ width: "100%" }}>
              <button className="booksession" style={{ width: "50%" }}>
                Go Back
              </button>
            </Link>
          </div> */}

          {/* <hr
          style={{width:"20rem"}}/>
          <div
            className="success-msg"
            style={{ color: "#3F414E", fontWeight: 700 }}
          >
            Get Exclusive Free eBook on Pet Training!
          </div>
          <div className="bookingbutton popup-cta" style={{ width: "100%" }}>
            <button
              className="booksession"
              onClick={handleOpen}
              style={{
                width: "50%",
                background: "black",
                color: "rgb(222,194,105)",
              }}
            >
              Get FREE eBook Now
            </button>
          </div>
          {/* Modal for free book */}
          {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ color: "#585858", fontWeight: "2" }}
              >
                Enter your email
              </Typography>
              
              <input
                placeholder="Enter email"
                style={{
                  width: "100%",
                  height: "2rem",
                  marginTop: "0.5rem",
                  paddingTop: "1.5rem",
                  paddingBottom: "1.5rem",
                  borderColor: "#E8ECF4",
                  borderRadius: "0.5rem",
                  background: "#F7F8F9",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox defaultChecked style={{ color: "#9A9A9A" }} />
                }
                label="I agree to receive future updates from petmojo by email"
                style={{
                  fontFamily: "Lato",
                  fontSize: "10px",
                  paddingTop: "1rem",
                  color: "#3F414E",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              />
              <div
                className="bookingbutton popup-cta"
                style={{ width: "100%", paddingTop: "2rem" }}
              >
                <Link to="/" style={{ width: "100%" }}>
                  <button className="booksession" style={{ width: "100%" }}>
                    Submit
                  </button>
                </Link>
              </div>
              
            </Box>
          </Modal>  */}
          {/* end of modal */}
          {/* <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "DM Sans",
              marginBottom: "20px",
            }}
          >
            <div>Download our Application</div>
            <div>
              {" "}
              to get instant{" "}
              <span style={{ backgroundColor: "rgb(106,255,112)" }}>
                ₹1000/-
              </span>
                in your wallet
            </div>
          </div> */}

          <div style={{ width: "100%", marginTop: "20px" }}>
          <div>
                <img src={iconsMobile} style={{ width: "21rem" , marginLeft:"15px"}} />
              </div>
              
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/mobile-thankpage-image.png"
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "60px 120px",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="success-animation-desktop" style={{ width: "40%" }}>
            <div>
              <div
                style={{
                  width: "26.8rem",
                  height: "19.48rem",
                  backgroundImage: `url(${background})`,
                }}
              >
                <p className="textOverBg" style={{ color: "white" }}>
                  Download our Application
                </p>
                <p className="textOverBg2" style={{ color: "white" }}>
                  to get instant{" "}
                  <span
                    style={{
                      backgroundColor: "rgb(106,255,112)",
                      color: "black",
                      fontWeight: "bolder",
                      fontStyle: "italic",
                    }}
                  >
                    ₹1000/-
                  </span>
                </p>
                <p
                  className="textOverBg2"
                  style={{ color: "white", paddingTop: "10px" }}
                >
                  in your wallet and
                </p>
                <p className="textOverBg2" style={{ paddingTop: "10px" }}>
                  <span
                    style={{
                      backgroundColor: "rgb(106,255,112)",
                      color: "black",
                      fontWeight: "bolder",
                      fontStyle: "italic",
                    }}
                  >
                    FREE Pet Training E-BOOK
                  </span>
                </p>
                <div
                  className="bookingbutton popup-cta"
                  style={{ width: "100%", paddingTop: "1rem" }}
                >
                  <a
                    target="_blank" rel="nofollow"
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    style={{ width: "100%" }}
                  >
                    <button className="booksession" style={{ width: "70%" }}>
                      Download Petmojo App
                      
                    </button>
                  </a>
                </div>
                <div
                  className="download-btn-container"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    gap: "40px",
                  }}
                >
                  <div className="playstore-icon">
                    <a
                      target="_blank" rel="nofollow"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                        alt="playstore"
                        id="section2"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </div>
                  <div className="appstore-icon">
                    <a
                      target="_blank" rel="nofollow"
                      href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store.png"
                        alt="appstore"
                        id="section1"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <div className="success-info">Thank You!</div>
            <div className="success-msg">
              Your information has been sent to our pet counselor. <br /> We
              will reach you soon.{" "}
            </div>

            {/* <hr
          style={{width:"25rem"}}/>
              
            <div
              className="success-msg"
              style={{ color: "#3F414E", fontWeight: 700 }}
            >
              Get Exclusive Free eBook on Pet Training!
            </div>
            <div className="bookingbutton popup-cta" style={{ width: "100%" }}>
              <button
                className="booksession"
                onClick={handleOpen}
                style={{
                  width: "50%",
                  background: "black",
                  color: "rgb(222,194,105)",
                }}
              >
                Get FREE eBook Now
              </button>
            </div> */}
          </div>

          {/* Modal for free book */}
          {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ color: "#585858", fontWeight: "2" }}
              >
                Enter your email
              </Typography>
              
              <input
                placeholder="Enter email"
                style={{
                  width: "100%",
                  height: "2rem",
                  marginTop: "0.5rem",
                  paddingTop: "1.5rem",
                  paddingBottom: "1.5rem",
                  borderColor: "#E8ECF4",
                  borderRadius: "0.5rem",
                  background: "#F7F8F9",
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox defaultChecked style={{ color: "#9A9A9A" }} />
                }
                label="I agree to receive future updates from petmojo by email"
                style={{
                  fontFamily: "Lato",
                  fontSize: "10px",
                  paddingTop: "1rem",
                  color: "#3F414E",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              />
              <div
                className="bookingbutton popup-cta"
                style={{ width: "100%", paddingTop: "2rem" }}
              >
                <Link to="/" style={{ width: "100%" }}>
                  <button className="booksession" style={{ width: "100%" }}>
                    Submit
                  </button>
                </Link>
              </div>
              
            </Box>
          </Modal> */}
          {/* end of modal */}
          <div style={{ width: "60%" }}>
          <div style={{ paddingTop: "2rem"  , marginLeft:"10rem"}}>
            <img
              src={iconsDesktop}
              style={{ width: "30rem", height: "8rem" }}
            />
          </div>
          
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                justifyContent: "end",
              }}
            >
              <img
                // src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-thankpage-image.png"
                src={mobImage}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThankComponent;
