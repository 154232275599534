import { useState, useEffect } from "react";
import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import NavigationDesktop from "../components-desktop/Navigation/NavigationDesktopBlogs";
import "./Terms.css";

const Terms = (props) => {
  const [changeView, setChangeView] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  return (
    <div className="terms-master">
      {!changeView ? (
        <NavigationBar className="home-page-mobile-navigation-container" />
      ) : (
        <NavigationDesktop
          submissionType={"none"}
          submissionText={"Download Our App"}
        />
      )}
      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">TERMS AND CONDITIONS</div>
        <div className="terms-content">
          The terms “We” / “Us” / “Our”/”Company” individually and collectively
          refer to Petmojo INDIA and the terms “Visitor” ”User” refer to the
          users.
        </div>
        <div className="terms-content">
          This page states the Terms and Conditions under which you (Visitor)
          may visit this website (“https://www.petmojo.care/”). Please read this
          page carefully. If you do not accept the Terms and Conditions stated
          here, we would request you to exit this site. The business, any of its
          business divisions and/or its subsidiaries, associate companies or
          subsidiaries to subsidiaries or such other investment companies (in
          India or abroad) reserve their respective rights to revise these Terms
          and Conditions at any time by updating this posting.
        </div>
        <div className="terms-content">
          You should visit this page periodically to re-appraise yourself of the
          Terms and Conditions, because they are binding on all users of this
          Website.
        </div>

        <div className="terms-content">
          <div className="terms-content-sub">
            Vaccination and other information of pet
          </div>
          Owner certifies the accuracy of all information and ensures complete
          vaccination documented with authorized veterinary. Owner certifies
          that pet has not been exposed to rabies, distemper, Bordetella, Parvo
          virus or other contagious disease including skin diseases within 30
          days prior to boarding. Owner also acknowledges that pet is not
          suffering from life threatening disease and not on any kind of
          medication.
        </div>
        <div>
          All animals must be free of external parasites (Fleas and ticks) when
          admitted for boarding, or must be treated on arrival, at owner’s
          expense. It is also recommended that all boarding pets should be
          tested for heartworms and placed on prevention at least one month
          before boarding time.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">
            Medical Treatment and Emergencies
          </div>
          All precautions will be taken to prevent injury, escape, or other
          life-threatening emergencies. The boarding and its staff will not be
          held accountable for problems that develop. If your pet develops any
          medical condition during their stay, we reserve the right to treat as
          necessary to prevent the spread the illness. In the event that your
          pet experiences a life-threatening problem, we will make every attempt
          to contact you or any authorized person by you. If we are unable to
          contact you, we will proceed as the situation demands and contact a
          vet for further advice. I understand that all treatments will be my
          financial responsibility including Vet fees, medicines, transportation
          etc.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Female Pets</div>
          Female pets without spaying are not advised to be kept in kennel.
          Female in heat is not allowed to board. It is seen that sometimes heat
          signs and symptoms may not be that obvious and detectable on time. We
          have Non-Neutered male pets in kennels too. They play in common area
          and it is sometimes difficult to keep eye continuously on them for
          this natural behavior. Female in heat agitate male pets of same
          species. Moreover, there is no way to test female pets in early
          pregnancy and hence kennel cannot ensure that pet is already pregnant
          at the time of entry into kennel. Owner acknowledges this fact and
          cannot held kennel responsible if female pet found pregnant later on
          and free kennel from this liability.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Undesirable Behaviors</div>
          Owner specifically represents that pet is not aggressive towards other
          animals and persons unless CBT Training case and further agrees to be
          solely responsible for any and all acts or behavior of said pet with
          kennel. Owner waives and agrees to release kennel from any liability
          with respect to its pet/Animal of any kind, character, or, nature
          whatsoever, arising out of or from boarding this pet/Animal or any and
          all damages which may accrue from any other causes whatsoever
          including fire, theft, running away/escape from boarding, injury or
          death of pet/Animal or injury or death of any other animal or to a
          person whether the pet is on the premises of kennel or not.
        </div>
        <div>
          In the event of undesirable, pet behaviors, which include, but not
          limited to the following signs of aggressions and destructive
          behaviors, etc. Owner agrees that kennel may “crate” or put muzzle the
          pet/Animal. If this action does not solve the problem, kennel will
          notify owner by telephone, text or Email and owner agrees to promptly
          remove pet from kennels premises. If owner do not remove the
          pet/Animal immediately then we reserve the right to remove the pet
          immediately by way of any agency like municipal corporation or any
          NGO, thus terminating any further boarding reservation. Prepaid
          boarding fees for unused days will be refunded to owner. “Undesirable
          Behaviors” Shall be defined solely by Kennel.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">General risks in Stay</div>I
          understand that my pet may get Kennel cough (Tracheo- bronchitis) even
          if they have current vaccinations. Open play areas like garden area,
          grooming bay, bathing may result in injuries including but not limited
          to : cut, bites, scratches, abrasions, puncture wounds, injuries to
          paw pads, contraction of contagious diseases and/or parasites, stress,
          loss of appetite, behavioral issues, diarrhea, sprains, fractures,
          insect bites, Allergic reactions, weight loss, eye injuries, hot
          spots, bloat, demise, demise due to natural causes like geriatric
          death (as in due to old age) especially in old dogs who are onboarded
          for geriatric care and stay.
        </div>
        <div>
          Owner understands and agrees that boarding has lot of vast and open
          area and presence of potentially harmful and sometimes lethal pests
          against which our enclosures are ineffective (including but not
          limited to the following: centipedes, ticks, rats, snakes etc.) cannot
          be ruled out.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Abandonment of pets</div>
          If Owner does not pick up the pet within the 3 days after the day pet
          was scheduled to be picked up, shall be deemed to be abandoned. The
          person into whose custody the pet was placed for care shall try for
          new owner and if unable to find new owner, shall thereafter give it to
          NGO or Municipal corporation as abandoned pet/Animal. Kennel or
          company will not be held responsible thereafter. In any case owner is
          still responsible for all the expenses.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Boarding walls</div>
          Our pet suits are 8 feet in height and covered and our outer walls
          with barbed fencing is 6 feet in height. Owner understands that his
          pet/Animal cannot jump over this. Owner cannot hold kennel responsible
          if his pet escape in spite of this as some pets seen to jump over and
          above 8 feet in super rare events.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Photos and Videos</div>
          Photos and short videos will be uploaded once/twice every day at fix
          hrs. on what’s app group or mobile app of petmojo. You can check
          photos of your pet. Request for live video calls and directly calling
          caretaker will not be allowed as it disturbs boarding environment and
          working.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Visits of pet parents</div>
          Pet parents can visit at scheduled time with prior appointment
          (Morning 8 am to 12 pmand evening 5 pm to 8pm) so that it should not
          be disturbing to pets. Best way is to coordinate with customer care
          and fix visiting time. Unauthorized and unverified person will not be
          allowed entry. It is advisable to call from registered number only to
          allow entry.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Pick and Drop</div>
          Though pick and drop facility is provided at extra cost but we cannot
          ensure timely pick and drop due to traffic or breakdown of vehicle or
          unexpected circumstances. In that case you will be informed of
          cancellation of pick/drop. However, your booking will not cancel and
          you can pick/drop on your own. If you want to cancel your booking
          because of cancelation of pick you can apply for refund .Kindly refer
          our refund policy.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Refund Policy</div>
          Cancellation is allowed 1 day prior. And money will be refunded within
          5 to 7 working days. Kindly contact customer care. Post 1 day or
          during stay, refund is not applicable.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Charges</div>Owner agrees to pay
          boarding charges from the day of booking.
        </div>
        <div>
          Owner agrees to pay all costs and charges for special services
          requested or required e.g. special food, veterinary cost, Grooming,
          training, foreseen or un foreseen expenditure to kennel etc. during
          the time of stay.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Staff timings</div>
          We are staffed 24 hrs. a day x 365 days. There are para vets, canine
          handlers, certified trainers, certified groomers on premises at all
          times.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Your Items with pet</div>We make
          every attempt to return all items that you leave with your pet,
          however some items may be lost due to changing bedding or cleaning the
          items
        </div>
        <div>
        All matters Subject to Delhi Jurisdiction only.
Owner hereby acknowledges having read and understood the agreement and hence given consent to board his/her pet/animal and accept all financial responsibility for any and all charges incurred during your pet’s stay.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">Training</div>
          We do our best to train your pet if you have paid for training. But training needs lot of effort and time by owner as well, for pet to follow the owner’s commands. Learning in Younger pets (less than 1 year) are better than older pets. Behavior issues sometimes may remain unresolved in spite of best efforts and time by us. Every pet is different behaviorally and hence results vary. Training is something which cannot be enforced on pets as our method of training is positive way. Training is subjective and hence results cannot be guaranteed.
        </div>
        <div></div>
      </div>

      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">USE OF CONTENT</div>

        <div className="terms-content">
          All logos, brands, marks headings, labels, names, signatures,
          numerals, shapes or any combinations thereof, appearing in this site,
          except as otherwise noted, are properties either owned, or used under
          licence, by the business and / or its associate entities who feature
          on this Website/APP. The use of these properties or any other content
          on this site, except as provided in these terms and conditions or in
          the site content, is strictly prohibited.
        </div>
        <div className="terms-content">
          You may not sell or modify the content of this Website or reproduce,
          display, publicly perform, distribute, or otherwise use the materials
          in any way for any public or commercial purpose without the respective
          organisation’s or entity’s written permission.
        </div>
      </div>

      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">
          ACCEPTABLE WEBSITE USE
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">(A) Security Rules</div>
          Visitors are prohibited from violating or attempting to violate the
          security of the WEBSITE/APP/APP, including, without limitation, (1)
          accessing data not intended for such user or logging into a server or
          account which the user is not authorised to access, (2) attempting to
          probe, scan or test the vulnerability of a system or network or to
          breach security or authentication measures without proper
          authorisation, (3) attempting to interfere with service to any user,
          host or network, including, without limitation, via means of
          submitting a virus or “Trojan horse” to the Website, overloading,
          “flooding”, “mail bombing” or “crashing”, or (4) sending unsolicited
          electronic mail, including promotions and/or advertising of products
          or services. Violations of system or network security may result in
          civil or criminal liability. The business and / or its associate
          entities will have the right to investigate occurrences that they
          suspect as involving such violations and will have the right to
          involve, and cooperate with, law enforcement authorities in
          prosecuting users who are involved in such violations.
        </div>
        <div className="terms-content">
          <div className="terms-content-sub">(B) General Rules</div>
          Visitors may not use the WEBSITE/APP in order to transmit, distribute,
          store or destroy material (a) that could constitute or encourage
          conduct that would be considered a criminal offence or violate any
          applicable law or regulation, (b) in a manner that will infringe the
          copyright, trademark, trade secret or other intellectual property
          rights of others or violate the privacy or publicity of other personal
          rights of others, or (c) that is libellous, defamatory, pornographic,
          profane, obscene, threatening, abusive or hateful.
        </div>
      </div>

      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">INDEMNITY</div>
        <div className="terms-content">
          The User unilaterally agree to indemnify and hold harmless, without
          objection, the Company, its officers, directors, employees and agents
          from and against any claims, actions and/or demands and/or liabilities
          and/or losses and/or damages whatsoever arising from or resulting from
          their use of ‘https://www.petmojo.care’ or APP or their breach of the
          terms .{" "}
        </div>
      </div>

      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">LIABILITY</div>

        <div className="terms-content">
          User agrees that neither Petmojo INDIA nor its group companies,
          directors, officers or employee shall be liable for any direct or/and
          indirect or/and incidental or/and special or/and consequential or/and
          exemplary damages, resulting from the use or/and the inability to use
          the service or/and for cost of procurement of substitute goods or/and
          services or resulting from any goods or/and data or/and information
          or/and services purchased or/and obtained or/and messages received
          or/and transactions entered into through or/and from the service
          or/and resulting from unauthorized access to or/and alteration of
          user’s transmissions or/and data or/and arising from any other matter
          relating to the service, including but not limited to, damages for
          loss of profits or/and use or/and data or other intangible, even if
          Company has been advised of the possibility of such damages.
        </div>
        <div className="terms-content">
          User further agrees that Petmojo INDIA shall not be liable for any
          damages arising from interruption, suspension or termination of
          service, including but not limited to direct or/and indirect or/and
          incidental or/and special consequential or/and exemplary damages,
          whether such interruption or/and suspension or/and termination was
          justified or not, negligent or intentional, inadvertent or advertent.
          User agrees that Petmojo INDIA shall not be responsible or liable to
          user, or anyone, for the statements or conduct of any third party of
          the service. In sum, in no event shall Company’s total liability to
          the User for all damages or/and losses or/and causes of action exceed
          the amount paid by the User to Petmojo INDIA, if any, that is related
          to the cause of action.{" "}
        </div>
      </div>

      <div className="terms-section-1">
        <div className="terms-page-mobile-view-head">
          DISCLAIMER OF CONSEQUENTIAL DAMAGES
        </div>
        <div className="terms-content">
          In no event shall Petmojo INDIA or any parties, organizations or
          entities associated with the corporate brand name us or otherwise,
          mentioned at this Website be liable for any damages whatsoever
          (including, without limitations, incidental and consequential damages,
          lost profits, or damage to computer hardware or loss of data
          information or business interruption) resulting from the use or
          inability to use the Website and the Website material, whether based
          on warranty, contract, tort, or any other legal theory, and whether or
          not, such organization or entities were advised of the possibility of
          such damages. We will not be liable for the work done or any liability
          arising due to our verified service partner and you are yourself
          responsible for the same.{" "}
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Terms;
