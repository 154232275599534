import { useState, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";

import styles from "./FooterNav.module.css";

function FooterNav(props) {
  const scrollToTop = () => {
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }
  const [activePage, setActivePage] = useState("home");
  const location=useLocation().pathname
  useEffect(()=>{
    if (location === "/") {
      setActivePage("home");
    } 
    else if (location === "/dog-training") {
      setActivePage("dog-training");
    } else if(location === "/grooming") {
      setActivePage("grooming");
    }
    else if(location === "/pet-boarding"){
      setActivePage("pet-boarding")
    }
    else if (
      location === "/socials" ||
      location === "/help" ||
      location === "/more"
    ) {
      setActivePage("services");
    } 
  },[location])
  const [windowLocation, setWindowLocation] = useState(
    window.location.pathname
  );
  

  // useEffect(() => {
  //   const currLocation = window.location.pathname;
  //   setWindowLocation(window.location.pathname);

  //   if (currLocation === "/") {
  //     setActivePage("home");
  //     scrollToTop();
  //   } 
  //   else if(currLocation === "/services"){
  //     setActivePage("services");
  //     scrollToTop();
  //   }
  //   else if (currLocation === "/dog-training") {
  //     setActivePage("dog-training");
  //     scrollToTop();
  //   } else if(currLocation === "/grooming") {
  //     setActivePage("grooming");
  //     scrollToTop();
  //   }
  //   else if(currLocation === "/pet-boarding"){
  //     setActivePage("pet-boarding")
  //     scrollToTop();
  //   }

  //   else if (
  //     currLocation === "/socials" ||
  //     currLocation === "/help" ||
  //     currLocation === "/more"
  //   ) {
  //     setActivePage("services");
  //     scrollToTop();
  //   } 

  // }, [window.location.pathname, setActivePage, setWindowLocation]);


  return (
   
    (props.showNavigationBar) && (
      <div className={styles.footerNav}>
        <div className={styles.innerContainer} style={{border:"none"}}>
          <Link
            to="/"
            className={
              activePage === "home"
                ? styles.activeFooterNav_link
                : styles.footerNav_link
            }
            onClick={() => {setActivePage("home");scrollToTop()}}
          >
            <img
              src={
              activePage !== "home" ? ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8920_xotcpd.png") : (
                "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Home_wangep.png"
              )
            }
              alt="Home Link"
              className="home-link-icon"
            />
            <p style={{marginTop:"7px"}}>Home</p>
          </Link>

          <Link
            to="/dog-training"
            className={
              activePage === "dog-training"
                ? styles.activeFooterNav_link
                : styles.footerNav_link
            }
            onClick={() => {setActivePage("dog-training");scrollToTop()}}
          >
            <img
              src={activePage === "dog-training" ? ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Training+Col.png") : ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Training.png")}
              alt="Home Link"
              className="home-link-icon"
            />
            <p style={{marginTop:"7px"}}>Training</p>
          </Link>
          <Link
            to="/grooming"
            className={
              activePage === "grooming"
                ? styles.activeFooterNav_link
                : styles.footerNav_link
            }
            onClick={() => {setActivePage("grooming");scrollToTop()}}
          >
                <img
                    src={
                      activePage === "grooming" ? ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Grooming+Col.png") : ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Grooming.png")
                    }
                    alt="Social Link"
                    className="home-link-icon"
                  />         
                <p style={{marginTop:"7px"}}>Grooming</p>             
          </Link>

          <Link
            to="/pet-boarding"
            className={
              activePage === "pet-boarding"
                ? styles.activeFooterNav_link
                : styles.footerNav_link
            }
            onClick={() => {setActivePage("pet-boarding");scrollToTop()}}
          >
            <img
              src={activePage === "pet-boarding" ? ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Boarding+Col.png") : ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Boarding.png")}
              alt="More Link"
              className="home-link-icon"
              style={{padding:"4px"}}
            />
            <p style={{marginTop:"7px"}}>Pet Hotel</p>
          </Link>
        </div>

      {/* <Link
          to="/services"
          className={
            activePage === "services"
              ? styles.servicesIconActive
              : styles.servicesIcon
          }
          style={{zIndex:"1000"}}
          onClick={() => setActivePage("services")}
        >
          <img
            src={
              activePage === "services"
                ? ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_lwqwv8.png")
                : ("https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Mask_group_lwqwv8.png")
            }
            alt="Services Link"
            className="service-link-icon"
          />
        </Link> */}

      {/* <div className={styles.innerContainer} style={{border:"none"}}>


        
      </div> */}
    </div>)
  );
}

export default FooterNav;
