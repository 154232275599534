import React, { useState, useEffect, useRef } from "react";
import "./Help.css";
import LazyLoad from "react-lazyload";
import {Link} from 'react-router-dom';
import NavigationDesktop from "../components-desktop/Navigation/NavigationDesktop";
import FAQContainerTraining from "../components/container/faqContainer/FAQContainerTraining";
import {Helmet} from 'react-helmet-async';
import Accordion from "./Accordion";
import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import GoToTop from "./GoToTop";
import { BsArrowUp } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

function Help( props ) {
  const [showad, setShowad] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  // const removeAdd = () => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "none";
  //   setShowad(false);
  // };
  // setTimeout(() => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "flex";
  // }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }
  const data = [
    {
      title: "Why should we choose Petmojo?",
      content:
        "Our expert trainers have up to 20+ years of training experience. Moreover, we are super empathetic towards your furry friend using only positive reinforcement to re-model behavior. Our mission is to improve your lifelong relationship with your pet by making it more harmonious. We are not money-driven and care deeply about overall animal welfare. ",
    },
    {
      title: "Who will be my pet’s Trainer?",
      content:
        "PetMojo’s in-house trainer is experienced, reliable, and verified through a comprehensive background check. Our trainers are focused on delivering results with positive and reward-based training. ",
    },
    {
      title: "In what areas do you provide services?",
      content:
        "We provide services in Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Chandigarh, Mumbai, Bangalore, Pune, Kolkata, Chennai, and Bhopal at the moment. ",
    },
    {
      title: "What Breeds of Dogs do you Train?",
      content:
        "We have trained all breeds of dogs. You would be hard-pressed to find a breed that we have not trained – from Chihuahua to Great Dane, big or small, we train them all! ",
    },
    // {
    //   title: "What is the Cancellation and Refund Policy",
    //   content:
    //     "We will refund 100% of the remaining sessions – no questions asked. To claim the refund please contact our customer support team.",
    // },
  ];
  const [navDropdown, setNavDropdown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [changeView, setChangeView] = useState(false);

  const dropdownRef = useRef(null);

  function goToTopHandler(){
    return window.scrollTo({top: 0, left: 0, behavior:"smooth"})
  }

  const scrollToTop = () => {
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  };

  useEffect(() => {
    if(window.innerWidth < 1000){
      setChangeView(false);
    }else{
      setChangeView(true);
    }
  }, []);


  useEffect(() => {

    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);


  return (
    <div>

      <Helmet>
        <title>Help and Support | Petmojo</title>
        <meta name="description" content="We are here for you if you need any help." />
        <link rel="canonical" href="/help" />
      </Helmet>

    {
      !changeView ? (
        <div className="help">  

        <div className="help-page-mobile-view-intro-container">
          <img alt="helpbackground" className="help-page-mobile-view-background-image" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/helpmobile.webp" />
          <div className="help-page-mobile-view-intro-info-section">
          {/* {showad && (
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "60px",
            display: "none",
            gap: "5px",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-evenly",
          }}
          id="grooming-popup-ad"
        >
          <div onClick={removeAdd}>
            <RxCross2 color="#5E5E5E" />
          </div>
          <div style={{ height: "100%" }}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-ad-logo.png"
              alt="logo"
              style={{ height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "DM Sans",
              height: "100%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "700",
              }}
            >
              Petmojo
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "400",
              }}
            >
              Download our app & get <br />{" "}
              <span style={{ color: "rgba(35, 175, 0, 1)" }}>₹1000</span> in
              your Wallet{" "}
            </div>
          </div>
          <a
            href={url}
            target="_blank"
            style={{
              textDecoration: "none",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="button-group" style={{ height: "80%" }}>
              <button
                className=""
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  outline: "none",
                  border: "2px solid #DEC269",
                  color: "#DEC269",
                  fontFamily: "DM Sans",
                  backgroundColor: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                DOWNLOAD
              </button>
            </div>
          </a>
        </div>
      )} */}
            <NavigationBar page={"white"} className="home-page-mobile-navigation-container" />
            <div className="help-page-mobile-view-intro-info">
              <div className="help-page-mobile-view-header-container">
                <div className="help-page-mobile-view-header-1">Thank you for visiting Petmojo</div>
                <div className="help-page-mobile-view-header-1 pink">We are here to help</div>
              </div>

              <div className="help-page-mobile-view-intro-points-container">
                <div className='home-page-intro-mobile-view-point'>
                  <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Need help with your Booking?</div>
                </div>
                <div className='home-page-intro-mobile-view-point'>
                  <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Our experts are here to help you find the right solution.</div>
                </div>
                <div className='home-page-intro-mobile-view-point'>
                  <div><img alt="tick" className="tick-img-mobile" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Bookings, LIVE tracking, Returns, Offers we got you covered.</div>
                </div>
              </div>

              <div className="help-page-mobile-view-intro-phone-container">
                <div className="help-page-desktop-view-intro-bottom-section-container">
                  <div>Call us on</div>
                  <div>888 999 18 00</div>
                </div>
                <div className="help-page-desktop-view-intro-bottom-section-container">
                  <div>Email us on</div>
                  <div>support@petmojo.care</div>
                </div>
              </div>
 
            </div>
          </div>
        </div>
  
        <div className="textbox2 faq-title">FAQ'S</div>
        <hr className="vector2"></hr>
  
        <div className="grey-text">Most frequent Questions and Answers</div>
  
        <div className="accordion">
          {data.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
          
        <div>
              <div className="textbox2 pink-container-family">We’ll Make Your Pet Smile</div>
              <hr className="vector2"></hr>
            </div>
        <div className="heroImage1Container">
              <div className="heroImage1">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8580_hjildd.png"
                  alt="makepetsmile"
                  className="make-pet-smile"
                ></img>
                <div className='petmojo-partner-mobile-view-name'>Petmojo Partner - <span className='petmojo-partner-mobile-view-name-cursive'>Kartik Rai</span></div>
              </div>
            </div>
        <div className="pink-container" style={{ marginTop: "0px" }}>
          <div className="textbox2">Download our App Now</div>
          <hr className="vector2"></hr>
          <div className="iphone-container">
            
          <div>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/iphone-icon_cxyipc.png"
                  alt="iphoneicon"
                  className="iphone-icon"
                ></img>
              </LazyLoad>
            </div>
            <div className="download-btn-container">
              <div className="playstore-icon">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=in.tamely.user" rel="nofollow">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                      alt="playstore"
                      className="playstore"
                    ></img>
                  </LazyLoad>
                </a>
              </div>
              <div className="appstore-icon">
                <a target="_blank" href="https://apps.apple.com/in/app/petmojo/id1609861328" rel="nofollow">
                  <LazyLoad>
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                      alt="appstore"
                      className="appstore"
                    ></img>
                  </LazyLoad>
                </a>
              </div>
            </div>
            
            
          </div>
        </div>
  
        <div>
            <div className='mobile-view-footer-1'>
              <div className='mobile-view-footer-1-left'>
                <div className='mobile-view-footer-1-left-header-container'>
                  <div className='mobile-view-footer-1-header'>Need Help</div>
                  <hr className='vector2-footer'></hr>
                </div>
                <div className='mobile-view-footer-1-content-left'>
                  <div className='mobile-view-footer-phone-container'>
                    <div className='mobile-view-footer-phone-icon'><img alt="telIcon" className="mobile-view-tel-icon" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png" /></div>
                    <div className=''><a className='mobile-view-footer-phone-text' style={{textDecoration: "none"}} href="tel: +91 8889991800" target="_blank" rel="nofollow">8889991800</a></div>
                  </div>
                  <div className='mobile-view-footer-mail-container'>
                    <div className='mobile-view-footer-mail-icon'><img alt="mailIcon" className='mobile-view-mail-icon' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                    <div className=''><a className='mobile-view-footer-mail-text' style={{textDecoration: "none"}} href="mailto: support@petmojo.care" target="_blank" rel="nofollow">support@petmojo.care</a></div>
                  </div>
                </div>
              </div>
              <div className='mobile-view-footer-1-right'>
                <div className='mobile-view-footer-1-header'>Services</div>
                <hr className='vector2-footer'></hr>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/dog-running" state={{from : "help"}}>Dog Exercise/Walking</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/dog-training" state={{from : "help"}}>Dog Training</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/grooming" state={{from : "help"}}>Pet Grooming</Link></div>
                <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/pet-boarding" state={{from : "help"}}>Pet Hotel</Link></div>
                {/* <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/styling" state={{from : "help"}}>Pet Styling</Link></div> */}
              </div>
            </div>
  
            <div className='mobile-view-footer-2'>
              <div className='mobile-view-footer-2-left'>
              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                <div className="footerDetailsLink-mobile">Delhi</div>
                <div className="footerDetailsLink-mobile">Gurgaon</div>
                <div className="footerDetailsLink-mobile">Noida</div>
                <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                <div className="footerDetailsLink-mobile">Faridabad</div>
                <div className="footerDetailsLink-mobile">Jaipur</div>
                <div className="footerDetailsLink-mobile">Manesar</div>
                <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>

              </div>
              <div className='mobile-view-footer-2-right'>
                <div className='mobile-view-footer-2-right-header'>More</div>
                <hr className='vector2-footer'></hr>
                <div className="mobile-view-links-div">
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/terms"><div className='mobile-view-footer-2-tnc'>Terms and Conditions</div></Link>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/privacy"><div className='mobile-view-footer-2-tnc'>Privacy Policy</div></Link>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/partner" ><div className='mobile-view-footer-2-left-content'>Become a Partner</div></Link>              <div className='mobile-view-footer-2-left-content'>Privacy Policy - Partner</div>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/refunds" ><div className='mobile-view-footer-2-left-content'>Refund Policy</div></Link>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/about" ><div className='mobile-view-footer-2-left-content'>About Us</div></Link>
                </div>
              </div>  
            </div>
  
  <div className="mobile-footer-wrap">

            <div className='mobile-view-footer-3' style={{gap:"5px"}}>
              <div className='mobile-view-footer-3-btn1' style={{height:"33px"}}>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank">

                <img className='mobile-view-footer-appStore-icon appstore' alt="appStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png" />
                </a>
              </div>
              <div className='mobile-view-footer-3-btn2' style={{height:"33px"}}>
                <a href=" https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                <img className='mobile-view-footer-appStore-icon playstore' alt="playStore" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png" />
                </a>
              </div>
              <div onClick={() => goToTopHandler()} className='mobile-view-footer-3-btn3' style={{height:"33px",padding:"5px",display:"flex",justifyContent:"center"}}>
                {/* <div>
                  Go to the top
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                  </svg>
                </div> */}
                <span>Go to the top</span>
                <BsArrowUp/>
              </div>
              </div>
            </div>
  
            <div className='mobile-view-footer-4' style={{marginBottom:"20px"}}>
              <div>Copyright 2022 Petmojo</div>
              <div>All copyrights are reserved - any infringement will be legally pursued.</div>
              <div><br></br><br></br><br></br><br></br><br></br><br></br></div>
            </div>
  
          </div>
  
  
      </div>
      ) : (
        <div className="help-page-desktop-view-master-container">
          <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/helpwebsite.webp" alt="introImage" className="about-page-main-image-desktop" />

          <div className="help-page-desktop-view-intro-container">
            <NavigationDesktop submissionType={"none"} submissionText={"Download Our App"}/>

            <div className="help-page-desktop-view-intro-content-master">
              <div className="help-page-desktop-view-intro-head-container">
                <div className="help-page-desktop-view-intro-head">Thank you for visiting Petmojo.</div>
                <div className="help-page-desktop-view-intro-head"><span className="pink">We are here to help.</span></div>
              </div>

              <div className="help-page-desktop-view-intro-points-container">
                <div className='intro-desktop-help-page-points-p1'>
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Need help with your Booking?</div>
                </div>
                <div className='intro-desktop-help-page-points-p1'>
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Our experts are here to help you find the right solution.</div>
                </div>
                <div className='intro-desktop-help-page-points-p1'>
                  <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                  <div>Bookings, LIVE tracking, Returns, Offers we got you covered.</div>
                </div>
              </div>

              <div className="help-page-desktop-view-intro-bottom-section">
                <div className="help-page-desktop-view-intro-bottom-section-container">
                  <div>Call us on</div>
                  <div>8889991800</div>
                </div>
                <div className="help-page-desktop-view-intro-bottom-section-container">
                  <div>Email us on</div>
                  <div>support@petmojo.care</div>
                </div>
              </div>
            </div>
          </div>

          <FAQContainerTraining view={"desktop"} />

          <div className="hero-image-container-desktop">
            <div className="hero-image-container-desktop-left">
              <div className="pet-smile-home-set1">
                <div>We'll Make Your</div>
                <div>Pet Smile</div>
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
              <div className="pet-smile-home-set2">
                Your Pet Our Family!
              </div>
            </div>
            <div className="hero-image-container-desktop-right">
              <img className="pet-smile-img" alt="heroImg" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp" />
              <div className='petmojo-partner-name-desktop'>Petmojo Partner - <span className='petmojo-partner-name-desktop-cursive'>Kartik Rai</span></div>
            </div>
          </div>

          <div className="social-media-links-container">
            <div className="social-media-links-header">
              <div className="social-media-links-text">
                Connect With Us
                <div className="line-div">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
              <div className="home-page-walking-packages-desktop-container2">
                <div>Connect on applications you use</div>
                <div>daily to get Informative/Educational </div>
                <div>contents on Petmojo.</div>
              </div> 
            </div>
  
            <div className="social-media-links-image">
              <div className="ellipse-container">
                <div className="ellipse-desktop">
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                    alt="outerring"
                    className="outer-ring-desktop"
                  ></img>
                  {/* </LazyLoad> */}
                  {/* <LazyLoad> */}
                  <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"} alt="innerring" className="inner-ring-desktop"></img>
                  {/* </LazyLoad> */}
                  <button className="youtube-icon-desktop">
                    <a
                      href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                        alt="youtubeicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="facebook-icon-desktop">
                    <a
                      href="https://www.facebook.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                        alt="facebookicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  
                  <button className="instagram-icon-desktop">
                    <a
                      href="https://www.instagram.com/petmojo.care/"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                        alt="instagramicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="phone-icon-desktop">
                    <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                        alt="phoneicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="gmail-icon-desktop">
                    <a
                      href="mailto: support@petmojo.care"
                      target="_blank"
                      rel="nofollow"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                        alt="gmailicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                  <button className="whatsapp-icon-desktop">
                    <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                        alt="whatsappicon"
                        width="60px"
                        height="60px"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div><img alt="icon" className="icon-desktop-call" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png" /></div>
                <div><a className="footerDetailsLink" target="_blank" href="tel: 8889991800" rel="nofollow">8889991800</a></div>
              </div>

              <div className="footer-details-content-call-mail">
                <div><img alt="icon" className="icon-desktop-mail" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png" /></div>
                <div><a className="footerDetailsLink" target="_blank" rel="nofollow" href="mailto: support@petmojo.care">support@petmojo.care</a></div>
              </div>
              
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/dog-running" state={{from : "help"}}><div>Daily Dog Exercise/Walking</div></Link>
              <Link className="footerDetailsLink" to="/dog-training" state={{from : "help"}}><div>Dog Training</div></Link>
              <Link className="footerDetailsLink" to="/cat-training" state={{from : "help"}}><div>Cat Training</div></Link>
              <Link className="footerDetailsLink" to="/grooming" state={{from : "help"}}><div>Pet Grooming</div></Link>
              <Link className="footerDetailsLink" to="/grooming-subscription" state={{from : "help"}}><div>Grooming Subscription</div></Link>
              <Link className="footerDetailsLink" to="/pet-boarding" state={{from : "help"}}><div>Pet Hotel</div></Link>
              {/* <Link className="footerDetailsLink" to="/styling" state={{from : "help"}}><div>Pet Styling</div></Link> */}
            </div>
          </div>
          <div className='footerDetailsContr1'>
              <div className="footerDetailsHeading">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <div className="footerDetailsLink">Delhi</div>
                <div className="footerDetailsLink">Gurgaon</div>
                <div className="footerDetailsLink">Noida</div>
                <div className="footerDetailsLink">Ghaziabad</div>
                <div className="footerDetailsLink">Faridabad</div>
                <div className="footerDetailsLink">Jaipur</div>
                <div className="footerDetailsLink">Manesar</div>
                <div className="footerDetailsLink">Mumbai</div>
              </div>
            </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner"><div>Become a Partner</div></Link>
              <Link className="footerDetailsLink" to="/terms"><div>Terms and Conditions</div></Link>
              <Link className="footerDetailsLink" to="/privacy"><div>Privacy Policy</div></Link>
              <Link className="footerDetailsLink" to="/"><div>Privacy Policy - Partner</div></Link>
              <Link className="footerDetailsLink" to="/refunds"><div>Refund Policy</div></Link>
              <Link className="footerDetailsLink" to="/about"><div>About Us</div></Link>
            </div>
          </div>
        </div>

<div>
<div className="desktop-footer-buttons-container-add" style={{display:"flex", flexDirection:"column"}}>
              <p style={{fontWeight:"800"}}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
              <p style={{marginTop:"-1rem"}}> Ph no- +918889991800</p>
            </div>

        <div className="desktop-footer-buttons-container">
                <div className="desktop-footer-buttons-left">
                  <div className="desktop-footer-buttons-left-btn1">
                    <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank">

                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" rel="nofollow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png" />
                    </a>
                  </div>
                  <div className="desktop-footer-buttons-left-btn1">
                    <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank">

                    <img alt="icon" className="desktop-footer-buttons-left-btn1-img" rel="nofollow" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png" />
                    </a>
                  </div>
                  <div onClick={() => scrollToTop()} className="desktop-footer-buttons-left-btn3">
                    <div>Go at the top</div>
                    
                      <svg className="top-arrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                      </svg>
                    
                  </div>
                </div>

                <div className="desktop-footer-buttons-right">
                  Copyright 2022 Petmojo
                </div>
              </div>
              </div>


        </div>
      )
    }

    <GoToTop/>

  </div>

  );
}

export default Help;
