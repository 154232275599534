import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./OrderSummary.css";
import download from "../images/download.png";
import downloadinvoice from "../images/download-invoice.png";
import groomingvector from "../images/DogWalking.png";
import background from "../images/background.png"
import blackLogo from "../images/BlackLogo.png"

const OrderSummary = (props) => {
  const location = useLocation();

  const [changeView, setChangeView] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const [copyMessage, setCopyMessage] = useState("");
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);

  function copyToClipboard(text) {
    text.select();
    document.execCommand("copy");
    text.focus();
    setCopySuccess("Copied!");
  }

  function copiedMessage() {
    setCopyMessage("Copied!");

    setTimeout(() => {
      setCopyMessage("");
    }, 2000);
  }

  return (
    <div>
      {changeView ? (
        <div className="order-summary-desktop-master-container">
          <div className="order-summary-desktop-master-container-bottom">
            <div className="order-summary-desktop-grey-container">
              <div className="order-summary-desktop-grey-left">
                <div className="order-summary-desktop-grey-left-order">
                  Order Successfully Placed:
                </div>
                <div className="order-summary-desktop-grey-left-order-heading">
                  {location.state?.SelectedService}
                </div>
                <div className="order-summary-desktop-grey-left-order-details-container">
                  <div>Number of pets: 1</div>
                  <div>Date: {location.state?.DateOfReg}</div>
                  <div>
                    Order Confirmation Number: {location.state?.orderId}
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${location.state?.orderId}`
                        );
                        copiedMessage();
                      }}
                    >
                      <img
                        alt="copy"
                        className="copy-icon-desktop"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/copy-icon-desktop.png"
                      />
                    </span>
                    <span className="copy-popup-text">{copyMessage}</span>
                  </div>
                </div>
              </div>
              {/* <div className="order-summary-desktop-grey-right">
                <img
                  src={downloadinvoice}
                  alt="dog-image"
                  width={100}
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    top: "-20px",
                  }}
                />
              </div> */}
            </div>

            <div className="order-summary-desktop-white-container">
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  // marginTop: "1px",
                  // padding: "15px",
                }}
              >
                <div className="order-summary-desktop-white-container-left">
                  <div >Billing Address:</div>
                  <div className="order-summary-desktop-white-container-left-address">
                    {location.state?.InputAddress}
                  </div>
                </div>
              </div>
              <div className="order-summary-desktop-white-container-right">
                <div style={{paddingLeft:"33rem", width:"100%"}}>
                  <img
                    src={groomingvector}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>

            <div className="lowerSum">
              <div className="leftLowerSum" style={{paddingLeft:"2rem"}}>
                <div
                  className="order-summary-desktop-white-container-left-download-button"
                  style={{ width: "100%",paddingLeft:"2rem" }}
                >
                  Download Invoice
                </div>
              </div>
              <div className="midLowerSum">
                <div style={{paddingLeft:"12rem", paddingTop:"1rem"}}>
                  <img
                    alt="tickImage"
                    className="order-confirmation-mobile-summary-image"
                    
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
                  />
                </div>
              </div>
              <div className="rightLowerSum">
                <div className="order-confirmation-mobile-summary-container">
                  <div className="order-confirmation-mobile-summary-text">
                    Your information has been sent to our pet counselor,
                    Download our Mobile app and start tracking immediately and
                    connect with the walker.
                  </div>
                </div>
              </div>
            </div>

            
            {/* <div className="order-confirmation-mobile-summary-container">
              <div className="order-confirmation-mobile-summary-text">
                Your information has been sent to our pet counselor, Download
                our Mobile app and start tracking immediately and connect with
                the walker.
              </div>
            </div>

            
            <div
              className="order-summary-desktop-white-container-left-download-button"
              style={{ width: "20%" }}
            >
              Download Receipt
            </div>
            
            <center>
              <div>
                <img
                  alt="tickImage"
                  className="order-confirmation-mobile-summary-image"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
                />
              </div>
            </center> */}

            {/* <div className="order-summary-mobile-footer-container">
            <div>
              <img
                alt="petmojoLogo"
                className="order-summary-mobile-footer-logo"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
              />
            </div>
            <div className="order-summary-mobile-footer-text">
              Copyright 2022 Petmojo
            </div>
            <div className="order-summary-mobile-footer-text">
              All copyrights are reserved - any infringement will be legally
              pursued
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div> */}
          </div>
        </div>
      ) : (
          <div className="order-summary-mobile-master-container" style={{ justifyContent: "start", marginTop: "-10px", marginBottom:"20px" }}>
            <div className="background" style={{
                  width: "100%",
                height: "11.48rem",
                  
                  backgroundImage: `url(${background})`,
            }}>
              <div
                  className="bookingbutton popup-cta"
                  style={{ width: "100%", paddingTop: "2.5rem" }}
                >
                  <a target="_blank"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user" rel="nofollow" style={{ width: "100%" }}>
                    <button className="booksession" style={{ width: "70%" }}>
                      Download Petmojo App
                    </button>
                  </a>
              </div>
              <div
                  className="download-btn-container"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    gap: "40px",
                  }}
                >
                  <div className="playstore-icon">
                    <a
                      target="_blank" rel="nofollow"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                        alt="playstore"
                        id="section2"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </div>
                  <div className="appstore-icon">
                    <a 
                      target="_blank" rel="nofollow"
                      href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    >
                      {/* <LazyLoad> */}
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store.png"
                        alt="appstore"
                        id="section1"
                      ></img>
                      {/* </LazyLoad> */}
                    </a>
                  </div>
                </div>

            </div>
          <div className="order-summary-mobile-grey-container">
            <div>Order Successfully Placed:</div>
            <div className="order-summary-mobile-grey-heading">
              {location.state?.SelectedService}
            </div>
            <div className="order-summary-mobile-grey-container-details-container">
              <div>
                <div>Number of pets: 1</div>
                <div>Date: {location.state?.DateOfReg}</div>
              </div>

              <div>
                <img
                  src={downloadinvoice}
                  width={60}
                  style={{
                    objectFit: "contain",
                    position: "relative",
                    top: "-20px",
                  }}
                />
              </div>
            </div>
            <div className="order-confirmation-mobile-container">
              Order Confirmation Number: {location.state?.orderId}
              <span
                onClick={() => {
                  navigator.clipboard.writeText(`${location.state?.orderId}`);
                  copiedMessage();
                }}
              >
                <img
                  alt="copy"
                  className="copy-icon-mobile"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/copy-icon-desktop.png"
                />
              </span>
              <span className="copy-popup-text">{copyMessage}</span>
              </div>
              <div className="order-summary-mobile-white-container">
              <div className="billing-address-heading">Billing Address:</div>
              <div className="billing-address-ad">
                {location.state?.InputAddress}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "15px",
            }}
          >
            
            <div
              className="groomingVector"
              style={{ objectFit: "contain", width: "20rem", height: "10rem" }}
            >
              <img src={groomingvector} alt="" />
            </div>
          </div>

            
          {/* <div className="lowerSum">
              <div className="leftLowerSum">
                <div
                  className="order-summary-desktop-white-container-left-download-button"
                  style={{ width: "100%" }}
                >
                  Download Receipt
                </div>
              </div>
              <div className="midLowerSum">
                <div style={{paddingLeft:"12rem", paddingTop:"1rem"}}>
                  <img
                    alt="tickImage"
                    className="order-confirmation-mobile-summary-image"
                    
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
                  />
                </div>
              </div>
              <div className="rightLowerSum">
                <div className="order-confirmation-mobile-summary-container">
                  <div className="order-confirmation-mobile-summary-text">
                    Your information has been sent to our pet counselor,
                    Download our Mobile app and start tracking immediately and
                    connect with the walker.
                  </div>
                </div>
              </div>
            </div> */}
          {/* <div className="order-confirmation-mobile-summary-container">
            <div>
              <img
                alt="tickImage"
                className="order-confirmation-mobile-summary-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/tick-order-summary-mobile.png"
              />
            </div>
            <div className="order-confirmation-mobile-summary-text">
              Your information has been sent to our pet counselor, We will reach
              you soon.
            </div>
          </div>

          <div className="order-summary-mobile-download-button">
            Download Receipt
          </div> */}

          <div className="order-summary-mobile-footer-container">
            <div>
              <img
                alt="petmojoLogo"
                  className="order-summary-mobile-footer-logo"
                  src={blackLogo}
                  style={{width:"7rem"}}
                // src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-order-summary-mobile.png"
              />
            </div>
            <div className="order-summary-mobile-footer-text">
              Copyright 2022 Petmojo
            </div>
            <div className="order-summary-mobile-footer-text">
              All copyrights are reserved - any infringement will be legally
              pursued
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
