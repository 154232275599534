import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import BoardingPageForm from './BoardingPageForm';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer({ introImage }) {

  return (
        <div className="intro-desktop-home-page-master-container" style={{backgroundColor:"#F8F8F8"}}>
           <div className='wrapper-class-intro-desktop-video' style={{width:"100%",position:"absolute"}}>
              {/* <video  role="presentation" autoPlay loop muted style={{width:"100vw",height:"100vh",objectFit:"cover"}}>
                <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boarding-vid.mp4" type="video/mp4" style={{width:"100%"}}>
                </source>
              </video> */}
              <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boardingwebsite.webp" alt="" style={{width:"100vw",height:"100vh",objectFit:"cover"}}/>
              </div>

            <div className='intro-desktop-home-page-content-container' style={{position:"relative"}}>
              <NavigationDesktop submissionType={"none"} submissionText={"Book Now!"}  />

              <div className='intro-desktop-head-combiner-boarding' style={{marginTop:"0px"}}>
                <div className='intro-desktop-home-page-header'>
                  <div className='intro-desktop-home-page-header1'><h1 className='home-page-desktop-heading' style={{flexDirection:"row"}}>India's&nbsp;<span className='pink-bold'> Largest & Trusted </span>&nbsp;pet hotel chain</h1></div>
                  {/* India's Largest & Trusted pet hotel chain */}
                </div>
                <div className='intro-desktop-boarding-page-points-container'>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Get live updates twice a day (Morning & Evening)</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Pickup & Drop Facility Available for Rabbit, Guinea Pigs, Cats & Dogs</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Personalised Care with Nutritious Meals & Exercise</div>
                  </div>
                  <div className='intro-desktop-home-page-points-p1'>
                    <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                    <div>Safety Guarenteed (11 feet fencing)</div>
                  </div>
                </div>
                <BoardingPageForm />
              </div>
            </div>
        </div>
  );
}

export default IntroContainer;
