import React, { useState, useEffect } from "react";
import NavigationBar from "../Navigation/navigationBar/NavigationBar";
import { Helmet } from "react-helmet-async";
import NavigationDesktopBlogs from "../../components-desktop/Navigation/NavigationDesktopBlogs";
import "./refundsPage.css";

const RefundsPage = () => {
  const [windowInnerWidth, setWindowInnerWidth] = useState("");
  const [changeView, setChangeView] = useState(false);

  
  const scrollToTop = () => {
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }

  function goToTopHandler(){
    return window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  }


  useEffect(() => {
    setWindowInnerWidth(window.innerWidth);
    window &&
      window.addEventListener("resize", (data) => {
        setWindowInnerWidth(data.target.screen.width);
      });
  }, []);

  
  useEffect(() => {
    if(window.innerWidth <= 1000){
      setChangeView(false);
    }else{
      setChangeView(true);
    }
  }, []);

  return (
    <>
    <div className="">
      <Helmet>
        <title>Book the most trusted Pet Hotel Service - Petmojo</title>
        <meta
          name="description"
          content="Pet Hotel - Going away for a vacation? But worried about your dog while you're away? Look no further, we are here to make sure all the needs of your pet are met"
        />
      </Helmet>
      {windowInnerWidth > 1150 ? (
        <NavigationDesktopBlogs
          submissionType={"none"}
          submissionText={"Download Our App"}
        />
      ) : (
        <NavigationBar />
      )}
{/* 

The first 24 sessions are non refundable in all services. E.g., if A Service opted for 48 sessions where 24 sessions have been delivered, the refund will be provided only for the remaining 24 sessions.
The refund amount will be made via Bank Transfer to the payee only
Petmojo employees may verify your refund claims/ perform an internal check before the refund is sanctioned
Once a refund request is raised it will take 14-21 days to process & sanction your refund claim */}
      <div className="refunds_text_wrapper">
        <div className="refunds_div">
          <h1 className="refunds_heading">REFUND POLICIES</h1>
          <p className="refunds_gray_text">- Last updated 20 December 2022</p>
          <p className="refunds_text">
          We are a mission-driven organization, and we want to deliver only the best services to our subscribers. In case you are unhappy with our services, the following policies are in place to ensure a safe refund of your paid amount.
          </p>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            Refund Policy is only applicable for 4 months & 6 months dog training packages that are fully paid (and not partially paid). The first 24 sessions are non refundable in all services. There is no refund for one, two or three months training or dog walking or pet hotel services.
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            Refunds can only be acknowledged when raised as a request on our dedicated email channel customercare@petmojo.care
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            Refunds are to be claimed only by the person who opted for the services
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            The first 24 sessions are non refundable in all services. E.g., if A Service opted for 48 sessions where 24 sessions have been delivered, the refund will be provided only for the remaining 24 sessions.
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            The refund amount will be made via Bank Transfer to the payee only
            </p>
          </div>
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
            Petmojo employees may verify your refund claims/ perform an internal check before the refund is sanctioned
            </p>
          </div>
          {/* <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Petmojo employees may verify your refund claims/ perform an
              internal check before the refund is sanctioned
            </p>
          </div> */}
          <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Once a refund request is raised it will take 14-21 days to process
              & sanction your refund claim
            </p>
          </div>
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
            HOW TO RAISE A REFUND CLAIM
          </h1>
          <p className="refunds_text">
            1. {"  "} To initiate a refund claim, you (subscriber) must write an
            email to customercare@petmojo.care with the claim details along with
            your registered mobile number. This is the only correct channel to
            process your refund. Any other escalation will not be considered
            valid.
          </p>
          <p className="refunds_text">
            2. {"  "} Once we have received your email, upon internal checks we
            will reach you via email to collect details of your payment,
            services, dissatisfaction & claim settlement bank details.
          </p>
          <p className="refunds_text">
            3. {"  "} Internal checks & verification can take up to 72 hours.
          </p>
          <p className="refunds_text">
            4. {"  "} If your refund claim adjudication is passed, we will
            sanction the final amount and will make a bank transfer to the
            details provided. This can take up to 7-14 days.
          </p>
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
            EXCEPTIONS
          </h1>
          <p className="refunds_text">
            1. {"  "} Refunds are not applicable for All Dog Training services equal to & below 36 sessions.
          </p>
          <p className="refunds_text">
            2. {"  "} Refunds are not applicable on Grooming & Pet Hotel
            Services.
          </p>
          <p className="refunds_text">
            3. {"  "} Refunds are not available for Dog Walking services where
            only one month's service payment is done.
          </p>
        </div>

        <div className="refunds_div">
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
            Please Note
          </h1>
          <p className="refunds_text">
            1. {"  "} Repeat Claims will not escalate your case faster. We
            appreciate your cooperation during this period.
          </p>
          <p className="refunds_text">
            2. {"  "} Refunds are not eligible where payment is directly made to
            the training partner & not to the Payment ways (Online, Bank
            Deposits, etc.) provided by Petmojo officials. We strongly
            discourage cash & cheque payments.
          </p>
          <p className="refunds_text">
            3. {"  "} Your refund claim will attract a 3% deduction for Bank
            Transfer fees, accounting fees, and Payment Gateway charges in
            total.
          </p>
        </div>

        <div className="refunds_div" style={{marginBottom:"100px"}}>
          <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
            QUESTIONS
          </h1>
          <p className="refunds_text">
            If you have any questions concerning our refund policy, please
            contact us at:
          </p>
          <p className="refunds_text">
            +91 900 900 4473 or Write us at customercare@petmojo.care
          </p>
        </div>
      </div>
      </div>

      </>
  );
};

export default RefundsPage;
