import classes from './blog1.module.css'; // importing the css classes from module file
import { useLocation } from 'react-router-dom'; // importing the useLocation hook 
import NavigationBarBlogDetail from '../components/Navigation/navigationBar/NavigationBarBlogDetail';
import { Link } from 'react-router-dom';   
import {useState} from 'react'; 

const Blog1 = (props) => {

    const location = useLocation();
    const { blogInfo } = location.state;

    const [fullView, setFullView] = useState(false);


    const readMoreHandler = () => {
        setFullView(!fullView);
    };

    const imageArray = [
        {
            source: "https://res.cloudinary.com/tamely-app/image/upload/v1661012701/petmojo-website/Rectangle_1195_srnyf3.png",
            tag: 1,
            tagline: "Road Tripping With Your Pet - What to Pack?"
        },
        {
            source: "https://res.cloudinary.com/tamely-app/image/upload/v1660560993/petmojo-website/Rectangle_1186_nsoic4.png",
            tag: 2,
            tagline: "Positive Pet Rewards"
        },
        {
            source: "https://res.cloudinary.com/tamely-app/image/upload/v1660478977/petmojo-website/Rectangle_1168_yqe8p5.png",
            tag: 3,
            tagline: "Pet Separation Anxiety"
        },
        {
            source: "https://res.cloudinary.com/tamely-app/image/upload/v1660408844/petmojo-website/Rectangle_1141_zahzor.png",
            tag: 4,
            tagline: "Guide to Pet Foods"
        },
        {
            source: "https://res.cloudinary.com/tamely-app/image/upload/v1660479194/petmojo-website/Rectangle_1140_qvcv1b.png",
            tag: 5,
            tagline: "Puppy and Teething"
        }      
    ];


    const imageClass = "imageContainer"

    return (
        <div className={classes.outerContainer}>
            <NavigationBarBlogDetail />
            <div className={classes.imageContainer}>
                <img className={classes.image} src={fullView ? blogInfo.imageSource2 : blogInfo.imageSource} >
                </img>
                <div className={classes.readAllContainer}>
                    {
                        fullView ? null : (
                            <div onClick={readMoreHandler} className={classes.readMoreBtn}>
                                Read All
                            </div>
                        )
                    }        
                </div>  
            </div>
            <div className={classes.overview}>
                <div className={fullView ? classes.fullOverviewTitle : classes.overviewTitle}>{blogInfo.title}</div>
                <div className={classes.overviewDate}>{blogInfo.date}</div>
                <div className={classes.overviewContent}>{fullView ? blogInfo.fullContent : blogInfo.content} &nbsp;
                    {fullView ? null : (
                        <span onClick={readMoreHandler} style={{color: "black", fontWeight: "600"}}>Continue Reading
                        <svg className={classes.overviewArrow} xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg></span>
                    )}
                    
                </div>
            </div>

            {
                !fullView ? null : (
                    <div className={classes.similarBlogsContainer}>
                        <div className={classes.similarBlogsHeading}>
                            Read Similar Blogs
                        </div>
                        <hr className={classes.underline}></hr>
                    </div>
                )
            }

            

            {
                !fullView ? null : (
                    <div className={classes.sliderContainer}>
                        <div className={classes.slider}>
                            {
                                imageArray.map((item, index) => {
                                    if(item.tag !== blogInfo.tag){
                                    return (<div className={classes.item}>
                                                <img 
                                                    src={item.source}
                                                    alt={`imageItem${index+1}`}
                                                    className={classes.cardImg}
                                                />
                                                <div className={classes.cardText}>
                                                    {item.tagline}
                                                </div>
                                        </div>)
                                    }
                                })
                            }
                        </div>
                    </div>
                )
            }

            

        </div>
    );
};

export default Blog1;