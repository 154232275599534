import {useEffect, useState} from 'react';
import LazyLoad from "react-lazyload";
import TrainingPageForm from './TrainingPageForm';
import {Link} from 'react-router-dom';
import NavigationDesktop from '../../../components-desktop/Navigation/NavigationDesktop';

function IntroContainer(props) {

  return (
        <div className="intro-desktop-home-page-master-container">
            <img className="socials-page-desktop-background-image" alt="backdrop" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Rectangle_1273_rvjbpx.png" />

            <div className='intro-desktop-home-page-content-container'>
                <NavigationDesktop submissionType={"dog-training"} submissionText={"Download Our App"}/>
                <div className='socials-page-intro-master'> 
                    <div className='socials-page-intro-left'>
                        <div className='socials-page-intro-left-1'>
                            <div>World's Best <span className='pink'>Pets Social Media App!</span></div>
                            <div><span className='pink'>16000+</span> Pet Parents</div>
                        </div>
                        <div className='socials-page-intro-left-2'>
                            <div className='intro-desktop-social-page-points-p1'>
                                <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                                <div>Go through our educational content </div>
                            </div>
                            <div className='intro-desktop-social-page-points-p1'>
                                <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                                <div>We have a supportive community</div>
                            </div>
                            <div className='intro-desktop-social-page-points-p1'>
                                <div><img alt="tick" className="tick-img" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png" /></div>
                                <div>Discover Pet services that can make your life easier.</div>
                            </div>
                        </div>
                        <div className='socials-page-intro-left-3'>
                            <div>
                                <a href="https://play.google.com/store/apps/details?id=in.tamely.user">
                                    <button className="booksession-navigation-desktop">Download Our App</button>
                                </a>
                            </div>
                            <div className='socials-page-desktop-disc'>Available on Play Store and App Store</div>
                        </div>
                    </div>
                    <div className='socials-page-intro-right'>
                        <img alt="phoneImage" className='socials-page-intro-right-container' src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/iPhone-13-Pro-Front_hdfame.png" />
                    </div>
                </div>
            </div>
        </div>
  );
}

export default IntroContainer;
