import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./CatTraining.css";
import LazyLoad from "react-lazyload";
import { BsArrowUp } from "react-icons/bs";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet-async";
import TimelineComponent from "./TimelineComponent";
import HeartContainer from "../components/container/heartContainer/HeartContainer";
import classes from "../pages/Landing/LandingPage.module.css";
import FAQContainerTraining from "../components/container/faqContainer/FAQContainerTraining";
import { useLocation } from "react-router-dom";
import IntroContainerDesktopTraining from "./container/introContainer/IntroContainerDesktopCatTraining";
import GoToTop from "./GoToTop";
import IntroContainerMobileTraining from "./container/introContainer/IntroContainerMobileCatTraining";
import Slider from "react-rangeslider";
import Loader from 'react-dots-loader'
import { BsChevronRight,BsChevronLeft } from "react-icons/bs";

const timelineData = [
  {
    title: "Book a FREE Session",
    text: "In this session, we will assess your pet for FREE. ",
    routeText: "Book Now",
    route: "/popup",
    type: "dog-training",
  },
  {
    title: "Select Your Package",
    text: "Help us understand your pain points, purpose of training and we will customize a curriculum to welcome your pet in our family. ",
    route: "/services",
  },
  {
    title: "Pay & Start Immediately",
    text: "Pay through our secure petmojo App/Payment link or through our Mobile Application. ",
    link: "https://play.google.com/store/apps/details?id=in.tamely.user",
  },
];

function  Training() {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Daily Pet Exercise/Walking");
  const options = [
    "Daily Dog Exercise/Walking",
    "Daily Dog Training",
    "Daily Pet Walking",
    "Daily Pet Grooming",
  ];

  const [open, setOpen] = useState(false);
  

  const [trainer, setTrainer] = useState("Pro Trainers");
  const [changeView, setChangeView] = useState(false);

  const [dropdown, setDropdown] = useState(false); // setting up the initial value of dropdown to be false

  const [popup, setPopup] = useState(false);
  const [option, setOption] = useState("pro");
  const [navDropdown, setNavDropdown] = useState(true);
  const dropdownRef = useRef(null);
  function rightScroll(){
    console.log('right scroll')
    const right=document.querySelector('.slider-training-packages');
    right.scrollBy(200,0);
  }
  function leftScroll(){
    console.log('right scroll')
    const right=document.querySelector('.slider-training-packages');
    right.scrollBy(-200,0);
  }
  const location = useLocation();
  const [value1, setValue] = useState(10);
  const [value2, setValue2] = useState(10);
  const [valueMobile, setValueMobile] = useState(10);
  const [valueMobile2, setValueMobile2] = useState(10);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  const [scroll,setScroll]=useState(true);
  const [scroll1,setScroll1]=useState(true);
  const [scroll2,setScroll2]=useState(true);
  const [scroll3,setScroll3]=useState(true);
  // const [section2,setSection2]=useState(false);
  // const [sectionArray,setSectionArray]=useState([
  //   Array(7).fill(false)
  // ])
  const [section1,setSection1]=useState(false);
  const [section2,setSection2]=useState(false);
  const [section3,setSection3]=useState(false);
  const [section4,setSection4]=useState(false);
  const [section5,setSection5]=useState(false);
  const [section6,setSection6]=useState(false);
  const [section7,setSection7]=useState(false);
  const [section8,setSection8]=useState(false);
  const [section9,setSection9]=useState(false);
  const [section10,setSection10]=useState(false);
  const [section11,setSection11]=useState(false);
  const [section12,setSection12]=useState(false);
  // const [section7,setSection7]=useState(false);
  
  // console.log(section1);
  // const mySectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1=document.getElementById('section1');
          const ele2=document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1=document.getElementById('section3');
          const ele2=document.getElementById('section4');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1=document.getElementById('section5');
          const ele2=document.getElementById('section6');
          
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionThreeRef.current) {
      observer.observe(sectionThreeRef.current);
    }
    return () => {
      if (sectionThreeRef.current) {
        observer.unobserve(sectionThreeRef.current);
      }
    };
  }, []);

  const handleChange = (value) => {
    setValue(value);
    setScroll(false);
    const scrollableDiv = document.getElementById('scrollable-div');
  scrollableDiv.scrollLeft = (value1 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    setScroll1(false);
    const scrollableDiv = document.getElementById('scrollable-div-mobile');
  scrollableDiv.scrollLeft = (valueMobile / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile2 = (value) => {
    setValueMobile2(value);
    setScroll3(false);
    const scrollableDiv = document.getElementById('scrollable-div2-mobile');
  scrollableDiv.scrollLeft = (valueMobile2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChange2 = (value) => {
    setValue2(value);
    setScroll2(false);
    const scrollableDiv = document.getElementById('scrollable-div2');
  scrollableDiv.scrollLeft = (value2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);


  
  useEffect(()=>{
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    
    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": "Rekha Saxena"
      },
      "itemReviewed": {
        "@type": "LocalBusiness",
        "name": "Petmojo Training Service",
        "address": "New Delhi, India",
        "image": "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "ratingCount": "1382"
        }
      },
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4.8",
        "reviewAspect": ""
      },
      "publisher": {
        "@type": "Person",
        "name": "Lalita Pandit"
      },
      "reviewBody": "Training your dog with Petmojo is an experience totally worth it. I recommend all Pet owners to try their training service at least once. And once you have tried, you will definitely keep going back to them."
    }
    )
    

    script2.type = "application/ld+json";
    script2.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Why should we choose Petmojo?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our expert trainers have up to 20+ years of training experience. Moreover, we are super empathetic towards your furry friend using only positive reinforcement to re-model behavior. Our mission is to improve your lifelong relationship with your pet by making it more harmonious. We care deeply about overall animal welfare."
          }
        },
        {
          "@type": "Question",
          "name": "Who will be my pet’s Trainer?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "PetMojo’s in-house trainers are experienced, reliable, and verified through a comprehensive background check. Our trainers are focused on delivering results with positive and reward-based training."
          }
        },
        {
          "@type": "Question",
          "name": "In what areas do you provide services?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "We provide services in Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Jaipur, Chandigarh, Mumbai, Bangalore, Pune, Kolkata, Chennai, and Bhopal at the moment."
          }
        },
        {
          "@type": "Question",
          "name": "What Breeds of Dogs do you Train?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "We have trained all breeds of dogs. You would be hard-pressed to find a breed that we have not trained – from Chihuahua to Great Dane, big or small, we train them all!"
          }
        },
        // {
        //   "@type": "Question",
        //   "name": "What is the Cancellation and Refund Policy?",
        //   "acceptedAnswer": {
        //     "@type": "Answer",
        //     "text": "We will refund 100% of the remaining sessions – no questions asked. To claim the refund please contact our customer support team."
        //   }
        // }
      ]
    }
    
    
      )
      script1.id="training-page1";
      script2.id="training-page2";
      document.body.appendChild(script1);
      document.body.appendChild(script2);

      return(()=>{
        document.getElementById("training-page1").remove();
        document.getElementById("training-page2").remove();
      })


  },[]);


  useEffect(() => {
    document.head.innerHTML += `
    <meta id="training-1" property="og:title" content="Get Professional Dog Training at home - Petmojo"/>
<meta id="training-2" property="og:site_name" content="Petmojo"/>
<meta id="training-3" property="og:url" content="https://petmojo.care"/>
<meta id="training-4" property="og:description" content="Worried about teaching your dog basic mannerisms? Petmojo comes to your rescue with dog training services by experienced professionals."/>
<meta id="training-5" property="og:type" content="website"/>
<meta id="training-6" property="og:image" content="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png"/>
<meta id="twitter-training-1" property="twitter:card" content="summary_large_image">
<meta id="twitter-training-2" property="twitter:url" content="https://petmojo.care/dog-training">
<meta id="twitter-training-3" property="twitter:title" content="Get Professional Dog Training at home - Petmojo">
<meta id="twitter-training-4" property="twitter:description" content="Worried about teaching your dog basic mannerisms? Petmojo comes to your rescue with dog training services by experienced professionals.">
<meta id="twitter-training-5" property="twitter:image" content="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png">


<title id="training-id">Professional Dog Training at home - Dog Trainer near you - Petmojo</title>
<meta id="training-desc" name="description" content="Book Dog Training at the comfort of your home. Petmojo offers the best dog training services. Best Dog Trainers near you. " />
    `;

    return(()=>{
      document.getElementById("training-1").remove();
      document.getElementById("training-2").remove();
      document.getElementById("training-3").remove();
      document.getElementById("training-4").remove();
      document.getElementById("training-5").remove();
      document.getElementById("training-6").remove();
      document.getElementById("twitter-training-1").remove();
      document.getElementById("twitter-training-2").remove();
      document.getElementById("twitter-training-3").remove();
      document.getElementById("twitter-training-4").remove();
      document.getElementById("twitter-training-5").remove();
      document.getElementById("training-id").remove();
      document.getElementById("training-desc").remove();
    })
  });

  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div className={!changeView ? "training" : "training-desktop"}>
      <Helmet>
        <title>Professional Dog Training at home - Dog Trainer near you - Petmojo</title>
        <meta
          name="description"
          content="Book Dog Training at the comfort of your home. Petmojo offers the best dog training services. Best Dog Trainers near you. "
        />
        <link rel="canonical" href="/dog-training" />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileTraining />
      ) : (
        <IntroContainerDesktopTraining />
      )}
      <div className="sliderServiceContainer" style={{ marginTop: "50px",backgroundColor:"#F3E18A" }}>
        {!changeView ? (
          <div style={{marginTop:'20px',padding:'20px'}}>
            <div className="textbox2" style={{color:"black"}}>
            Our Happy Customer
            </div>
            <hr className="vector2" style={{borderColor:'black'}}></hr>

            <div className="dog-container" style={{border:"10px solid black",padding:'0px',display:'flex',width:'100%',justifyContent:"center"}}>
              <div className="dog-box-2" style={{display:"flex",justifyContent:"center",backgroundColor:"rgba(0, 0, 0, 0.10)",marginTop:'0px'}}>
                <div className="social-page-mobile-view-card-container" style={{display:"flex",justifyContent:"center",border:"none",marginRight:'0px',marginBottom:"0px",paddingBottom:"0px",padding:"5px 10px"}}>
                  <div style={{width:"100%",height:"100%"}}>
              <video width="100%" height="100%" controls poster="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-mobile-thumbnail.webp"  playsInline preload="auto" muted loop tabIndex="-1" autoPlay>
                <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-2.mp4" type="video/mp4"/>
                
                </video> 
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!changeView ? null : (
        <div className="dog-container-desktop2" style={{marginTop:"50px",backgroundColor:"#F3E18A"}}>
          <div style={{border:"10px solid black",display:'flex',width:"100%",padding:"20px",justifyContent:"space-between"}}>
          <div className="dog-slide-desktop-training">
            <div className="training-page-behave-box1" style={{marginBottom:"0px"}}>
              <h2 className="fifty-font-size bold-none" style={{color:"black"}}>
              Our Happy Customer
              </h2>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line" style={{borderColor:'black'}}></hr>
              </div>
            </div>
            <div className="training-page-behave-box2" style={{position:"relative",bottom:"20px"}}>
              <h4 className="bold-none" style={{color:'black'}}>
                <div>16000+ monthly happy pet </div>
                <div>parents on our platform!</div>
              </h4>
            </div>
          </div>

          <div className="dog-box-desktop-training" style={{display:"flex",justifyContent:"center",backgroundColor:"rgba(0, 0, 0, 0.10)",width:'50%'}}>
            <div className="educational-content-card-training" style={{width:"100%",height:"100%",boxShadow:"0px 4px 76px 0px #000",backgroundColor:"none",border:"none",background:"transparent",padding:'0px',margin:"0px",borderRadius:"0px"}}>
              <video width="100%" height="100%" controls muted loop poster="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/training-desktop-thumbnail.webp">
                <source src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-2.mp4" type="video/mp4"/>
                </video> 
            </div>
          </div>
          </div>
        </div>
      )}
      <div
        className={
          !changeView
            ? "sliderServiceContainer mobile-view-top-margin"
            : "sliderServiceContainer margin-for-desktop-training"
        }
        style={{marginTop:"0px"}}
      >
        {!changeView ? (
          <div className="training-page-mobile-view-package-selector-container" style={{marginTop:"15px"}}>
            <div className="textbox2">
              Our Packages
              <hr className="vector1"></hr>
            </div>
            {/* <div onClick={() => setOpen(!open)} className="trainers-option">
              <div>{trainer}</div>

              <div className="home-page-form-mobile-view-dropdown-arrow">
                <svg
                  className="training-page-form-mobile-view-dropdown-arrow-img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
            </div>

            {!open ? null : (
              <div className="optionsContainer">
                <div
                  onClick={() => {
                    setTrainer("Pro Trainers");
                    setOpen(!open);
                    setSection1(false)
                    setSection2(false)
                    setSection3(false)
                    setSection4(false)
                    setSection5(false)
                    setSection6(false)
                  }}
                  className="option"
                >
                  Pro Trainers
                </div>
                <hr></hr>
                <div
                  onClick={() => {
                    setTrainer("Regular Trainers");
                    setOpen(!open);
                    setSection1(false)
                    setSection2(false)
                    setSection3(false)
                    setSection4(false)
                    setSection5(false)
                    setSection6(false)
                  }}
                  className="option"
                >
                  Regular Trainers
                </div>
              </div>
            )} */}
          </div>
        ) : null}

        {!changeView ? (
          <div className="training-page-mobile-view-cards-master-container">
            {trainer === "Pro Trainers" ? (
              <div class="slider-container-grey">
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <div className="slider-training-package-mobile" style={{overflowX:"scroll"}} id="scrollable-div-mobile">
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Basic_Training-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-sec-2-2">
                      BASIC TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        24 Sessions | 2 Months
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic Social Ettiquttes </span> - Don't Jump, Don't Pull, Boundaries in House
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Leash Walking </span> - Heel, Turn, Slow, Fast Walk
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Advanced Potty / Pee Training
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Advanced Social Etiquettes 
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Greetings  </span> - Shake Hand, High-five
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Feedback Intelligence  </span> - No, Yes, Jump, Don't Jump, Eat, Don't Eat, Don't Bark 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Instruction   </span> - Sit, Advanced Stay, Down, Get Up
                        </div>
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Walking</span> - Come, Go
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">FREE Socialisation Chart (provided on app)</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">FREE diet chart</span> 
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:"flex",justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹17,940
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹13,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Intermediate_Training_Cat-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2-2">
                      INTERMEDIATE TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        36 Sessions | 3 Months
                      </div>
                      
                      <div className="training-page-mobile-view-package-section3">
                        {/* <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            All Sessions from PUPPY TRAINING+
                          </span>
                        </div> */}
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(115,1,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹24,440
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹18,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Advanced_Training-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2-2">
                      ADVANCED TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        48 Sessions | 4 Months
                      </div>
                      
                      <div className="training-page-mobile-view-package-section3">
                        {/* <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            All Sessions from PUPPY TRAINING+
                          </span>
                        </div> */}
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(115,1,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Trick Sequence</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Obstacle course Training</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Fetch with specific objects</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Discrimination Tasks</span> - colours, and objects identification
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹24,440
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹18,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                  
                  
                </div>
                </div>
                
              </div>
            ) : (
              <div class="slider-container-grey">
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <div className="slider-training-package-mobile" style={{overflowX:"scroll"}} id="scrollable-div2-mobile">
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Basic-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2">
                      BASIC TRAINING 
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        24 Sessions | 2 Months
                      </div>
                      <div className="training-page-mobile-view-package-section3">
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Litter Box Training
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Social Etiquettes 
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Handling & Touch Acceptance</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            Scratching Pad Use
                          </span>{" "}
                         
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            Basic House Rules
                          </span>{" "}
                         
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Clicker Training
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          No Biting, Scratching
                          </span>{" "}
                        </div>
                        {/* <div style={{textAlign:"right"}}>
                          read more
                        </div> */}
                      </div>
                    </div>

                    <div className="" style={{position:"absolute",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹17,940
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹13,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Intermediate-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2-2">
                      INTERMEDIATE TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        36 Sessions | 3 Months
                      </div>
                      
                      <div className="training-page-mobile-view-package-section3">
                        {/* <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            All Sessions from PUPPY TRAINING+
                          </span>
                        </div> */}
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(115,1,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹24,440
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹18,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                  <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Advanced-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2-2">
                      ADVANCED TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        48 Sessions | 4 Months
                      </div>
                      
                      <div className="training-page-mobile-view-package-section3">
                        {/* <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                            All Sessions from PUPPY TRAINING+
                          </span>
                        </div> */}
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(115,1,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Trick Sequence</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Obstacle course Training</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Fetch with specific objects</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Discrimination Tasks</span> - colours, and objects identification
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹32,440
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹26,800
                        </div>
                      </div>
                      {/* <div className="mobile-view-per-session">Per Session</div> */}
                    </div>
                  </div>
                </div>
                <div style={{width:"100%",padding:"10px",textAlign:"center"}}>
          </div>
                
                </div>
                
              </div>
            )}
          </div>
        ) : (

          <div className="slider-container-desktop-grooming margin-for-desktop" style={{marginTop:"0px",position:"relative",justifyContent:"space-between",gap:"10%"}}>
            <div className="training-page-desktop-package-left">
              <div className="training-page-package-heading">
                <div className="training-page-package-line1">
                  <h2 className="fifty-font-size bold-none">
                    Our Pet Training Packages
                  </h2>
                </div>
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>

              <br></br>

              {/* <div className="training-page-package-selector">
                <span
                  onClick={() => setOpen(!open)}
                  className="trainers-option-desktop"
                >
                  {trainer}
                  <span>
                    <svg
                      className="downArrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </span>

                {!open ? null : (
                  <div className="optionsContainer-desktop">
                    <div
                      onClick={() => {
                        setTrainer("Pro Trainers");
                        setOpen(!open);
                        console.log(open,"premium")
                        setSection7(false);
                        setSection8(false);
                        setSection9(false);
                        setSection10(false);
                        setSection11(false);
                        setSection12(false);
                      }}
                      className="option"
                    >
                      Pro Trainers
                    </div>
                    <hr></hr>
                    <div
                      onClick={() => {
                        setTrainer("Regular Trainers");
                        setOpen(!open);
                        console.log(open,"regular")
                        setSection7(false);
                        setSection8(false);
                        setSection9(false);
                        setSection10(false);
                        setSection11(false);
                        setSection12(false);
                      }}
                      className="option"
                    >
                      Regular Trainers
                    </div>
                  </div>
                )}
              </div> */}

              <div>
                <div className="bookingbutton" style={{marginTop:"20px",width:"100%"}}>
                  {/* <Link to="/popup" state={{ type: "dog-training" }}>
                  </Link> */}
                    <button className="booksession" onClick={()=>scrollToTop()}>Book a FREE Session</button>

                  <div className="limitedslots">
                    Hurry Up, Limited slots left!
                  </div>
                </div>
              </div>
            </div>
            
            <div className="training-page-desktop-package-right">
              {trainer === "Regular Trainers" ? (
                <div class="slider-container-desktop-training2">
                  <button style={{height:"150px",borderRadius:"5px",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",borderColor:"rgba(254, 119, 152, 1)",border:"2px solid rgba(254,119,152,1)",cursor:"pointer",background:"none"}} onClick={leftScroll}>
            <BsChevronLeft color="rgba(254,119,152,1)" size={20}/>
          </button>
                  <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                  <div class="slider-training-packages" style={{overflowX:"scroll",scrollBehavior:"smooth",width:"50vw"}} id="scrollable-div2">
                    
                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear"}} onClick={scrollToTop}>
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Basic-removebg-preview.png"
                        />
                      </div>
                      <div className="training-page-mobile-view-package-sec-2">
                        BASIC TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          24 Sessions | 2 Months
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                          Litter Box Training
                          </span>
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                            Social Etiquettes 
                          </span>{" "}
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Handling & Touch Acceptance</span> 
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                            Scratching Pad Use
                          </span>{" "}
                        
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                            Basic House Rules
                          </span>{" "}
                        
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Clicker Training
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          No Biting, Scratching
                          </span>{" "}
                        </div>
                        </div>
                      </div>

                      <div className="" style={{position:"absolute",bottom:"15px",width:"100%"}}>
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹17,940
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹13,800
                          </div>
                        </div>
                        {/* <div className="mobile-view-per-session">
                          Per Session
                        </div> */}
                      </div>
                    </div>

                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section7?"scroll":"hidden"}} onClick={scrollToTop}>
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Intermediate-removebg-preview.png"
                        />
                      </div>
                      <div className="training-page-mobile-view-package-sec-2-2">
                        INTERMEDIATE TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          36 Sessions | 3 Months
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training  </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching  </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section7 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                      </div>

                      <div className="" style={{position:!section7?"absolute":"relative",bottom:"15px",width:"100%"}}>
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹24,440
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹18,800
                          </div>
                        </div>
                      </div>
                    </div>
 
                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section7?"scroll":"hidden"}} onClick={scrollToTop}>
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Regular_Cat_Advanced-removebg-preview.png"
                        />
                      </div>
                      <div className="training-page-mobile-view-package-sec-2-2">
                        ADVANCED TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          48 Sessions | 4 Months
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training  </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching  </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section7 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Trick sequence</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Obstacle course training</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Fetch with specific objects</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Discrimination tasks</span> - colours, and objects identification
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                      </div>

                      <div className="" style={{position:!section7?"absolute":"relative",bottom:"15px",width:"100%"}}>
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹32,440
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹26,800
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  </div>
                  <button style={{height:"150px",borderRadius:"5px",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",borderColor:"rgba(254, 119, 152, 1)",border:"2px solid rgba(254,119,152,1)",cursor:"pointer",background:"none"}} onClick={rightScroll}>
            <BsChevronRight color="rgba(254,119,152,1)" size={20}/>
          </button>
                </div>
              ) : (
                <div class="slider-container-desktop-training">
                  <button style={{height:"150px",borderRadius:"5px",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",borderColor:"rgba(254, 119, 152, 1)",border:"2px solid rgba(254,119,152,1)",cursor:"pointer",background:"none"}} onClick={leftScroll}>
            <BsChevronLeft color="rgba(254,119,152,1)" size={20}/>
          </button>
                  <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                  <div class="slider-training-packages" style={{overflowX:"scroll",scrollBehavior:"smooth",width:"50vw"}} id="scrollable-div">
                    

                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Basic_Training-removebg-preview.png"
                        />
                      </div>
                      <div className="training-page-mobile-view-package-standard-sec-2-2" style={{background: "linear-gradient(180deg, #fe8788 0%, #ea2179 100%)"}}>
                        BASIC TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          24 Sessions | 2 Months
                        </div>
                        
                        <div className="training-page-mobile-view-package-section3">
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Litter Box Training </span>
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Social Etiquettes </span>
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                            Handling & Touch Acceptance
                            </span>{" "}
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">
                            Scratching Pad Use
                            </span>
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Basic House Rules</span> 
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Come and Sit Command </span> 
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">Clicker Training </span> 
                          </div>
                          <div className="training-page-mobile-view-package-sec-3-o">
                            <span className="package-bold">No Biting, Scratching </span> 
                          </div>
                          
                        </div>
                      </div>

                      <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹20,540
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹15,800
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section1?"scroll":"hidden"}} onClick={scrollToTop}>
                    <div className="training-page-mobile-view-package-sec-1">
                      <img
                        alt="trainingIcon"
                        className="training-page-mobile-view-packageIcon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Intermediate_Training_Cat-removebg-preview.png"
                      />
                    </div>
                    <div className="training-page-mobile-view-package-standard-sec-2-2" style={{background: "linear-gradient(180deg, #7e0a90 0%, #ce4c95 100%)"}}>
                      INTERMEDIATE TRAINING
                    </div>
                    <div className="training-page-mobile-view-package-sec-3">
                      <div className="training-page-mobile-view-package-sec-3-1">
                        36 Sessions | 3 Months
                      </div>
                      
                      <div className="training-page-mobile-view-package-section3">
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(115,1,144)",display:section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section1 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section1?"none":"block"}} >
                          <span onClick={()=>{
                           setSection1((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="" style={{position:!section1?"absolute":"relative",bottom:"15px",width:"100%"}}>
                      <div className="training-card-mobile-view-discounted-price">
                        Discounted Price
                      </div>
                      <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                        <div className="training-card-mobile-view-og-price">
                          <div className="diagonal-line-mobile-view-training">
                            <div className="diagonal-line-mobile-view-text">
                              ₹28,340
                            </div>
                          </div>{" "}
                        </div>
                        <div className="training-card-mobile-view-disc-price">
                          ₹21,800
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="training-page-mobile-view-package-container" style={{animation:"scroll-div10 2s linear",overflowY:section7?"scroll":"hidden"}} onClick={scrollToTop}>
                      <div className="training-page-mobile-view-package-sec-1">
                        <img
                          alt="trainingIcon"
                          className="training-page-mobile-view-packageIcon"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Cat_Advanced_Training-removebg-preview.png"
                        />
                      </div>
                      <div className="training-page-mobile-view-package-sec-2-2" style={{background: "linear-gradient(180deg, #edce28 0%, #b5921f 100%)"}}>
                        ADVANCED TRAINING
                      </div>
                      <div className="training-page-mobile-view-package-sec-3">
                        <div className="training-page-mobile-view-package-sec-3-1">
                          48 Sessions | 4 Months
                        </div>
                        <div className="training-page-mobile-view-package-section3">
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Litter Box Training </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Social Etiquettes </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Handling & Touch Acceptance
                          </span>{" "}
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">
                          Scratching Pad Use
                          </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Basic House Rules </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Come and Sit Command </span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Clicker Training  </span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">No Biting, Scratching  </span> 
                        </div>
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show more...
                          </span>
                        </div>
                        {section7 && <>
                          <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Advanced Tricks</span> - shake paw, high five, spin, fetch
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Stay Command</span>
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Target Training</span> - teach cat to touch few commands
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Hulahoop Jumping</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Trick sequence</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Obstacle course training</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Fetch with specific objects</span> 
                        </div>
                        <div className="training-page-mobile-view-package-sec-3-o">
                          <span className="package-bold">Discrimination tasks</span> - colours, and objects identification
                        </div>
                        </>}
                        <div style={{textAlign:"right",color:"rgb(129,13,144)",display:!section7?"none":"block"}} >
                          <span onClick={()=>{
                           setSection7((prev)=>!prev)
                          }}>
                          show less...
                          </span>
                        </div>
                      </div>
                      </div>

                      <div className="" style={{position:!section7?"absolute":"relative",bottom:"15px",width:"100%"}}>
                        <div className="training-card-mobile-view-discounted-price">
                          Discounted Price
                        </div>
                        <div className="training-card-mobile-view-price-btn" style={{width:"100%",display:'flex',justifyContent:"center",gap:"5px"}}>
                          <div className="training-card-mobile-view-og-price">
                            <div className="diagonal-line-mobile-view-training">
                              <div className="diagonal-line-mobile-view-text">
                                ₹36,340
                              </div>
                            </div>{" "}
                          </div>
                          <div className="training-card-mobile-view-disc-price">
                            ₹28,800
                          </div>
                        </div>
                      </div>
                    </div>   
                  </div>
                  
                  </div>
                  <button style={{height:"150px",borderRadius:"5px",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",borderColor:"rgba(254, 119, 152, 1)",border:"2px solid rgba(254,119,152,1)",cursor:"pointer",background:"none"}} onClick={rightScroll}>
            <BsChevronRight color="rgba(254,119,152,1)" size={20}/>
          </button>
                </div>
              )}
            </div>
          </div>
        )}

        {!changeView ? (
          <div style={{marginTop:"20px"}}>
            <div className="total-sessions">
              "20,000+ Session & Counting..."
            </div>

            <div className="bookingbutton" style={{paddingLeft:"30px",paddingRight:"30px"}}>
              {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                <button className="booksession-mobile-view" onClick={()=>scrollToTop()}  style={{width:"100%"}}>
                  Book a FREE Session
                </button>
              {/* </Link> */}

              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          </div>
        ) : null}
      </div>

      {!changeView ? (
        <div className="pink-container">
          <div className="textbox tracking-text">
            <span>
              <span className="pinktext">Track every session on our </span>{" "}
              <span className="pet-bold-text">pet</span>
              <span className="pinktext">mojo Application</span>
            </span>
          </div>

          <div className="tracking-container" ref={sectionOneRef}>
            <div className="tracking-info">
              <div className="tracking-info-mobile-view-master-container">
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Report card of sessions</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Tracking progress</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photos from session</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Agenda for sessions</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Trainers feedback</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Highlights from today</div>
                </div>
                <div className="tracking-info-mobile-view-container">
                  <div>
                    <img
                      alt="tick"
                      className="tick-mobile-view-image"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photo/Video Update</div>
                </div>
              </div>
              <LazyLoad>
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                  alt="appstore-training"
                  id="section1"
                  ></img>
                  </a>
              </LazyLoad>
              <LazyLoad>
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                  alt="playstore"
                  id="section2"
                  ></img>
                  </a>
              </LazyLoad>
            </div>
            <LazyLoad>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
                alt="trackingicon"
                className="tracking-icon"
              ></img>
            </LazyLoad>
          </div>

          <div className="bookingbutton" style={{paddingLeft:"30px",paddingRight:"30px"}}>
            {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
              <button className="booksession-mobile-view" onClick={()=>scrollToTop()}  style={{width:"100%"}}>
                Book a FREE Session
              </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div> //here you come
      ) : (
        <div className="walking-page-track-container">
          <div className="walking-page-track-right">
            <div className="walking-page-track-left-heading">
              <div className="walking-page-track-line1 pink">
                <h2
                  className="fifty-font-size bold-none"
                  style={{ fontFamily: "DM Sans" }}
                >
                  Track every session on our{" "}
                  <span className="dark-grey-text fifty-font-size">pet</span>
                  <span className="pink">mojo</span>{" "}
                  <span className="pink">Application</span>
                </h2>
              </div>
          
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>
            <div
              className="walking-page-track-left-content"
              style={{ fontFamily: "DM Sans" }}
            >
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Report card of sessions</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Tracking progress</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Photos from session</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Agenda for sessions</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Trainers feedback</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Highlights from today</div>
              </div>
              <div className="track-session-point">
                <div>
                  <img
                    alt="tick"
                    className="tick-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                  />
                </div>
                <div>Photo/Video Update</div>
              </div>
            </div>
            <div className="" style={{marginTop:"40px"}}>
          {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
            <button className="booksession-track" onClick={()=>scrollToTop()} style={{width:""}}>Book a FREE Session</button>
          {/* </Link> */}
        </div>
          </div>
          <div className="" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <div className="walking-page-track-left-buttons" style={{height:"80%",display:"flex",alignItems:"flex-end"}}>
             <div className="icons-store-section-float-2">
               <div>
                 <a
                   target="_blank" rel="nofollow"
                   href="https://apps.apple.com/in/app/petmojo/id1609861328"
                 >
                   <img
                     alt="appStore"
                     className="walking-page-track-left-buttons-store"
                     src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/image_17_txwsw4.png"
                   />
                 </a>
               </div>
               <div>
                 <a
                   target="_blank" rel="nofollow"
                   href="https://play.google.com/store/apps/details?id=in.tamely.user"
                 >
                   <img
                     alt="playStore"
                     className="walking-page-track-left-buttons-store"
                     src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                   />
                 </a>
               </div>
             </div>
           </div>
          </div>
          <div className="walking-page-track-left" style={{display:"flex",alignItems:"flex-end"}}>
          <div style={{height:"100%",width:"full",display:"flex",alignItems:"flex-end"}}>
            <img
              alt="petmojoImg"
              className="walking-page-track-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
              style={{height:"524px",width:"100%",objectFit:"contain"}}
            />
          </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <HeartContainer pathname={location.pathname}/>
      ) : (
        <div className={classes.heartSection}>
          <div className={classes.heartSectionTextContainer}>
            <div className={classes.heartSectionTextHeading}>
              <h2 className="fifty-font-size bold-none">
                Your Pet, Our Family 💖
              </h2>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className={classes.heartSectionText} style={{marginTop:"0px",position:"relative",bottom:"20px"}}>
              <div>
                <h4 className="bold-none">
                  <span className="pink">16000+</span> monthly happy pet parents
                  on our platform!
                </h4>
              </div>
            </div>

            <div className="bookingbutton" style={{position:"relative",bottom:"20px"}}>
              {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                <button className="booksession" onClick={scrollToTop}>Book a FREE Session</button>
              {/* </Link> */}
              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          </div>
          <div className={classes.heartSectionImage}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart"
              className="hearticon"
            ></img>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div className="training-page-yt-container">
          <div className="training-page-yt-left">
            <div className="training-page-yt-left-heading">
              <div>
                <h2 className="fifty-font-size bold-none">
                  Truly Transformational Results Guaranteed
                </h2>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="training-page-yt-left-btn" style={{marginTop:"0px"}}>
              <div className="bookingbutton">
                {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                  <button className="booksession" onClick={()=>scrollToTop()}>Book a FREE Session</button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>
          <div className="training-page-yt-right">
            <div className="youtube-container-desktop">
              {/* <Iframe
                url="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/dog-training-video.mp4"
                id="myId"
                className="youtube-video-desktop"
                width="100%"
                height="100%"
              /> */}
              <video src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/dog-training-video.mp4" controls className="youtube-video-desktop" />
            </div>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div className="cat-training-page-trainer-meet">
          <div className="training-page-trainer-meet-left" >
            <div className="grooming-page-trainer-meet-head1" style={{marginBottom:"0px"}}>
              <div>
                <h2 className="fifty-font-size bold-none">
                  Pet Parent & Trainer <span className="pink">Meet</span>
                </h2>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="grooming-page-trainer-meet-head2" style={{marginTop:"0px",position:"relative",bottom:"30px"}}>
              <h4>After Every 12 Sessions</h4>{" "}
            </div>
            <div className="bookingbutton" style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"flex-start",alignItems:"flex-start",position:"relative",bottom:"30px"}}>
              {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                <button className="booksession" onClick={()=>scrollToTop()} style={{display:"block",width:"50%"}}>Book a FREE Session</button>
              {/* </Link> */}
              <div className="limitedslots" style={{textAlign:"center",width:"50%"}}>Hurry Up, Limited slots left!</div>
            </div>
          </div>
          <div className="grooming-page-trainer-meet-right" style={{padding:"0px",margin:"0px "}}>
            <div className="grooming-page-trainer-meet-right-head" style={{marginBottom:"0px"}}>
              <h3 className="bold-none grooming-page-trainer-meet-right-head" style={{fontSize:"45px"}}>
                Understand your pet's progress better
              </h3>
            </div>

            <div className="grooming-page-trainer-meet-subtext" style={{marginTop:"0px"}}>
              <div style={{display:"flex"}}>
                <div>
                  <li></li>
                </div>
                <div>
                Session-by-Session Progress Report.
                </div>
              </div>
              <div style={{display:"flex"}}>
                <div>
                  <li></li>
                </div>
                <div>
                Understanding strength and training capabilities.
                </div>
              </div>
              <div style={{display:"flex"}}>
                <div>
                  <li></li>
                </div>
                <div>
                Focused feedback on performance improvement.
                </div>
              </div>
              <div style={{display:"flex"}}>
                <div>
                  <li></li>
                </div>
                <div>
                Tips to prevent aggressive behaviour, biting and nipping.
                </div>
              </div>
              {/* <li>Session-by-Session Progress Report.</li> */}
              {/* <li>Understanding strength and training capabilities.</li>
              <li>Focused feedback on performance improvement.</li>
              <li>Tips to prevent aggressive behaviour, biting and nipping.</li> */}
            </div>

           
          </div>
        </div>
      )}

      {!changeView ? (
        <div style={{padding:"20px"}}>
          <div className="textbox2">
            Truly Transformation Results Guaranteed
          </div>
          <hr className="vector2"></hr>

          <div className="youtube-container">
            {/* <Iframe
              url="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/dog-training-video.mp4"
              id="myId"
              className="youtube-video"
              
            /> */}
            <video src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/review-5.mp4" controls  className="youtube-video" />
              
          
          </div>
          <div className="bookingbutton"  style={{paddingLeft:"10px",paddingRight:"10px"}}>
            {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
              <button className="booksession" onClick={()=>scrollToTop()}  style={{width:"100%"}}>Book a FREE Session</button>
            {/* </Link> */}
          </div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="pet-parent-meet-mobile-view">
          <div className="pet-parent-meet-mobile-view-container-1">
            <div className="pet-parent-meet-mobile-view-container-1-head">
              Pet Parent & Trainer Meet
            </div>
            <div className="pet-parents-meet-mobile-view-cont">
              <div className="pet-parent-meet-mobile-view-container-1-subhead">
                After Every 12 Sessions{" "}
              </div>
              <hr className="vector2-pet-parents"></hr>
            </div>
          </div>
          <div className="pet-parent-meet-mobile-view-container-2">
            <div className="pet-parent-meet-mobile-view-container-2-head">
              Understanding your pet's progress better
            </div>
            <ul className="ul-list">
              <li className="li-list">Session-by-Session Progress Report.</li>
              <li className="li-list">
                Understanding strength and training capabilities.
              </li>
              <li className="li-list">
                Focused feedback on performance improvement.
              </li>
              <li className="li-list">
                Tips to prevent aggressive behaviour, biting and nipping.
              </li>
            </ul>
          </div>
          <div className="pet-parent-meet-mobile-view-container-3"  style={{width:"100%"}}>
            <div className="bookingbutton"  style={{width:"100%",paddingLeft:"10px",paddingRight:"10px"}}>
              {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                <button className="booksession-mobile-view2" onClick={()=>scrollToTop()}  style={{width:"100%"}}>
                  Book a FREE Session
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? (
        <div className="container-home margin-top-training-started">
          <div className="textbox2">
            Let’s Get You Started with Our Fitness Training
          </div>
          <hr className="vector1"></hr>
          <div>
            <TimelineComponent version={"mobile"} timelineData={timelineData} />
          </div>
          <div className="bookingbutton" style={{paddingLeft:"30px",paddingRight:"30px"}}>
            {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
              <button className="booksession-mobile-view" onClick={()=>scrollToTop()}  style={{width:"100%",marginTop:"0px"}}>
                Book a FREE Session
              </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container-home-desktop">
            <div className="get-started-walking-head">
              <div className={classes.howToStart}>
                <div className={classes.howToStart} style={{marginBottom:"0px"}}>
                  <div className="">
                    <h2 className="fifty-font-size bold-none">
                      Let’s Get You Started with Our Training
                    </h2>
                  </div>
                  <div className="line-div relative-line">
                    <hr className="live-training-pink-line"></hr>
                  </div>
                </div>
                <div  style={{marginTop:"0px",position:"relative",bottom:"30px"}}>
                  <div className="bookingbutton" style={{width:"100%"}}>
                    {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
                      <button className="booksession-mobile-view" onClick={()=>scrollToTop()} style={{marginBottom:"0px",padding:"0px"}}> 
                        Book a FREE Session
                      </button>
                    {/* </Link> */}
                    <div className="limitedslots">
                      Hurry Up, Limited slots left!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TimelineComponent
                version={"desktop"}
                timelineData={timelineData}
              />
            </div>
          </div>
        </div>
      )}

      
      {!changeView ? (
        <div className="cat-training-page-mobile-view-why-container">
          <div className="services-container2">
           
            <div className="service-box">
              <div
                className="textbox2"
                style={{ color: "#5D5D5D", fontWeight: 700 }}
              >
                Why Choose Petmojo?
              </div>
              <hr className="vector1"></hr>
              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Trainers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>

              {/* <div className="grey-background">
              <div className="bookingbutton">
                <Link to="/popup" state={{ type: "dog-training" }}>
                  <button className="booksession-mobile-view2">Book a FREE Session</button>
                </Link>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page-why-choose-container">
          <div className="home-page-why-choose-container4">
            <div className="home-page-why-choose-container1-heading-seo" style={{marginBottom:"0px"}}>
              <div>
                <h3 className="non-bold">Why Choose Petmojo?</h3>
              </div>
              <div className="line-div relative-line" >
                <hr className="live-training-pink-line" style={{position:"relative",bottom:"15px"}}></hr>
              </div>
            </div>

            <div className="home-page-why-choose-container1-content" style={{marginTop:"0px",position:"relative",bottom:"40px"}}>
              <div>
                <h4>We are worlds most empathetic Pet Care Services.</h4>
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container2">
            <div className="service-1">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                  alt="trust icon"
                  className="trust-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Trusted & Caring Trainers
              </div>
            </div>
            <div className="service-2">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                  alt="door icon"
                  className="door-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Service at Your Door-Step{" "}
              </div>
            </div>
            <div className="service-3">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                  alt="refund icon"
                  className="refund-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Guaranteed Satisfaction{" "}
              </div>
            </div>
            <div className="service-4">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                  alt="team icon"
                  className="team-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Local Onground Support
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container3">
            <img
              className="home-page-why-choose-container3-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Desktop_Why_Choose_Petmojo.png"
            />
          </div>
        </div>
      )}

      {/*       

        {
          !changeView ? (<LiveTraining />) : ( 
           null
          )
        } */}

      {!changeView ? (
        <div className="pink-container-family">
          <div className="textbox2">We are Family of Pet Lovers</div>
          <hr className="vector2"></hr>

          <div className="textbox3">
            Fully Trained and Certified Trainers/Groomers.
            <br /> <span className="pinktext">Top 1% </span>
            <span className="grey-text">
              of all Trainers/Groomers in India. Rigorous quality and audit
              checks
            </span>
          </div>

          <div className="trainer-container">
            <div className="trainer-cards-mobile ">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-1.webp"
                  alt="trainercard1"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-2.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-3.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
          </div>
          <div className="bookingbutton" style={{paddingLeft:"30px",paddingRight:"30px"}}>
            {/* <Link to="/popup" state={{ type: "dog-training" }}> */}
              <button className="booksession-mobile-view " onClick={()=>scrollToTop()}  style={{width:"100%"}}>
                BOOK a FREE Session
              </button>
            {/* </Link> */}
            <div className="limitedslots">Hurry Up, Limited slots left!</div>
          </div>
        </div>
      ) : (
        <div className="pink-container-desktop">
          <div className="pink-container-left">
            <div className="pink-container-left-box1">
              <div className="textbox2-desktop">
                <h2 className="bold-none fifty-font-size">
                  We are Family of Pet Lovers
                </h2>
              </div>
              <div className="line-div2 relative-line">
                <hr className="live-training-pink-line" style={{position:"relative",bottom:"20px"}}></hr>
              </div>
            </div>

            <div className="pink-container-left-box2" style={{marginTop:"0px",position:"relative",bottom:"30px"}}>
              <h4 className="bold-none">
                <div>Fully Trained and Certified</div>
                <div>
                  Trainers. <span className="pink-bold">Top 1%</span> of all
                  trainers in
                </div>
                <div>India. Rigorous quality and audit</div>
                <div>checks.</div>
              </h4>
            </div>

            <div className="pink-container-left-box3" style={{position:"relative",bottom:"30px"}}>
              <div className="bookingbutton">
                {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                  <button className="booksession" onClick={scrollToTop}>Book a FREE Session</button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>

          <div className="pink-container-right">
            <div className="pink-container-right-head">
              <h3 className="bold-none">Some of Our Top Trainers</h3>
            </div>
            <div className="trainer-container">
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8926_criqhc.png"
                    alt="trainercard1"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8928_rtscxb.png"
                    alt="trainercard2"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>

              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8930_nsgnok.png"
                    alt="trainercard3"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <FAQContainerTraining view={"mobile"} />
      ) : (
        <FAQContainerTraining view={"desktop"} />
      )}

      {!changeView ? (
        <div className="pink-container-family">
          <div className="textbox2">We’ll Make Your Pet Smile</div>
          <hr className="vector2"></hr>

          <div className="heroImage1Container">
            <div className="heroImage1">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/homepage-petmojo-partner-mobile-outline.webp"
                alt="makepetsmile"
                className="make-pet-smile"
              ></img>
              <div className="petmojo-partner-mobile-view-name">
                Petmojo Partner -{" "}
                <span className="petmojo-partner-mobile-view-name-cursive">
                  Kartik Rai
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="hero-image-container-desktop">
          <div className="hero-image-container-desktop-left">
            <div className="pet-smile-home-set1">
              <div>
                <h3 className="fifty-font-size bold-none">
                  We'll Make Your Pet Smile
                </h3>
              </div>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="pet-smile-home-set2" style={{position:"relative",bottom:"20px"}}>
              <h4 className="bold-none">Your Pet Our Family!</h4>
            </div>
          </div>

          <div className="hero-image-container-desktop-right">
            <img
              className="pet-smile-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp"
            />
            <div className="petmojo-partner-name-desktop">
              Petmojo Partner -{" "}
              <span className="petmojo-partner-name-desktop-cursive">
                Kartik Rai
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="giveSpace">
        {!changeView ? (
          <div className="pink-container-family">
            <div className="textbox2">Download Petmojo App</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {!changeView ? (
          <div className="iphone-container">
            <div className="storeContainer_logo_container_mobile">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                  alt=""
                  style={{objectFit:"contain"}}
                />
              </div>
            {/* <LazyLoad> */}
            <div>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application-mobile.webp"
                alt="iphoneicon"
                className="iphone-icon"
              ></img>
            </div>

            {/* </LazyLoad> */}
            <div className="download-btn-container" ref={sectionTwoRef}>
              <div className="playstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                    alt="playstore"
                    id="section4"
                  ></img>
                  {/* </LazyLoad> */}
                </a>
              </div>
              <div className="appstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                >
                  {/* <LazyLoad> */}
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                    alt="appstore"
                    id="section3"
                  ></img>
                  {/* </LazyLoad> */}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="storeContainer">
          <div className="storeContainer_left">
            <h1 className="storeContainer_heading">
              Get Petmojo Application
            </h1>

            <hr className="live-training-pink-line"></hr>

            <p className="storeContainer_text">
            Download our mobile application to get exclusive offers and discounts on Pet Grooming
            </p>
            <div className="pink-container-left-box3" style={{marginTop:"10px"}}>
              <div className="bookingbutton">
                {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                  <button className="booksession" onClick={scrollToTop}>Book Now!</button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>

          <div className="storeContainer_right">
            <div className="storeContainer_logo_container">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                alt=""
                style={{objectFit:"contain"}}
              />
            </div>
            <div className="storeContainer_main_image">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application.webp"
                alt=""
              />
            </div>
           
            <div className="storeContainer_button_container">
            <a
                target="_blank" rel="nofollow"
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
              >
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                alt=""
              />
              </a>
              <a
                target="_blank" rel="nofollow"
                href="https://apps.apple.com/in/app/petmojo/id1609861328"
              >
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                alt=""
              />
              </a>
            </div>
          </div>
        </div>
        )}
      </div>

      <div style={{ margin: "2rem 0rem" }}>
        {!changeView ? (
          <div>
            <div className="textbox2">Social Media Links</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"
                  }
                  alt="innerring"
                  className="inner-ring"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="twitter-icon">
                  <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank" 
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="mailto: support@petmojo.care"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div style={{ margin: "2rem 0rem" }}>
        {!changeView ? (
          <div>
            <div className="textbox2">Trusted by &nbsp;
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/google_logo.png"
                alt="outerring"
                style={{width:"120px",marginBottom:"-10px"}}
              ></img>  
              <br/>
              and<span style={{fontWeight: "bold",color: "black"}}> Pet Parents </span>
            </div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Trusted_by_Google.png"
                  alt="outerring"
                  className="iphone-icon"
                ></img> 
              </div>
            </div>
            <div>
              
            <div className="textbox3">
            <span className="grey-text">
              Google in partnership with Ministry of Electronics and
              Information Technology (Meity) has chosen us as one of
              India's top 100 startups
            </span>
          </div>
        
            </div>
          </div>
        ) : null}
      </div>

      {!changeView ? null : (
        <div className="social-media-links-container">
          <div className="social-media-links-header">
            <div className="social-media-links-text">
              Connect With Us
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>
            <div className="home-page-walking-packages-desktop-container2">
              <div>Connect on applications you use</div>
              <div>daily to get Informative/Educational </div>
              <div>contents on Petmojo.</div>
            </div>
          </div>

          <div className="social-media-links-image">
            <div className="ellipse-container">
              <div className="ellipse-desktop">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                  alt="outerring"
                  className="outer-ring-desktop"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"
                  }
                  alt="innerring"
                  className="inner-ring-desktop"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon-desktop">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon-desktop">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                      alt="facebookicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>

                <button className="instagram-icon-desktop">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                      alt="instagramicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon-desktop">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                      alt="phoneicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon-desktop">
                  <a
                    href="mailto: support@petmojo.care"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                      alt="gmailicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon-desktop">
                  <a
                    href="tel:+91 8889991800"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                      alt="whatsappicon"
                      width="60px"
                      height="60px"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div className="social-media-links-container">
          <div className="social-media-links-header">
            <div className="social-media-links-text" style={{display:"inline"}}>
              Trusted by&nbsp;
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/google_logo.png"
                alt="outerring"
                style={{height:"80px",marginBottom:"-23px"}}
              ></img>  
              <br/>
              and <span style={{fontWeight: "bold",color: "black"}}> Pet Parents </span>
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>
            <div className="home-page-walking-packages-desktop-container2">
              <div>Google in partnership with Ministry of Electronics and </div>
              <div>Information Technology (Meity) has chosen us as one of </div>
              <div>India's top 100 startups </div>
            </div>
          </div>

          <div className="social-media-links-image-cat">
            <div className="ellipse-container">
              <div className="ellipse-desktop">
      
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Trusted_by_Google.png"
                  alt="outerring"
                  className="outer-ring-desktop"
                ></img>
                
              </div>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <div>
          <div className="mobile-view-footer-1">
            <div className="mobile-view-footer-1-left">
              <div className="mobile-view-footer-1-left-header-container">
                <div className="mobile-view-footer-1-header">Need Help</div>
                <hr className="vector2-footer"></hr>
              </div>
              <div className="mobile-view-footer-1-content-left">
                <div className="mobile-view-footer-phone-container">
                  <div className="mobile-view-footer-phone-icon">
                    <img
                      alt="telIcon"
                      className="mobile-view-tel-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-phone-text"
                      style={{ textDecoration: "none" }}
                      href="tel: +91 8889991800"
                      target="_blank" rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>
                <div className="mobile-view-footer-mail-container">
                  <div className="mobile-view-footer-mail-icon">
                    <img
                      alt="mailIcon"
                      className="mobile-view-mail-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-mail-text"
                      style={{ textDecoration: "none" }}
                      href="mailto: support@petmojo.care"
                      target="_blank" rel="nofollow"
                    >
                      support@petmojo.care
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-1-right">
              <div className="mobile-view-footer-1-header">Services</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-running"
                  state={{ from: "training" }}
                >
                  Dog Exercise/Walking
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-training"
                  state={{ from: "training" }}
                >
                  Dog Training
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming"
                  state={{ from: "training" }}
                >
                  Pet Grooming
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming-subscription"
                  state={{ from: "training" }}
                >
                  Grooming S
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/pet-boarding"
                  state={{ from: "training" }}
                >
                  Pet Hotel
                </Link>
              </div>
              {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "training" }}
                >
                  Pet Styling
                </Link>
              </div> */}
            </div>
          </div>

          <div className="mobile-view-footer-2">
            <div className="mobile-view-footer-2-left">
              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                  <div className="footerDetailsLink-mobile">Delhi</div>
                  <div className="footerDetailsLink-mobile">Gurgaon</div>
                  <div className="footerDetailsLink-mobile">Noida</div>
                  <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                  <div className="footerDetailsLink-mobile">Faridabad</div>
                  <div className="footerDetailsLink-mobile">Jaipur</div>
                  <div className="footerDetailsLink-mobile">Manesar</div>
                  <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-2-right">
              <div className="mobile-view-footer-2-right-header">More</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-links-div">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/terms"
                >
                  <div className="mobile-view-footer-2-tnc">
                    Terms and Conditions
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/privacy"
                >
                  <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/partner"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Become a Partner
                  </div>
                </Link>{" "}
                <div className="mobile-view-footer-2-left-content">
                  Privacy Policy - Partner
                </div>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/refunds" ><div className='mobile-view-footer-2-left-content'>Refund Policy</div></Link>
                <Link style={{textDecoration: "none", color: "rgba(132, 132, 132, 1)"}} to="/about" ><div className='mobile-view-footer-2-left-content'>About Us</div></Link>
              </div>
            </div>
          </div>

<div className="mobile-footer-wrap" ref={sectionThreeRef}>

          <div className="mobile-view-footer-3" style={{gap:"5px"}}>
            <div className="mobile-view-footer-3-btn1" style={{height:"33px"}}>
              <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

              <img
                className="mobile-view-footer-appStore-icon"
                id="section5"
                alt="appStore"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                style={{height:"100%",width:"100%",objectFit:"contain"}}
                />
                </a>
            </div>
            <div className="mobile-view-footer-3-btn2" style={{height:"33px"}}>
              <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">
              <img
                className="mobile-view-footer-appStore-icon"
                id="section6"
                alt="playStore"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
              style={{height:"100%",width:"100%",objectFit:"contain"}}
              />
              </a>
              
            </div>
            <div
              onClick={() => goToTopHandler()}
              className="mobile-view-footer-3-btn3" style={{height:"33px",padding:"5px",display:"flex",justifyContent:"center"}}
            >
              <span>Go to the top</span>
                <BsArrowUp/>
              {/* <div>Go to the top</div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div> */}
            </div>
          </div>
          </div>

          <div className="mobile-view-footer-4">
            <div>Copyright 2022 Petmojo</div>
            <div>
              All copyrights are reserved - any infringement will be legally
              pursued.
            </div>
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-call"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="tel: 8889991800"
                  >
                    8889991800
                  </a>
                </div>
              </div>

              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-mail"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank" rel="nofollow"
                    href="mailto: support@petmojo.care"
                  >
                    support@petmojo.care
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link
                className="footerDetailsLink"
                to="/dog-running"
                state={{ from: "training" }}
              >
                <div>Daily Dog Exercise/Walking</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/dog-training"
                state={{ from: "training" }}
              >
                <div>Dog Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/cat-training"
                state={{ from: "training" }}
              >
                <div>Cat Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming"
                state={{ from: "training" }}
              >
                <div>Pet Grooming</div>
              </Link>
              <Link
                      className="footerDetailsLink"
                      to="/grooming-subscription"
                      state={{ from: "footer" }}
                    >
                      <div>Grooming Subscription</div>
                    </Link>
              <Link
                className="footerDetailsLink"
                to="/pet-boarding"
                state={{ from: "training" }}
              >
                <div>Pet Hotel</div>
              </Link>
              {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "training" }}
              >
                <div>Pet Styling</div>
              </Link> */}
            </div>
          </div>
          <div className="footerDetailsContr1">
            <div className="footerDetailsHeading">Serving In</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footerDetailsLink">Delhi</div>
              <div className="footerDetailsLink">Gurgaon</div>
              <div className="footerDetailsLink">Noida</div>
              <div className="footerDetailsLink">Ghaziabad</div>
              <div className="footerDetailsLink">Faridabad</div>
              <div className="footerDetailsLink">Jaipur</div>
              <div className="footerDetailsLink">Manesar</div>
              <div className="footerDetailsLink">Mumbai</div>
            </div>
          </div>

          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner">
                <div>Become a Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/terms">
                <div>Terms and Conditions</div>
              </Link>
              <Link className="footerDetailsLink" to="/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/">
                <div>Privacy Policy - Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/refunds">
                <div>Refund Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/about">
                <div>About Us</div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {!changeView ? null : (
        <div>
          <div className="desktop-footer-buttons-container-add" style={{display:"flex", flexDirection:"column"}}>
              <p style={{fontWeight:"800"}}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
              <p style={{marginTop:"-1rem"}}> Ph no- +918889991800</p>
            </div>

        <div className="desktop-footer-buttons-container">
          <div className="desktop-footer-buttons-left">
            <div className="desktop-footer-buttons-left-btn1">
              <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

              <img
                alt="icon"
                className="desktop-footer-buttons-left-btn1-img"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                />
                </a>
            </div>
            <div className="desktop-footer-buttons-left-btn1">
              <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

              <img
                alt="icon"
                className="desktop-footer-buttons-left-btn1-img"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                />
                </a>
            </div>
            <div
              onClick={scrollToTop}
              className="desktop-footer-buttons-left-btn3"
            >
              <div>Go at the top</div>

              <svg
                className="top-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-up"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </div>
          </div>

          <div className="desktop-footer-buttons-right">
            Copyright 2022 Petmojo
          </div>
        </div>
        </div>
      )}

      <GoToTop />
    </div>
  );
}

export default Training;
