import React, { useState, SyntheticEvent, useRef, useEffect } from "react";
import "./Blog.css";
import {Helmet} from 'react-helmet-async';
import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import NavigationDesktopBlogs from "../components-desktop/Navigation/NavigationDesktopBlogs";
import {AllBlogs, SuggestionBlogs} from '../blogs/BlogsData';
import { useNavigate } from "react-router-dom";
import BlogListGenerator from "./BlogListGenerator";
import BlogListGeneratorMobile from "./BlogListGeneratorMobile";
import GoToTop from "./GoToTop";
import BlogCardGeneratorDesktop from './BlogCardGeneratorDesktop';
import { RxCross2 } from "react-icons/rx";

// keep the word limit of 35 words per blog
function BlogLandingPage(){
    const [showad, setShowad] = useState(true);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isiOS, setIsiOS] = useState(false);
    // const removeAdd = () => {
    //   const ele = document.getElementById("grooming-popup-ad");
    //   ele.style.display = "none";
    //   setShowad(false);
    // };
    // setTimeout(() => {
    //   const ele = document.getElementById("grooming-popup-ad");
    //   ele.style.display = "flex";
    // }, 5000);
    let url;
  
    const userAgent = navigator.userAgent.toLowerCase();
    useEffect(() => {
      setIsAndroid(userAgent.indexOf("android") > -1);
      setIsiOS(
        userAgent.indexOf("iphone") > -1 ||
          userAgent.indexOf("ipad") > -1 ||
          userAgent.indexOf("ipod") > -1
      );
      // console.log(userAgent.indexOf("iphone"))
    }, [userAgent]);
  
    if (isAndroid) {
      url = "https://play.google.com/store/apps/details?id=in.tamely.user";
    } else if (isiOS) {
      url = "https://apps.apple.com/in/app/petmojo/id1609861328";
    }
    const [changeView, setChangeView] = useState(false);
    const myRef = useRef();
   useEffect(() => {
        // 👇️ scroll to top on page load
        setTimeout(function () {
            window.scrollTo(0, 300);
        },200);
      }, []);

    useEffect(() => {
        if(window.innerWidth < 1000){
            setChangeView(false);
        }
        else{
            setChangeView(true);
        }
    });

    function decypher(content){
        let count = 0;
        let finalString = "";
        let prototypeString = "";

        for(let a = 0; a<content.length; a++){
            // console.log(content[a]);
            if(content[a] === " " && count <= 30){
                count++;
            }
            else if(count > 30){
                break;
            }

            prototypeString = content.substring(0, a);
        }

        finalString = prototypeString + " ... "
        return finalString;
    }

    return(
        <>
        <Helmet>
            <title>Blogs by Petmojo - Pet Care, Training, Food, Well Being & More</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta
          name="description"
          content="Read tips & tricks to deal with your pets and their moods."
        />
        <link rel="canonical" href="/blog" />
        </Helmet>
        <div ref={myRef} style={{height:"100%",overflow:"auto"}}>
            {
                !changeView ? (
                    <div className="BlogsLandingPageMasterContainerMobile">
                         {/* {showad && (
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "60px",
            display: "none",
            gap: "5px",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-evenly",
          }}
          id="grooming-popup-ad"
        >
          <div onClick={removeAdd}>
            <RxCross2 color="#5E5E5E" />
          </div>
          <div style={{ height: "100%" }}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-ad-logo.png"
              alt="logo"
              style={{ height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "DM Sans",
              height: "100%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "700",
              }}
            >
              Petmojo
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "400",
              }}
            >
              Download our app & get <br />{" "}
              <span style={{ color: "rgba(35, 175, 0, 1)" }}>₹1000</span> in
              your Wallet{" "}
            </div>
          </div>
          <a
            href={url}
            target="_blank"
            style={{
              textDecoration: "none",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="button-group" style={{ height: "80%" }}>
              <button
                className=""
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  outline: "none",
                  border: "2px solid #DEC269",
                  color: "#DEC269",
                  fontFamily: "DM Sans",
                  backgroundColor: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                DOWNLOAD
              </button>
            </div>
          </a>
        </div>
      )} */}
                        <NavigationBar />

                        <div className="blogs-landing-page-mobile-view-heading">
                            <div className="">All Blogs</div>
                            <hr className="vector2"></hr>
                        </div>


                        <div className="blog-landing-page-mobile-view-blog-cards-container">
                            {
                                AllBlogs.map((blog) => {
                                    const blogContent = decypher(blog.full_content[0]);
                                    return <BlogCardGeneratorDesktop
                                        src={blog.src}
                                        views={blog.views}
                                        heading={blog.heading}
                                        date={blog.date}
                                        id={blog.id}
                                        content={blogContent}
                                        serial_no={blog.serial_no}
                                        device={"mobile"}
                                    />
                                })
                            }
                        </div>
                        <GoToTop/>  
                    </div>
                ) : (
                    <div className="BlogsLandingPageMasterContainer">
            
                    <NavigationDesktopBlogs submissionType={"none"} submissionText={"Download Our App"} />
                    <div className="blogs-landing-page-desktop-view-main-heading-container">
                        <div className="blogs-landing-page-desktop-view-main-heading">All Blogs</div>
                        <div className="line-div ">
                            <hr className="live-training-pink-line"></hr>
                        </div>
                    </div>
        
                    <div className="blogs-landing-page-desktop-view-main-content-master">
                        <div className="blogs-landing-page-desktop-view-main-content-container">
                            <div className="blogs-landing-page-desktop-view-main-content-container-column">
                                {
                                    AllBlogs.map((blog, index) => {
                                            const blogContent = decypher(blog.full_content[0]);
                                            return (
                                                (blog.serial_no % 2 ===0) ?
                                                <BlogCardGeneratorDesktop
                                                    index={index} 
                                                    src={blog.src}
                                                    views={blog.views}
                                                    heading={blog.heading}
                                                    date={blog.date}
                                                    id={blog.id}
                                                    content={blogContent}
                                                    serial_no={blog.serial_no}
                                                    device={"laptop"}
                                                /> : <div></div>
                                            );
                                    })
                                }
                            </div>
        
                            <div className="blogs-landing-page-desktop-view-main-content-container-column">
                                {
                                    AllBlogs.map((blog, index) => {
                                        const count = index+1;
                                        const blogContent = decypher(blog.full_content[0]);
                                        return (
                                            (blog.serial_no % 3 ===0) ?
                                            <BlogCardGeneratorDesktop
                                                index={index} 
                                                src={blog.src}
                                                views={blog.views}
                                                heading={blog.heading}
                                                date={blog.date}
                                                id={blog.id}
                                                content={blogContent}
                                            /> : <div></div>
                                        );
                                    })
                                }
                            </div>
        
                            <div className="blogs-landing-page-desktop-view-main-content-container-column">
                                {
                                    AllBlogs.map((blog, index) => {
                                        const count = index+1;
                                        const blogContent = decypher(blog.full_content[0]);
                                        return (
                                            (blog.serial_no % 5 ===0) ?
                                            <BlogCardGeneratorDesktop
                                                index={index} 
                                                src={blog.src}
                                                views={blog.views}
                                                heading={blog.heading}
                                                date={blog.date}
                                                id={blog.id}
                                                content={blogContent}
                                            /> : <div></div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
        
                </div>
                )
            }

            <GoToTop/>
        </div>
        </>

    );
};

export default BlogLandingPage;