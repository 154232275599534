
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css"
// import "./style.css"
import React from 'react'

const ImageSliderWalking = ({ images }) => {
 
    const settings = {
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed:500,
        autoplay:true,
        // accessibility:false,
        arrows:false,
        // variablewidth:true
 
    };
    return (
        <div className="imgslider" >
            <Slider {...settings}>
            {images.map((item) => (
                        <div key={item.id} style={{width:"100%"}}>
                            <img src={item.src} alt={item.alt} style={{width:"100%"}}/>
                        </div>
                    ))}
                        {/* <div >8</div>
                        <div >9</div> */}
                </Slider>            
        </div>
    )
}
export default ImageSliderWalking;