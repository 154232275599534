import Accordion from "../../../components/Accordion";
import AccordionDesktop from "../../../components/AccordionDesktop";
import { useLocation } from "react-router-dom";
import { data } from "../../../mockData/mockDataWalking";
import {Link} from "react-router-dom";

function FAQContainerGrooming(props) {
  const location=useLocation().pathname
  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };
  return (

    
    <>
    {
      (props.view === "mobile") ? (
        <div className="centerAlign">
          <div>
            <div className="textbox2 faq-title">FAQ'S</div>
            <hr className="vector2"></hr>

              <div className="grey-text" >Most frequent Questions and Answers</div>
            

            <div className="accordion">
              {data.map(({ title, content }) => (
                <Accordion view={"mobile"} title={title} content={content} />
              ))}
            </div>
          </div>

          <div className="bookingbutton"  style={{paddingLeft:"30px",paddingRight:"30px"}}>
              {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                <button className="booksession-mobile-view" onClick={()=>scrollToTop()} style={{width:"100%"}}> Book FREE Walk</button>
              {/* </Link> */}
              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          
        </div>
      ) : (
        <div className="faq-master-container-desktop">
          <div className="faqContainerDesktop">
            <div className="faqContainerDesktop-left">
              <div className="textbox2-desktop faq-title-desktop">FAQ'S</div>
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
              <div className="grey-text-desktop" style={{position:"relative",bottom:"10px"}}>Most frequent Questions and Answers</div>
              <div className="bookingbutton" style={{marginTop:"10px"}}>
                      {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                        <button className="booksession" onClick={()=>scrollToTop()}>Book FREE Walk</button>
                      {/* </Link> */}
                      {/* {location==="/dog-running"?<button className="booksession" onClick={()=>scrollToTop()}>Book 1st FREE Walk</button>:<>
                      
                      <Link to="/dog-running" state={{ type: "dog-running" }}>
                        <button className="booksession">Book 1st FREE Walk</button>
                      </Link>
                      </>} */}
                      <div className="limitedslots">Hurry Up, Limited slots left!</div>
                    </div>
            </div>

            <div className="accordion-desktop">
              {data.map(({ title, content }) => (
                <AccordionDesktop title={title} content={content} />
              ))}
            </div>
          </div>
          
        </div>
      )
    }
      
    </>
  );
}

export default FAQContainerGrooming;
