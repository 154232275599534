import React, { useState } from 'react';
import './Accordion.css';



const Accordion = ({ title, content, view }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item-desktop">
      <div className="accordion-title-desktop" onClick={() => setIsActive(!isActive)}>
        <div className='accordion-text'>{isActive ? <span className='pinktext'>{title}</span> : title}</div>
        <div className='accordion-symbol'>{isActive ? <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/close-dropdown_ku8xv4.png"} alt='closedropdown' className='closedropdown-icon'></img> : <img src={"https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/open-dropdown_wlu6ra.png"} alt='opendropdown' className='opendropdown-icon'></img> }</div>
      </div>
      {isActive && <div className="accordion-content-desktop">{content}</div>}
    </div>
  );
};

//className={(view === "mobile") ? "accordion-item" : "accordion-item-desktop"}
//className={(view === "mobile") ? "accordion-title" : "accordion-title-desktop"}

export default Accordion;